<template>
  <div class="product-size-chart">
    <h2 class="typo--l product-size-chart__title">
      Guida alle misure
    </h2>
    <div
      v-for="type in balloonTypes"
      :key="type.slug"
      class="product-size-chart__item"
    >
      <SizesVisual :balloon-type="type" />
      <h4>{{ type.name }}</h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SizesVisual from '@/components/pad/product-size-chart-item';

export default {
  computed: {
    ...mapGetters(['balloonTypes']),
  },
  components: {
    SizesVisual,
  },
};
</script>

<style lang="scss">
  .product-size-chart {
    width: 100%;
    display: grid;
    justify-content: center;
    column-gap: var(--spacer-xs);
    row-gap: var(--spacer-m);
    padding-bottom: var(--spacer-wrapper);

    @include mq(s) {
      grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
    }

    &__title {
      grid-column: 1 / -1;
    }

    &__item {
      display: grid;
      row-gap: var(--spacer-xxxs);
    }
  }
</style>
