<template>
  <Section
    :layout="{
      section_spacer: 'l',
      section_wrapper: 's'
    }"
  >
    <div class="register">
      <h2
        class="t-center typo--l"
      >
        {{ $labels.create_account }}
      </h2>
      <Form
        id="register"
        class="form--single"
      />
    </div>
  </Section>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '@/components/form';
import Section from '@/components/section';

export default {
  name: 'Register',
  components: {
    Form,
    Section,
  },
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    if (this.user) {
      this.$router.push({ name: 'Account' });
    }
  },
};
</script>

<style lang="scss">
.register {
  display: grid;
  row-gap: var(--spacer-l);
}
</style>
