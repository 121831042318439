<template>
  <div class="product-tabs-sizes">
    <SizesVisual :balloon-type="balloonType" />
    <div class="product-tabs-sizes__text">
      <div>
        <h4>{{ balloonType.name }}</h4>
        <h4>
          <span class="typo--s">Altezza:</span>
          {{ `${balloonType.acf.height} cm` }}
        </h4>
      </div>
      <Cta
        :data="{
          title: $labels.show_sizes_chart,
          url: '',
          afterSlot: true,
          size: 's--700',
          fn: showSizeChart,
        }"
      >
        <Icon
          size="xs"
          name="arrow-right"
        />
      </Cta>
    </div>
  </div>
</template>

<script>
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';

import SizesVisual from '@/components/pad/product-size-chart-item';

export default {
  components: {
    Cta,
    Icon,
    SizesVisual,
  },
  props: {
    balloonType: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    showSizeChart() {
      this.$bus.$emit('modal', {
        type: 'component',
        // id: 'cancelOrder',
        component: 'ProductSizeChart',
        theme: 'size-chart',
        wrapperSize: 'l',
      });
    },
  },
};
</script>

<style lang="scss">
  .product-tabs-sizes {
    display: grid;
    column-gap: var(--spacer-s);
    row-gap: var(--spacer-m);

    @include mq(m) {
      grid-template-columns: min-content 1fr;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--spacer-s);
    }
  }
</style>
