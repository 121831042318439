<template>
  <Flex
    class="main-navigation__menu"
    tag="nav"
    align="center"
  >
    <div
      v-for="(item, index) in navigation"
      :key="index"
      :class="[
        'main-navigation__menu__item',
        item.url === '/palloncini/' ? 'navigation__menu__item__submenu' : false,
      ]"
      @mouseenter="item.url === '/palloncini/' ? showSubMenu() : null"
    >
      <Cta
        :theme="item.acf.navigation_item_icon ? 'button--menu' : 'default'"
        :class="[
          ...item.css,
          item.url === '/palloncini/' ? 'typo--s--700' : null,
        ]"
        :data="{
          url: item.url,
          title: item.content,
          target: item.target,
        }"
      >
        <Icon
          name="sparkle"
          size="xs"
        />
      </Cta>
    </div>
  </Flex>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';

export default {
  name: 'Menu',
  components: {
    Cta,
    Icon,
  },
  emits: ['show-sub-menu'],
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('Header');
      return nav ? nav.items : null;
    },
  },
  methods: {
    showSubMenu() {
      this.$emit('show-sub-menu');
    },
  },
};
</script>

<style lang="scss">
  .main-navigation {
    &__menu {
      min-height: inherit;
      &.flex {
        display: none;
      }

      @include mq(l) {
        &.flex {
          display: flex;
        }
      }

      &__item {
        padding-left: calc(var(--spacer-m) / 2);
        padding-right: calc(var(--spacer-m) / 2);
      }

      > * {
        display: grid;
        align-items: center;
        min-height: inherit;
      }

      .router-link-active {
        text-decoration: underline;
        text-decoration-color: var(--black) !important;
        text-underline-offset: 6px;
        text-decoration-thickness: var(--line);
      }
    }
  }
</style>
