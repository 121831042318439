<template>
  <Grid
    v-if="orders && orders[0]"
    col="12"
    row-gap="xs"
  >
    <Order
      v-for="order in orders"
      :key="order.order_id"
      :order="order"
      theme="list"
    />
  </Grid>

  <Grid
    v-else-if="!loading"
    col="12"
    class="t-center"
    row-gap="m"
  >
    {{ $labels.no_order }}
    <Flex justify="center">
      <Cta
        :data="{
          title: $labels.shop_cta,
          url: '/shop',
        }"
        theme="button"
      />
    </Flex>
  </Grid>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { mapGetters } from 'vuex';
import Order from '@/components/wc/order';
import Cta from '@/components/typo/cta';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Orders',
  components: {
    Order,
    Cta,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['user', 'orders']),
  },
  async mounted() {
    await this.$store.dispatch('getOrders');
    if (this.$route.hash) {
      if (this.$el.querySelector(`*[data-order="${this.$route.hash.replace('#order-', '')}"] .accordion__trigger`)) {
        this.$el.querySelector(`*[data-order="${this.$route.hash.replace('#order-', '')}"] .accordion__trigger`).click();
        setTimeout(() => {
          disableBodyScroll(this.$el);
          gsap.killTweensOf(window);
          gsap.to(window, {
            scrollTo: {
              y: document.querySelector(`#${this.$route.hash.replace('#order-', 'order-')}`),
            },
            duration: 0.4,
            ease: 'expo.out',
            onComplete: () => {
              clearAllBodyScrollLocks();
            },
          });
        }, 1000);
      }
    }
    this.loading = false;
  },
};
</script>
