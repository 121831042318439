<template>
  <Grid
    class="form-theme--default"
    grid="1"
    row-gap="s"
  >
    <div>
      <h4 class="typo--s--700">
        {{ $labels.checkout_calendar_title }}
      </h4>
      <Spacer
        v-if="!shippingDate"
        top="xxxs"
        class="typo--xs"
      >
        {{ $labels.checkout_calendar_after_title }}
      </Spacer>
    </div>
    <v-date-picker
      v-model="date"
      :min-date="shippingDate"
      v-bind="calendarSettings"
    />
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import { weekdayFn, holidaysFn } from '@/assets/js/utils-wc';

export default {
  data() {
    return {
      date: null,
      calendarSettings: {},
      calendarSettingsInitial: {
        isExpanded: true,
        firstDayOfWeek: 2,
        locale: 'it',
        modelConfig: {
          type: 'string',
          mask: 'YYYY-MM-DD',
        },
        disabledDates: {},
        attributes: [
          {
            highlight: {
              contentClass: 'custom',
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['shippingDate', 'shippingVendor', 'shippingClosingDates']),
    // minDate: {
    //   get() {
    //     return this.shippingDate;
    //   },
    //   set(value) {
    //     this.$store.commit('UPDATE_SHIPPING_DATE', value);
    //   },
    // },
  },
  watch: {
    date: {
      immediate: true,
      handler(val) {
        this.$store.commit('SET_SHIPPING_SELECTED_DATE', val);
      },
    },
    shippingDate(val) {
      if (val) {
        this.date = val;
      } else {
        this.date = null;
        this.calendarSettings.disabledDates = {};
      }
    },
    shippingVendor(val) {
      this.calendarSettings = JSON.parse(JSON.stringify(this.calendarSettingsInitial));
      if (val) {
        this.date = this.shippingDate;
        if (!Array.isArray(this.calendarSettings.disabledDates)) this.calendarSettings.disabledDates = [];
        this.calendarSettings.disabledDates.push({
          weekdays: weekdayFn(val.closingDays),
        });
      } else {
        this.date = null;
        this.calendarSettings.disabledDates = {};
      }
    },
    shippingClosingDates(val) {
      if (!Array.isArray(this.calendarSettings.disabledDates)) this.calendarSettings.disabledDates = [];
      this.calendarSettings.disabledDates.push(...holidaysFn(val));
    },
  },
  mounted() {
    this.calendarSettings = JSON.parse(JSON.stringify(this.calendarSettingsInitial));
  },
};
</script>

<style lang="scss">
  .vc-container {
    --shadow: rgba(#{map-get($color-bases, "black")}, 0.2);
    border: none !important;
    font-family: $sans;
  }
  .vc-title {
    color: var(--black) !important;
    font-weight: 500 !important;
  }

  .vc-weekday {
    color: var(--grey-2) !important;
    border-bottom: var(--line) var(--grey-2) solid;
    border-top: var(--line) var(--grey-2) solid;
    padding: var(--spacer-xxxs) 0 !important;
    font-size: inherit !important;
    margin-bottom: var(--spacer-xs);
  }

  .vc-arrow {
    color: var(--black);
  }

  .vc-arrows-container {
    // --arrows-width: 250px;
    --arrows-width: 100%;
    padding: 0 !important;
    width: var(--arrows-width);
    left: 50%;
    margin-left: calc(var(--arrows-width) / -2);
  }

  .vc-header {
    padding-bottom: var(--spacer-xs) !important;
    padding-top: 0 !important;
  }

  .vc-day {
    min-height: 48px !important;
  }

  .vc-weeks {
    padding: 0 !important;
    min-width: 0 !important;
  }

  .vc-day-content {
    color: var(--black) !important;
    font-size: inherit !important;
    width: 24px !important;
    height: 24px !important;

    @include mq(s) {
      width: 36px !important;
      height: 36px !important;
    }

    &.is-disabled {
      color: var(--grey-2) !important;
    }
    &:focus {
      font-weight: 500 !important;
      background: transparent !important;
    }
  }

  .vc-highlight {
    background-color: var(--yellow) !important;
    width: 24px !important;
    height: 24px !important;

    @include mq(s) {
      width: 36px !important;
      height: 36px !important;
    }
  }

  .vc-highlights {
    + span {
      font-weight: 500 !important;
    }
  }

  .vc-pane {
    min-width: 0 !important;
  }
</style>
