<template>
  <Grid
    :col="$mq.isMobileM ? '12' : '6'"
    column-gap="xs"
    row-gap="xs"
    class="order-header"
  >
    <Column size="1">
      <h4 class="typo--s--700">
        {{ $labels.order }}
      </h4>
      <p>#{{ order.id }}</p>
    </Column>
    <Column size="1">
      <h4 class="typo--s--700">
        {{ $labels.date }}
      </h4>
      <p>
        {{ parseDate(order.created_date) }}
      </p>
    </Column>
    <Column size="1">
      <h4 class="typo--s--700">
        {{ $labels.total }}
      </h4>
      <div v-html="total" />
    </Column>
    <Column
      v-if="paymentGateway"
      size="1"
    >
      <h4 class="typo--s--700">
        {{ $labels.payment_method }}
      </h4>
      <p>
        {{ paymentGateway }}
      </p>
    </Column>
    <!-- <Column
      v-if="order.customer_note"
      size="1"
    >
      <h4 class="typo--s--700">
        {{ $labels.additional_information }}
      </h4>
      <p>{{ order.customer_note }}</p>
    </Column> -->
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  parseDate,
  parseCurrency,
  getOrderTotal,
} from '@/assets/js/utils-wc';

export default {
  name: 'OrderDetails',
  props: {
    order: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['wcSettings', 'paymentGateways']),
    total() {
      return getOrderTotal(this.order, this.wcSettings);
    },
    paymentGateway() {
      if (this.order && this.order.payment_method) {
        if (this.paymentGateways[this.order.payment_method]) {
          return this.paymentGateways[this.order.payment_method].title;
        }
        return (
          this.order.payment_method.charAt(0).toUpperCase()
            + this.order.payment_method.slice(1)
        );
      }
      return null;
    },
  },
  mounted() {},
  methods: {
    parseDate(date) {
      if (date) {
        return parseDate(date, this.wcSettings);
      }
      return null;
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings, this.order.currency);
    },
  },
};
</script>

<style lang="scss">
  .order-header {
    .col {
      display: grid;
      row-gap: var(--spacer-xxxs);
    }
  }
</style>
