import Vue from 'vue';

const labels = {
  account_created: 'Account creato con successo!',
  account_details: 'Dettagli account',
  account_number: 'Numero account',
  account: 'Account',
  accounte_creation_needed:
    'Devi creare un account per procedere con l\'ordine. Se ne hai già uno, effettua il login.',
  actions: 'Azioni',
  add_coupon: 'Applica',
  add_new_card: 'Aggiungi una carta',
  add_to_cart: 'Aggiungi al carrello',
  add: 'Aggiungi',
  additional_information: 'Informazioni aggiuntive',
  address_1: 'Indirizzo',
  address_2_placeholder: 'Scala, piano, unità, ecc.',
  address_2: 'Dettagli indirizzo',
  addresses: 'Indirizzi',
  available: 'Disponibile',
  back_to_cart: 'Torna al carrello',
  bank: 'Banca',
  bic_swift: 'BIC/SWIFT',
  billing_address: 'Indirizzo di fatturazione',
  cancel_card_dialog: 'Vuoi davvero rimuovere la carta?',
  cancel_card: 'Rimuovi carta',
  cancel_order_dialog: 'Vuoi davvero cancellare l\'ordine?',
  cancel_order: 'Cancella ordine',
  cancel_subscription_dialog: 'Vuoi davvero cancellare l\'abbonamento?',
  cancel_subscription: 'Cancella abbonamento',
  cancel: 'Cancella',
  cannot_pay_for_order: 'Non puoi pagare questo ordine',
  cap_button: 'Verifica',
  cap_discover_cta: 'Scopri i nostri prodotti',
  cap_edit_cta: 'Modifica il CAP',
  cap_message_1: 'La prima data disponibile è il',
  cap_message_2: (data) => `${data.date}!`,
  cap: 'Il tuo CAP...',
  cart: 'Carrello',
  cart_button: 'Vai al carrello',
  cart_disclaimer:
    'In base alle disponibilità dei singoli magazzini i palloncini potrebbero essere sostituiti con altri simili.',
  cart_products_title: 'Prodotti',
  change_payment_method: 'Cambia metodo di pagamento',
  change_payment: 'Cambia metodo di pagamento',
  changed_payment_method: 'Il metodo di pagamento è stato aggiornato',
  checkout: 'Acquisto',
  checkout_calendar_title: 'Scegli la data di consegna',
  checkout_calendar_date_mandatory: 'Seleziona dal calendario una data di consegna per il tuo ordine',
  checkout_calendar_after_title:
    'Prima devi selezionare l\'indirizzo di consegna',
  choose_option: 'Scegli un\'opzione',
  selected_date: 'Data consegna indicata',
  city: 'Città',
  close: 'Chiudi',
  company: 'Azienda',
  country: 'Paese',
  coupon: 'Codice sconto',
  create_account: 'Crea account',
  date: 'Data',
  delete: 'Rimuovi',
  details: 'Dettagli',
  discount: 'Sconto',
  display_name: 'Nickname',
  do_you_have_account: 'Hai già un account?',
  edit: 'Modifica',
  email: 'La tua email...',
  empty_cart: 'Il carrello è vuoto',
  end_date: 'Data di fine',
  ending_in: 'che finisce con',
  error_confirmed_after: '',
  error_confirmed_pre: 'Il campo deve essere uguale a',
  error_digits_1: 'Il campo deve avere',
  error_digits_2: 'cifre',
  error_email: 'Email non valida',
  error_generic_customer: 'Dati cliente non disponibili',
  error_generic_login: 'Errore durante il login. Prova di nuovo.',
  error_generic: 'Errore',
  error_min_after: 'caratteri',
  error_min_pre: 'Il campo deve avere almeno',
  error_placeholder: 'Errore',
  error_required: 'Campo obbligatorio',
  error_user_addresses_update: 'Errore nel salvataggio dati.',
  error_user_update:
    'Errore nel salvataggio dati. <br>Controlla prima di riprovare.',
  excluded_vat_extended: 'IVA esclusa',
  excluded_vat: 'IVA esclusa',
  expires: 'scadenza:',
  first_name: 'Nome',
  future_payments: 'Pagamenti futuri',
  go_back: 'Torna indietro',
  go_to_checkout: 'Procedi con l\'acquisto',
  go_to_orders: 'Vai agli ordini',
  go_to_payment: 'Procedi con il pagamento',
  go_to_shop: 'Torna indietro',
  go_to: 'Vai a',
  hide_order_summary: 'Nascondi il riepilogo dell\'ordine',
  iban: 'IBAN',
  id: 'ID',
  insert_coupon: 'Codice...',
  last_name: 'Cognome',
  last_order_date: 'Data dell\'ultimo ordine',
  logged_in_with_mail: 'Login effettuato tramite email',
  login: 'Login',
  logout: 'Logout',
  lost_password: 'Hai dimenticato la password?',
  make_default: 'Rendi predefinita',
  may_be_interested_in: 'Potresti essere interessato a...',
  my_account: 'Il mio account',
  next_payment_date: 'Data del prossimo pagamento',
  newsletter_button: 'Iscriviti',
  no_order: 'Nessun ordine effettuato.',
  no_saved_card: 'Nessuna carta salvata',
  no_subscription: 'Nessun abbonamento sottoscritto.',
  number_button: 'Crea la composizione',
  number_configurator_empty: 'Inserisci un numero!',
  number_configurator_title: 'La tua composizione',
  number_configurator_number_title: 'Scrivi il numero:',
  number_configurator_type_title: 'Seleziona la tipologia:',
  numbers_composition:
    'Oggi non ti senti creativo? Fatti ispirare da una delle nostre composizioni già pronte!',
  number_placeholder: 'Il tuo numero...',
  old_password: 'Password vecchia',
  order_cancelled: 'Il tuo ordine è stato cancellato',
  order_card_author_label: 'Chi firma il biglietto?',
  order_card_author_placeholder: 'Nome, soprannome...',
  order_card_cta: 'Aggiungi il biglietto (+1,70€)',
  edit_order_card_cta: 'Modifica il biglietto',
  order_card_message_label: 'Messaggio (max. 300 caratteri)',
  order_card_message_placeholder: 'Scrivi un messaggio speciale...',
  order_card_title: 'Aggiungi un biglietto al tuo regalo!',
  order_details: 'Riepilogo',
  order_fail_text:
    'Purtroppo il tuo ordine non può essere processato perchè la transazione è stata rifiutata. Prova ad acquistare nuovamente.',
  order_fail_title: 'C\'è stato un errore nell\'ordine...',
  order_infos: 'Informazioni dell\'ordine',
  order_notes_placeholder: 'Nota...',
  order_notes: 'Informazioni aggiuntive',
  order_success_text: 'Il tuo ordine è andato a buon fine.',
  order_success_title: 'Grazie per l\'acquisto!',
  order_updated: 'Il tuo ordine è stato aggiornato con successo',
  order_updates: 'Aggiornamenti dell\'ordine',
  order: 'Ordine',
  orders: 'Ordini',
  out_of_stock: 'Esaurito',
  password_placeholder: 'Minimo 6 caratteri',
  password: 'Password',
  pay_for_order: 'Paga l\'ordine',
  payment_method: 'Metodo di pagamento',
  payment_methods: 'Metodi di pagamento',
  no_payment_methods: 'Nessun metodo di pagamento disponibile, puoi procedere con l\'ordine.',
  payment: 'Pagamento',
  phone: 'Telefono',
  place_order: 'Acquista',
  postcode: 'CAP',
  product: 'Prodotto',
  product_description_title: 'Descrizione',
  product_description_placeholder: 'Nessuna descrizione.',
  product_information_title: 'Info',
  product_information_placeholder: 'Nessuna informazione aggiuntiva.',
  product_sizes_title: 'Misure',
  product_sizes_placeholder: 'Misure sconosciute.',
  products: 'Prodotti',
  profile: 'Profilo',
  refunded: 'Rimborsato',
  register: 'Crea un account',
  related_orders: 'Ordini correlati',
  related_products: 'Prodotti correlati',
  remove: 'Rimuovi',
  renew_now: 'Rinnova ora',
  repeat_password: 'Ripeti la password',
  save_card: 'Salva la carta per pagamenti futuri',
  save: 'Salva',
  saved_cards: 'Carte salvate',
  saved_changes: 'Le modifiche sono state salvate',
  search_button: 'Cerca',
  search_placeholder: 'Cerca...',
  search_results: (results) => `Risultati (${results.length})`,
  select_option: 'Vai al prodotto',
  send: 'Invia',
  ship_different_address: 'Spedire ad un indirizzo diverso?',
  shipping_address: 'Indirizzo di spedizione',
  shipping_exstimated: 'Spedizione',
  shipping_method: 'Modalità di spedizione',
  shipping_methods_empty_states:
    'Inserisci il tuo indirizzo per scegliere la modalità di spedizione.',
  shipping_methods: 'Modalità di spedizione',
  shipping: 'Spedizione',
  shop: 'negozio',
  shop_cta: 'Vai al negozio',
  show_order_summary: 'Mostra il riepilogo',
  show_sizes_chart: 'Guida alle misure',
  start_date: 'Data di inizio',
  state: 'Provincia',
  status: 'Stato',
  subscriptions: ' Abbonamenti',
  subscription: ' Abbonamento',
  subscription_details: ' Dettagli abbonamento',
  subscription_totals: ' Totali abbonamento',
  shipping_subscription_address: 'Indirizzo di spedizione per abbonamento',
  subtotal: 'Subtotale',
  terms: 'Accetto termini e condizioni.',
  title_coupon: 'Hai un codice sconto?',
  total: 'Totale',
  totals: 'Totali',
  use_new_card: 'Usa un\'altra carta',
  username_email: 'Email',
  vat_extimated: 'IVA stimata',
  vat: 'IVA',
  view: 'Visualizza',
  welcome_back: 'Ciao!',
  wrong_login_data:
    'I dati inseriti non sono corretti. <br>Riprova o recupera la password.',
  wrong_mail: 'Email non registrata.',
  your_cart_empty: 'Il carrello è vuoto',
  your_cart: 'Carrello',
};

labels.install = () => {
  Vue.prototype.$labels = labels;
};

Vue.use(labels);

export default labels;
