/* eslint-disable no-undef */

import Cart from '@/views/wc/Cart.vue';
import Checkout from '@/views/wc/Checkout.vue';
import ThankYou from '@/views/wc/ThankYou.vue';
import Account from '@/views/wc/account/Index.vue';
import Orders from '@/views/wc/account/Orders.vue';
import Subscriptions from '@/views/wc/account/Subscriptions.vue';
import Register from '@/views/wc/account/Register.vue';
import SocialLoggedIn from '@/views/wc/account/SocialLoggedIn.vue';
import EditAccount from '@/views/wc/account/EditAccount.vue';
import EditAddress from '@/views/wc/account/EditAddress.vue';
import SingleProduct from '@/views/wc/SingleProduct.vue';
import payOrderSelector from './payOrderSelector';

const { postTypes } = __VUE_WORDPRESS__.state;

const permalink = postTypes.product.rewrite.slug.replace(/^\/+/g, '');
const type = postTypes.product.rest_base;

const wcRoutes = [
  {
    path: '/shop',
    name: 'Shop',
    redirect: '/palloncini',
  },
  {
    path: '/carrello',
    name: 'Cart',
    component: Cart,
    meta: {
      title: 'Carrello',
    },
  },
  // /cart/?cancel_order=true&order=wc_order_wIm66dL0hWe5a&order_id=165&redirect&_wpnonce=9d9f754376
  {
    path: '/checkout/order-pay/:orderId/',
    name: 'PayOrder',
    component: payOrderSelector,
    meta: {
      title: 'Pagamento ordine',
      fetchCountry: true,
    },
  },
  // Pagina di pagamento ordine: /checkout/order-pay/260/?pay_for_order=true&key=wc_order_CeFdYg7esp6RQ
  // Pagina di pagamento ordine: /checkout/order-pay/462/?pay_for_order=true&key=wc_order_Lu2e0h5jQZaEa&change_payment_method=462
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
      title: 'Acquisto',
      fetchCountry: true,
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: '/checkout/order-received/:orderId/',
    name: 'ThankYou',
    component: ThankYou,
    meta: {
      title: 'Riepilogo',
      fetchCountry: true,
    },
  },
  {
    path: '/registrazione/',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Registrazione',
    },
  },
  {
    path: '/social-logged-in/',
    name: 'SocialLoggedIn',
    component: SocialLoggedIn,
    // meta: {
    //   title: true,
    // },
  },
  {
    path: '/account/',
    name: 'Account',
    component: Account,
    meta: {
      title: 'Account',
      fetchCountry: true,
    },
    children: [
      {
        path: '/',
        name: 'EditAccount',
        component: EditAccount,
        meta: {
          title: 'Account',
          userPage: true,
          fetchCountry: true,
        },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        meta: {
          title: 'Ordini',
          userPage: true,
          fetchCountry: true,
        },
      },
      {
        path: 'view-order/:orderId/',
        redirect: (to) => ({
          name: 'Orders',
          hash: `#order-${to.params.orderId}`,
        }),
      },
      {
        path: 'edit-address/:addressType/',
        name: 'EditAddress',
        component: EditAddress,
        meta: {
          title: 'Modifica indirizzo',
          userPage: true,
          fetchCountry: true,
        },
      },
      {
        path: 'subscriptions',
        name: 'Subscriptions',
        component: Subscriptions,
        meta: {
          title: 'Abbonamenti',
          userPage: true,
          fetchCountry: true,
        },
      },
      {
        path: 'view-subscription/:subscriptionId/',
        redirect: (to) => ({
          name: 'Subscriptions',
          hash: `#subscription-${to.params.subscriptionId}`,
        }),
      },
    ],
  },
  {
    path: `/${permalink}/:slug`,
    name: 'SingleProduct',
    component: SingleProduct,
    meta: {
      type,
    },
  },
];

export default wcRoutes;
