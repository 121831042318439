import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      settings: {
        submitWithIcon: true,
      },
      groups: {
        number: {
          visible: true,
          fields: {
            number: {
              type: 'text',
              model: this.selectedNumber,
              placeholder: 'number_placeholder',
              label: 'number',
              // rules: 'required|max:4',
              inputFn: this.setNumber,
              maxlength: 4,
              placeholderIsVisible: true,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['selectedNumber']),
  },
  methods: {
    setNumber() {
      this.$store.commit('SET_NUMBER', this.groups.number.fields.number.model);
    },
  },
  watch: {
    selectedNumber: {
      immediate: true,
      handler(value) {
        this.groups.number.fields.number.model = value;
      },
    },
  },
};
