import { mapGetters } from 'vuex';
import { fetchSingle } from '@/api/wc';

export default {
  data() {
    return {
      settings: {
        sendLabel: 'order_card_cta',
      },
      groups: {
        orderCard: {
          title: this.$labels.order_card_title,
          visible: true,
          fields: {
            author: {
              type: 'text',
              model: '',
              label: 'order_card_author_label',
              placeholder: 'order_card_author_placeholder',
              placeholderIsVisible: true,
            },
            message: {
              type: 'textarea',
              rows: 8,
              model: '',
              label: 'order_card_message_label',
              placeholder: 'order_card_message_placeholder',
              placeholderIsVisible: true,
            },
          },
        },
      },
      product: null,
      metaInfos: {},
      cardAlreadyAdded: null,
    };
  },
  computed: {
    ...mapGetters(['cart']),
  },
  methods: {
    async init() {
      // Prendo i dati del prodotto
      const { data } = await fetchSingle({
        type: 'product',
        params: {
          slug: 'biglietto-auguri',
        },
      });
      this.product = data;
      // Prendo i valori inseriti fino ad ora dall'utente
      this.cardAlreadyAdded = this.cart.items.find((item) => item.slug === 'biglietto-auguri');
      if (this.cardAlreadyAdded) {
        const { autore, messaggio } = this.cardAlreadyAdded.custom_item_data;
        this.groups.orderCard.fields.author.model = autore;
        this.groups.orderCard.fields.message.model = messaggio;
        this.settings.sendLabel = 'edit_order_card_cta';
      }
    },
    async callback() {
      const cart_item_data = {
        custom_item_data: {
          autore: this.groups.orderCard.fields.author.model,
          messaggio: this.groups.orderCard.fields.message.model,
        },
      };
      this.cardAlreadyAdded = this.cart.items.find((item) => item.slug === 'biglietto-auguri');
      if (this.cardAlreadyAdded) {
        await this.$store.dispatch('removeCartItem', {
          key: this.cardAlreadyAdded.key,
        });
      }
      this.$store.dispatch('addToCart', {
        id: String(this.product[0].id),
        quantity: 1,
        quantityDelta: 1,
        cart_item_data,
      });
      // this.init();
    },
  },
};
