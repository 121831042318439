var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-thumb"},[_c('router-link',{attrs:{"to":_vm.data.link || _vm.data.permalink}},[(_vm.data.gds_featured_image)?_c('Media',{class:[
        'product__image',
        'block--default',
        ("ratio--" + (_vm.number ? '43' : '11')) ],attrs:{"data":{
        type: 'image',
        image: _vm.data.gds_featured_image,
        ratio: false,
        object_fit: 'cover',
      }}}):_vm._e()],1),_c('div',{staticClass:"product-thumb__text"},[_c('router-link',{attrs:{"to":_vm.data.link || _vm.data.permalink}},[_c('Title',{staticClass:"t-center",attrs:{"data":{
          tag: 'h4',
          size: 's--700',
          value: _vm.data.taxonomy || _vm.data.name ? _vm.data.name : _vm.data.title.rendered,
        }}})],1),_c('Price',{staticClass:"t-center",attrs:{"product":_vm.data}}),(_vm.isOos)?_c('Availability',{attrs:{"product":_vm.data}}):_vm._e()],1),_c('Flex',{attrs:{"justify":"center"}},[(!_vm.hasVariations)?_c('Cta',{attrs:{"section-background-color":_vm.data.backgroundColor,"theme":"button","data":{
        title: _vm.$labels.add_to_cart,
        url: false,
        fn: _vm.addToCart,
      },"disabled":!_vm.addableToCart}}):_c('Cta',{attrs:{"theme":"button","section-background-color":_vm.data.backgroundColor,"data":{
        title: _vm.$labels.select_option,
        url: _vm.data.link || _vm.data.permalink,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }