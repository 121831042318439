export default {
  searchBarIsVisible: (state) => state.searchBarIsVisible,
  mobileMenuVisibility: (state) => state.mobileMenuVisibility,
  selectedNumber: (state) => state.selectedNumber,
  balloonTypes: (state, getters, rootState) => {
    const allTypes = Object.values(rootState.wp.balloonTypes.default);
    const filteredTypes = allTypes.filter((e) => e.acf.height !== '');
    return filteredTypes;
  },
};
