<template>
  <div :class="['separator', data.full ? 'separator--full' : null]" />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        full: false,
      }),
    },
  },
};
</script>

<style lang="scss">
  .separator {
    height: var(--line);
    background: var(--grey-2);

    &--full {
      width: calc(100% + var(--spacer-wrapper) * 2);
      margin-left: 50%;
      transform: translateX(-50%);

      @include mq(xl) {
        width: initial;
        margin-left: 0;
        transform: initial;
      }
    }
  }
</style>
