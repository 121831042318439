<template>
  <div class="carousel">
    <div class="carousel__overlay" />
    <slot />
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

require('flickity-imagesloaded');

export default {
  name: 'Carousel',
  props: {
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      carousel: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        pageDots: false,
        arrowShape:
            'm43.589081 6.4099855c1.312451 0 2.624549.5010777 3.625843 1.5024217 2.002587 2.002741 2.002587 5.2489458 0 7.2516878l-29.707885 29.707881h77.364696c2.832238 0 5.128265 2.295925 5.128265 5.128265 0 2.832341-2.296027 5.128265-5.128265 5.128265h-77.364696l29.707885 29.707882c2.002587 2.002588 2.002587 5.249099 0 7.251685-2.002588 2.002588-5.249098 2.002588-7.251685 0l-38.4619931-38.461989c-.4532873-.453287-.79359392-.970488-1.04167888-1.522454-.001215-.0027.00121-.0072 0-.01026-.0246157-.05487-.0572827-.10436-.0801548-.16026-.0546673-.134822-.10753972-.272567-.15025817-.410671-.006154-.02-.0138463-.04-.0200002-.0601-.0420518-.142258-.0806676-.285952-.11015514-.430672-.0033744-.01641-.0066667-.03333-.01025653-.05026-.09584728-.495031-.11102694-1.002043-.06010327-1.502418.01589762-.155386.03076959-.309798.06010327-.460723.0026103-.01333.0071796-.02667.01025653-.04.00064104-.0032-.00064616-.0067 0-.01026.02974394-.146156.06815465-.289388.11015514-.430672.006154-.02.0138463-.04.0200002-.0601.0435902-.140258.0951806-.275849.15025817-.410672.0230772-.0559.0553853-.105385.0801548-.160258.00121-.0027-.001215-.0072 0-.01026.24810548-.551957.58838638-1.069153 1.04167888-1.522445l38.4619931-38.4619918c1.001293-1.0005456 2.313391-1.5016217 3.625842-1.5016217z',
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.carousel = new Flickity(
        this.$el,
        Object.assign(this.settings, this.extraSettings, {
          on: {
            ready: () => {
              this.$el.classList.add('flickity-ready');
            },
          },
        }),
      );
    });
  },
  beforeUnmount() {
    this.carousel.destroy();
  },
};
</script>

<style lang="scss">
  .carousel {
    --width: calc(2 / 3);

    &.flickity-ready {
      .item {
        height: 100%;
      }
    }

    .item {
      margin-right: calc(var(--spacer-wrapper) / 2);
      margin-left: calc(var(--spacer-wrapper) / 2);
      width: calc(var(--width) * 100vw);
      // min-height: 100%;
      // display: flex;
      // align-items: stretch;
    }

    // .product-thumb {
    //   height: 100%;
    //   width: 100%;
    // }

    @include mq(m) {
      --width: calc(1 / 3);

      .item {
        margin-right: calc(var(--spacer-xs) / 2);
        margin-left: calc(var(--spacer-xs) / 2);
      }
    }

    &__overlay {
      position: absolute;
      width: 100vw;
      margin-left: 50%;
      transform: translateX(-50%);
      pointer-events: none;
      z-index: 1;
      background: linear-gradient(
        to left,
        var(--overlay-white-08),
        rgba(255, 255, 255, 0) 100px
      );
      height: 100%;
    }

    .flickity {
      &-viewport {
        overflow: initial;
      }
      &-prev-next-button {
        height: 30px;
        width: 30px;
        top: calc(var(--width) * 50vw);
        background-color: var(--red);
        color: var(--white);
        z-index: 1;
        transform: none;

        &.next {
          right: 0;
        }

        &.previous {
          left: 0;
        }

        @media (hover: hover) {
          &:hover {
            box-shadow: 0px 0px 10px var(--red);
          }
        }

        @include mq(m) {
          height: 36px;
          width: 36px;
        }
      }
      &-button:disabled {
        opacity: 0;
      }
    }
  }
</style>
