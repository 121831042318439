<template>
  <AccordionWrapper class="block-theme--default">
    <AccordionItem :data-subscription="subscription.id">
      <template slot="accordion-trigger">
        <Grid>
          <Column
            :size="{
              default: 6,
              xs: 3,
            }"
            :class="{ 'typo--m--700': $mq.isMobileXS }"
          >
            <span v-if="!$mq.isMobileXS">{{ $labels.subscription }}</span>
            #{{ subscription.id }}
          </Column>
          <Column
            :size="{
              default: 9,
              s: 3,
            }"
            :class="{ 'typo--m--700': $mq.isMobileXS }"
          >
            <div class="t-ellipsis">
              {{ subscriptionStatus }}
            </div>
          </Column>
          <Column
            v-if="!$mq.isMobileS"
            size="3"
          >
            <div
              class="t-ellipsis"
              v-html="nextPayment"
            />
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <div
              class="t-ellipsis"
              v-html="total"
            />
          </Column>
        </Grid>
      </template>
      <template slot="accordion-content">
        <SubscriptionDetails :subscription="subscription" />
      </template>
    </AccordionItem>
  </AccordionWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  parseDate,
  parseCurrency,
  getOrderTotal,
} from '@/assets/js/utils-wc';

import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import SubscriptionDetails from '@/components/wc/subscription-details.vue';

export default {
  name: 'Subscription',
  components: {
    AccordionWrapper,
    AccordionItem,
    SubscriptionDetails,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['wcSettings']),
    subscriptionStatus() {
      return this.$store.state.wp.wcSettings.woocommerce_subscription_statuses[
        `wc-${this.subscription.status}`
      ];
    },
    nextPayment() {
      return this.subscription.status === 'active' && this.subscription.subscription_data.schedule_next_payment ? this.parseDate(this.subscription.subscription_data.schedule_next_payment.date) : '-';
    },
    total() {
      return getOrderTotal(this.subscription, this.wcSettings);
    },
  },
  mounted() {},
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings, this.subscription.currency);
    },
    parseDate(date) {
      if (date) {
        return parseDate(date, this.wcSettings);
      }
      return null;
    },
  },
};
</script>

<style lang="scss"></style>
