<template>
  <li
    class="accordion__item"
  >
    <div
      class="accordion__trigger chevron"
      :class="{ 'accordion__trigger--active': visible }"
      @click="open"
    >
      <slot name="accordion-trigger" />
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="visible"
        class="accordion__content"
      >
        <Spacer
          top="l"
        >
          <slot name="accordion-content" />
        </Spacer>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  inject: ['Accordion'],
  props: {
    preOpenedFn: {
      type: Function,
      required: false,
      default: () => {},
    },
    openedFn: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.index === this.Accordion.active;
    },
  },
  created() {
    this.index = this.Accordion.count + 1;
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      this.preOpenedFn();
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      el.style.height = '';
      this.openedFn();
    },
  },
};
</script>
