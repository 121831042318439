<template>
  <ValidationProvider
    v-if="!data.hidden"
    v-slot="{ errors, classes }"
    mode="lazy"
    :rules="data.rules"
    :vid="data.vid"
    tag="div"
  >
    <div
      ref="radio-payment"
      class="field field--radio-payment"
      :class="classes"
    >
      <div v-if="!optionsKeys[0]">
        {{ $labels.no_payment_methods }}
      </div>
      <div
        v-for="(option, index) in data.options"
        :key="index"
        class="field-radio-option"
      >
        <label>
          <div class="field-input">
            <input
              :id="formId ? `${formId}-${option.id}` : option.id"
              v-model="computedModel"
              type="radio"
              :name="data.id"
              :value="option.id"
              @change="data.fn ? data.fn(option.id) : false"
            >
            <div class="field-after-input" />
          </div>
          <span v-html="option.title" />
        </label>
        <div
          v-show="computedModel === option.id"
          class="field-description"
        >
          <div v-html="option.description" />
          <div v-if="option.id === 'bacs'">
            <BanksDetails />
          </div>
          <div v-if="option.testmode">
            Test mode is ENABLED.
          </div>
          <Spacer
            v-if="option.id === 'stripe'"
            top="s"
          >
            <Radio
              v-model="saved_cards.model"
              :data="saved_cards"
              :model.sync="saved_cards.model"
            />
            <div
              v-show="showStripeInputs"
              class="add-card add-card--checkout"
            >
              <div
                id="card-element"
                class="add-card__input"
              >
                <!-- Elements will create input elements here -->
              </div>
              <p
                id="card-errors"
                class="add-card__error"
                role="alert"
              >
                <!-- Error messages will be shown in this element -->
              </p>
              <div id="save-card-wrapper">
                <Checkbox
                  v-model="save_card.model"
                  :data="save_card"
                  :model.sync="save_card.model"
                >
                  <label
                    slot="label"
                    for="save_card"
                  >{{
                    $labels[save_card.label]
                  }}</label>
                </Checkbox>
              </div>
            </div>
          </Spacer>
        </div>
      </div>

      <div
        slot="errors"
        class="input-errors"
      >
        {{ errors[0] }}
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import BanksDetails from '@/components/wc/banks-details.vue';
import Checkbox from '@/components/form/fields/checkbox';
import Radio from '@/components/form/fields/radio';

export default {
  name: 'RadioPayment',
  components: {
    ValidationProvider,
    BanksDetails,
    Checkbox,
    Radio,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      default: '',
    },
    formId: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      saved_cards: {
        type: 'radio',
        model: null,
        id: 'saved_cards',
        hidden: false,
        placeholder: null,
        options: [],
        fn: this.setSavedCards,
      },
      save_card: {
        id: 'save_card',
        type: 'checkbox',
        checked: false,
        hidden: true,
        model: false,
        label: 'save_card',
      },
      showStripeInputs: false,
    };
  },
  computed: {
    ...mapGetters([
      'paymentMetas',
      'wcSettings',
      'customer',
      'customerSavedMethods',
    ]),
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', val);
      },
    },
    optionsKeys() {
      if (!this.data.options) {
        return [];
      }
      return Object.keys(this.data.options);
    },
  },
  watch: {
    customer: {
      immediate: true,
      handler(val) {
        if (val && val.id) {
          this.save_card.hidden = false;
        }
      },
    },
    customerSavedMethods: {
      immediate: true,
      handler(val) {
        if (
          val
            && typeof val === 'object'
            && val[0]
            && !this.saved_cards.options[0]
        ) {
          const cards = [];
          val.forEach((element) => {
            cards.push({
              id: String(element.token_value),
              title: `${element.method.brand} ${this.$labels.ending_in} ${element.method.last4} (${this.$labels.expires} ${element.expires})`,
            });
          });
          cards.push({
            id: 'new',
            title: this.$labels.use_new_card,
          });
          this.saved_cards.options = cards;
        } else {
          this.showStripeInputs = true;
        }
      },
    },
  },
  methods: {
    setSavedCards(val) {
      if (val === 'new') {
        this.showStripeInputs = true;
      } else {
        this.showStripeInputs = false;
      }
    },
  },
};
</script>

<style lang="scss">
  .add-card.add-card--checkout {
    margin-top: var(--spacer-xs);
    @include mq(m) {
      justify-content: start;
      grid-template-columns: 1fr;
    }
  }
</style>
