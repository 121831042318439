<template>
  <transition
    name="cookie"
    appear
  >
    <Wrapper
      v-show="!cookie && init"
      class="cookie-container"
      size="xl"
      boxed
    >
      <div class="cookie">
        <Richtext
          v-if="options.cookies.text"
          :data="{
            value: options.cookies.text,
            size: 's',
          }"
        />
        <!-- <Cta
          v-if="options.cookies.cta"
          :data="{
            title: options.cookies.cta,
            fn: acceptCookie,
          }"
        /> -->
        <Icon
          name="close"
          size="xs"
          theme="minimal"
          :fn="acceptCookie"
        />
      </div>
    </Wrapper>
  </transition>
</template>

<script>
// import { bootstrap } from 'vue-gtag';
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

// import { loadScript } from '@/assets/js/utils';

import Icon from '@/components/ui/icon';
import Richtext from '@/components/typo/richtext';
// import Cta from '@/components/typo/cta';

// eslint-disable-next-line no-undef
const id = __VUE_WORDPRESS__.state.options?.cookies?.id;

export default {
  name: 'Cookie',
  components: {
    Icon,
    Richtext,
    // Cta,
  },
  data() {
    return {
      init: false,
      cookie: !!Cookies.get(id),
    };
  },
  computed: {
    ...mapGetters(['transition', 'options']),
  },
  watch: {
    transition(val) {
      if (!val) this.init = true;
    },
  },
  methods: {
    acceptCookie() {
      Cookies.set(this.options.cookies.id, '1', { expires: 365 });
      this.cookie = true;

      if (this.options.scripts && this.options.scripts.scripts_repeater) {
        // this.options.scripts.scripts_repeater.forEach((item) => {
        //   if (item.inline) {
        //     const s = document.createElement('span');
        //     s.style.display = 'none';
        //     s.innerHTML = item.script;
        //     document.getElementsByTagName('body')[0].appendChild(s);
        //   } else {
        //     loadScript(item.script_src);
        //   }
        // });
      }

      // if (this.$gtag) {
      //   bootstrap();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
  .cookie-enter-active,
  .cookie-leave-active {
    transition: all 0.8s $ease-custom;
  }
  .cookie-enter,
  .cookie-leave-to {
    transform: translateY(100%) translateY(30px);
  }
  .cookie-container {
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: var(--yellow);
  }
  .cookie {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacer-xxs) 0;

    > * {
      max-width: 100%;
    }
  }
</style>
