var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main--category"},[(_vm.$mq.isMobile && _vm.cover && _vm.cover.title && _vm.cover.title !== '')?_c('Spacer',{attrs:{"top":"m","left":"s"}},[_c('Title',{attrs:{"data":{
        value: _vm.cover.title,
        tag: 'h1',
        size: 'xl',
      }}})],1):_vm._e(),(!_vm.$mq.isMobile)?_c('Section',{attrs:{"layout":{
      section_background_color: _vm.cover.backgroundColor,
      section_spacer: 'm',
      section_wrapper: 'xl',
    }}},[_c('Cover',{attrs:{"data":{
        title: _vm.cover.title,
        text: _vm.cover.text,
        figure: _vm.cover.image,
      }}})],1):_vm._e(),(_vm.request.slug === 'numeri')?_c('Section',{attrs:{"layout":{
      section_spacer: 'l',
      section_wrapper: 'xl',
    }}},[_c('NumberConfigurator')],1):_vm._e(),(!_vm.$mq.isMobile)?_c('Wrapper',{attrs:{"size":"xl"}},[_c('Separator')],1):_vm._e(),_c('Section',{attrs:{"layout":{
      section_spacer: 'l',
      section_wrapper: 'xl',
    }}},[_c('div',{staticClass:"category-archive"},[(_vm.request.slug === 'numeri')?_c('h2',{staticClass:"typo--l"},[_vm._v(" "+_vm._s(_vm.$labels.numbers_composition)+" ")]):_vm._e(),_c('Archive',{attrs:{"data":{
          archive_type: 'archive',
          archive: _vm.request.postType,
          posts_per_page: 16,
          infinite_scroll: true,
          archive_category: {
            taxonomy: _vm.request.category,
            term_id: _vm.request.categoryId,
          },
          layout: [
            {
              media_query: 'default',
              layout_size: '12',
              layout_type: 'grid',
            },
            {
              media_query: 'm',
              layout_size: ("" + (_vm.request.slug === 'numeri' ? '12' : '6')),
              layout_type: 'grid',
            },
            {
              media_query: 'l',
              layout_size: ("" + (_vm.request.slug === 'numeri' ? '6' : '3')),
              layout_type: 'grid',
            } ],
        }}})],1)]),(_vm.request.slug === 'numeri')?_c('Section',{attrs:{"layout":{
      section_spacer: 'l',
      section_wrapper: 'xl',
    }}},[_c('div',{staticClass:"category-archive"},[_c('Archive',{attrs:{"data":{
          archive_type: 'archive',
          archive: _vm.request.postType,
          posts_per_page: 16,
          infinite_scroll: true,
          archive_category: {
            taxonomy: _vm.request.category,
            term_id: 667,
          },
          layout: [
            {
              media_query: 'default',
              layout_size: '12',
              layout_type: 'grid',
            },
            {
              media_query: 'm',
              layout_size: '6',
              layout_type: 'grid',
            },
            {
              media_query: 'l',
              layout_size: '3',
              layout_type: 'grid',
            } ],
        }}})],1)]):_vm._e(),(_vm.$mq.isMobile)?_c('Section',{attrs:{"layout":{
      section_background_color: _vm.cover.backgroundColor,
      section_spacer: 'm',
      section_wrapper: 'xl',
    }}},[_c('Cover',{attrs:{"data":{
        title: _vm.cover.title,
        text: _vm.cover.text,
        figure: _vm.cover.image,
      }}})],1):_vm._e(),(!_vm.$mq.isMobile && _vm.options.sections && _vm.options.sections[0])?_c('Wrapper',{attrs:{"size":"xxl"}},[_c('Separator')],1):_vm._e(),_vm._l((_vm.options.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:("section--" + parentIndex),attrs:{"layout":_vm.layout(section)}},_vm._l((section.blocks),function(block,index){return _c('Spacer',{key:index,attrs:{"template":block.block_spacer ? ("block--" + (block.block_spacer)) : ''}},[_c(_vm.components.get(block.acf_fc_layout),{tag:"component",class:[
          'block',
          ("block--" + (block.acf_fc_layout)),
          block.block_classes && block.block_classes !== ''
            ? block.block_classes.split(' ')
            : null,
          block.block_template && block.block_template !== ''
            ? ("block-template--" + (block.block_template))
            : null ],attrs:{"id":block.block_id || null,"data":Object.assign({}, block,
          {section_background_color: section.section_background_color})}})],1)}),1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }