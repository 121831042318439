<template>
  <header
    v-show="!$route.meta.hideHeader"
    :class="[
      'header',
      !mainNavigationVisibility && !showCart ? 'slide-up' : 'slide-down',
    ]"
  >
    <Announcement />
    <Wrapper
      tag="nav"
      class="main-navigation"
      size="xl"
      boxed
    >
      <Flex
        justify="space-between"
        align="center"
      >
        <router-link to="/">
          <img
            class="logo"
            :src="require('@/assets/logo.png')"
          >
        </router-link>
        <Menu
          @show-sub-menu="showSubMenu"
        />
        <Flex
          :column-gap="$mq.isMobileM ? 'xxxs' : 'xs'"
          align="center"
        >
          <Icon
            :fn="toggleSearchBar"
            name="search"
          />
          <WcMenu />
          <Icon
            :fn="toggleMobileMenu"
            :name="mobileMenuVisibility ? 'mobile-close' : 'mobile'"
            size="m"
          />
        </Flex>
      </Flex>
    </Wrapper>
    <MobileMenu
      :visibility="mobileMenuVisibility"
      @clicked-outside="toggleMobileMenu"
    />
    <SubMenu
      ref="submenu"
      :visibility="subMenuVisibility"
      @hide-sub-menu="hideSubMenu"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

import SubMenu from '@/components/ui/sub-menu';
import MobileMenu from '@/components/ui/mobile-menu';
import Menu from '@/components/ui/menu';
import WcMenu from '@/components/wc/wc-menu';
import Icon from '@/components/ui/icon';
import Announcement from '@/components/ui/site-announcement';

export default {
  name: 'Header',
  components: {
    Menu,
    WcMenu,
    Icon,
    Announcement,
    SubMenu,
    MobileMenu,
  },

  data() {
    return {
      mainNavigationVisibility: true,
      oldScrollY: 0,
      subMenuVisibility: false,
    };
  },

  computed: {
    ...mapGetters(['showCart', 'mobileMenuVisibility']),
  },

  mounted() {
    document.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleSearchBar() {
      this.$store.commit('HIDE_MOBILE_MENU');
      if (this.$route.name !== 'Search') {
        this.$store.commit('TOGGLE_SEARCH_BAR');
      }
    },
    handleScroll() {
      const newScrollY = window.scrollY;

      if (newScrollY > 90 && newScrollY > this.oldScrollY) {
        this.mainNavigationVisibility = false;
        this.oldScrollY = newScrollY;
      } else {
        this.mainNavigationVisibility = true;
        this.oldScrollY = newScrollY;
      }
    },
    showSubMenu() {
      this.subMenuVisibility = true;
      document.addEventListener('mousemove', this.detectHideMenu);
    },
    hideSubMenu() {
      document.removeEventListener('mousemove', this.detectHideMenu);
      this.subMenuVisibility = false;
    },
    toggleMobileMenu() {
      this.$store.commit('HIDE_SEARCH_BAR');
      this.$store.commit('TOGGLE_MOBILE_MENU');
    },
    detectHideMenu(e) {
      if (this.subMenuVisibility) {
        const link = this.$el.querySelector('.navigation__menu__item__submenu');
        if (
          (e.target !== this.$refs.submenu.$el && !this.$refs.submenu.$el.contains(e.target))
          && (e.target !== link && !link.contains(e.target))
        ) {
          this.hideSubMenu();
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .header {
    --main-navigation-height--mobile: 64px;

    position: sticky;
    top: 0;
    transition: transform 0.2s;
    z-index: 100;
  }
  .main-navigation {
    box-shadow: 0 0 10px var(--shadow);
    position: relative;
    background-color: var(--white);
    min-height: var(--main-navigation-height--mobile);
    display: flex;
    align-items: center;
    z-index: 1;

    @include mq(m) {
      min-height: 90px;
    }

    > .wrapper {
      min-height: inherit;

      > .flex {
        min-height: inherit;
      }
    }
  }

  .logo {
    width: 120px;
    height: auto;
    display: block;

    @include mq(m) {
      width: 180px;
    }
  }

  .icon {
    &-mobile,
    &-mobile-close {
      margin-left: var(--spacer-xxs);
      @include mq(l) {
        display: none;
      }
    }
  }

  .slide-up {
    transform: translateY(calc(-100% - 10px));
  }

  .slide-down {
    transform: translateY(0);
  }
</style>
