<template>
  <main>
    <Section
      v-for="(section, parentIndex) in post.acf.sections"
      :key="parentIndex"
      :layout="layout(section)"
      :class="`section--${parentIndex}`"
    >
      <Spacer
        v-for="(block, index) in section.blocks"
        :key="index"
        :template="block.block_spacer ? `block--${block.block_spacer}` : ''"
      >
        <component
          :is="components.get(block.acf_fc_layout)"
          :id="block.block_id || null"
          :class="[
            'block',
            `block--${block.acf_fc_layout}`,
            block.block_classes && block.block_classes !== ''
              ? block.block_classes.split(' ')
              : null,
            block.block_template && block.block_template !== ''
              ? `block-template--${block.block_template}`
              : null,
          ]"
          :data="{
            ...block,
            section_background_color: section.section_background_color,
          }"
        />
      </Spacer>
    </Section>
  </main>
</template>

<script>
import components from '@/assets/js/components';
import data from '@/mixins/data';

import Section from '@/components/section';

export default {
  name: 'Page',
  components: {
    Section,
  },
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  methods: {
    layout(section) {
      const {
        section_background_color,
        section_classes,
        section_layout,
        section_text_color,
        section_id,
        section_wrapper,
        section_spacer,
      } = section;
      return {
        section_background_color,
        section_classes,
        section_layout,
        section_text_color,
        section_id,
        section_wrapper,
        section_spacer,
      };
    },
  },
};
</script>

<style lang="scss">
  .block {
    &--accordion {
      display: grid;
      row-gap: var(--spacer-xxs);
    }
  }
</style>
