<template>
  <div
    v-if="cart.totals"
  >
    <Grid
      col="12"
      row-gap="xxxs"
    >
      <Flex
        justify="space-between"
        class="t-right"
      >
        <h4>{{ $labels.subtotal }}</h4>
        <div v-html="subtotal" />
      </Flex>
      <Flex
        justify="space-between"
        class="t-right"
      >
        <h4>
          <span v-if="theme === 'checkout'">{{ $labels.shipping }}</span>
          <span v-else>{{ $labels.shipping_exstimated }}</span>
        </h4>
        <div>
          <span
            v-if="shippingAvailable"
            v-html="shipping"
          />
          <span
            v-else
            v-html="`To be defined`"
          />
        </div>
      </Flex>
      <Flex
        v-if="cart.coupons.length > 0"
        justify="space-between"
        class="t-right"
      >
        <div>{{ $labels.coupon }}</div>
        <div>
          -
          <span v-html="discount" />
        </div>
      </Flex>
      <Flex
        v-if="taxDisplayCart === 'excl'"
        justify="space-between"
        class="t-right"
      >
        <h4>
          <span v-if="theme === 'checkout'">{{ $labels.vat }}</span>
          <span v-else>{{ $labels.vat_extimated }}</span>
        </h4>
        <div>
          <span v-html="vat" />
        </div>
      </Flex>
    </Grid>
    <Spacer y="m">
      <Separator />
    </Spacer>
    <Flex
      justify="space-between"
    >
      <h4 class="t-uppercase">
        {{ $labels.total }}
      </h4>
      <h4 v-html="parseCurrency(unparseCurrency(cart.totals.total))" />
    </Flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseCurrency, unparseCurrency, exclVatLabel } from '@/assets/js/utils-wc';
import Separator from '@/components/blocks/blocks-separator';

export default {
  name: 'CheckoutOrder',
  components: {
    Separator,
  },
  props: {
    theme: {
      type: String,
      default: 'checkout',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['cart', 'wcSettings']),
    shippingAvailable() {
      return Object.keys(this.cart.shipping_methods).map((method) => this.cart.shipping_methods[method].chosen_method);
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(this.unparseCurrency((this.cart.totals.subtotal)) + this.unparseCurrency((this.cart.totals.subtotal_tax)));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.subtotal)))}  ${exclVatLabel(this.$labels.excluded_vat)}`;
      }
      return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.subtotal)))} + ${this.parseCurrency(this.unparseCurrency((this.cart.totals.subtotal_tax)))}`;
    },
    shipping() {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(this.unparseCurrency((this.cart.totals.shipping_total)) + this.unparseCurrency((this.cart.totals.shipping_tax)));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.shipping_total)))}  ${exclVatLabel(this.$labels.excluded_vat)}`;
      }
      return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.shipping_total)))} + ${this.parseCurrency(this.unparseCurrency((this.cart.totals.shipping_tax)))}`;
    },
    discount() {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(this.unparseCurrency((this.cart.totals.discount_total)) + this.unparseCurrency((this.cart.totals.discount_tax)));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.discount_total)))}  ${exclVatLabel(this.$labels.excluded_vat)}`;
      }
      return `${this.parseCurrency(this.unparseCurrency((this.cart.totals.discount_total)))} + ${this.parseCurrency(this.unparseCurrency((this.cart.totals.discount_tax)))}`;
    },
    vat() {
      return this.parseCurrency(this.unparseCurrency(this.cart.totals.total_tax));
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {
    getLineTotal(cartItem) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(this.unparseCurrency((cartItem.line_subtotal)) + this.unparseCurrency((cartItem.line_subtotal_tax)));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(this.unparseCurrency((cartItem.line_subtotal)))}  ${exclVatLabel(this.$labels.excluded_vat)}`;
      }
      return this.parseCurrency(this.unparseCurrency((cartItem.line_subtotal)) + this.unparseCurrency((cartItem.line_subtotal_tax)));
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style>

</style>
