var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"mobile-menu"}},[_c('Wrapper',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibility),expression:"visibility"}],staticClass:"mobile-menu",attrs:{"size":"xl","boxed":""}},[_c('Spacer',{attrs:{"y":"m"}},[_c('Flex',{attrs:{"dir":"column","row-gap":"s","nowrap":""}},_vm._l((_vm.navigationMenu),function(mainItem){return _c('div',{key:mainItem.id,staticClass:"mobile-menu__main-item"},[_c('Cta',{class:[
              mainItem.url === '/palloncini/' ? 'typo--m--700' : 'typo--m' ],attrs:{"data":{
              url: mainItem.url,
              title: mainItem.content,
            }}}),(mainItem.url === '/palloncini/')?_c('div',{staticClass:"mobile-menu__highlights"},_vm._l((_vm.subMenuHighlights),function(section){return _c('router-link',{key:section.id,attrs:{"to":("/palloncini/categoria/" + (section.category.slug))}},[_c('div',{staticClass:"sub-menu__section sub-menu__section--highlight"},[_c('h4',{staticClass:"cta__title"},[_vm._v(" "+_vm._s(section.title)+" ")]),_c('Figure',{attrs:{"data":Object.assign({}, section.image,
                    {ratio: true,
                    object_fit: 'contain'})}})],1)])}),1):_vm._e(),_vm._l((mainItem.children),function(section){return _c('div',{key:section.id,staticClass:"sub-menu__section"},[_c('h4',{staticClass:"cta__title"},[_vm._v(" "+_vm._s(section.content)+" ")]),_c('Flex',{attrs:{"dir":"column","row-gap":"xxxs","tag":"nav"}},_vm._l((section.children),function(item){return _c('Cta',{key:item.id,attrs:{"data":{
                  url: item.url,
                  title: item.content,
                }}})}),1)],1)})],2)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }