import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  selectedNumber: '18',
  searchBarIsVisible: false,
  mobileMenuVisibility: false,
};

export default {
  state,
  mutations,
  getters,
  actions,
};
