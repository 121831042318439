<template>
  <div class="accordion-wrapper">
    <AccordionWrapper
      v-for="(item, index) in data.items"
      :key="index"
    >
      <AccordionItem>
        <template slot="accordion-trigger">
          <h4>{{ item.title_trigger }}</h4>
        </template>
        <template slot="accordion-content">
          <Content :data="{ title: item.title, rich_text: item.rich_text}" />
        </template>
      </AccordionItem>
    </AccordionWrapper>
  </div>
</template>

<script>
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';

import Content from '@/components/blocks/content';
// import Cta from '@/components/typo/cta';

export default {
  name: 'Accordion',
  components: {
    AccordionWrapper,
    AccordionItem,
    Content,
    // Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">

.accordion__trigger {
  cursor: pointer;
  &--active {
    &.chevron {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &.chevron {
    position: relative;
    max-width: initial;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background: url('~@/assets/svg/icons/chevron.svg');
      transition: transform 0.3s ease;
    }
  }
}

</style>
