var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-menu"},[_c('Flex',{staticClass:"account-menu__items",attrs:{"column-gap":"xs","tag":"nav","nowrap":""}},[_c('Cta',{attrs:{"theme":_vm.$route.name === 'EditAccount'
          ? 'button--alt'
          : 'button--alt--disabled',"data":{
        title: _vm.$labels.account_details,
        url: { name: 'EditAccount' },
      }}}),_c('Cta',{attrs:{"theme":_vm.$route.name === 'Orders' ? 'button--alt' : 'button--alt--disabled',"data":{
        title: _vm.$labels.orders,
        url: { name: 'Orders' },
      }}}),(_vm.areSubscriptionsActive)?_c('Cta',{attrs:{"theme":_vm.active ? 'button--alt' : 'button--alt--disabled',"data":{
        title: _vm.$labels.subscriptions,
        url: { name: 'Subscriptions' },
      }}}):_vm._e(),_c('Cta',{attrs:{"theme":"button","data":{
        title: _vm.$labels.logout,
        url: { name: 'Account' },
        fn: _vm.logout,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }