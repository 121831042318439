<template>
  <ValidationProvider
    v-if="!data.hidden"
    v-slot="{ errors, classes }"
    mode="lazy"
    :rules="data.rules"
    :vid="data.vid"
    tag="div"
  >
    <div
      :class="[
        'field field--input',
        ...classes,
        { empty: labelEmpty },
        { focus: labelFocus },
      ]"
    >
      <slot
        name="label"
        class="field-label"
      />
      <div class="field-input">
        <input
          :id="data.id || $vnode.key"
          :ref="data.ref ? data.ref : null"
          v-model.trim="computedModel"
          :name="data.id || $vnode.key"
          :type="data.type"
          :readonly="data.readonly"
          spellcheck="false"
          :placeholder="computedPlaceholder"
          :maxlength="data.maxlength ? data.maxlength : false"
          @change="data.fn ? data.fn() : false"
          @blur="blur"
          @focus="labelPosition"
        >
        <Icon
          name="alert"
          theme="warning"
          size="s"
        />
        <Icon
          v-if="data.tooltip"
          v-tooltip.top-center="data.tooltip"
          name="help"
          size="s"
        />
      </div>
    </div>
    <Errors
      v-if="data.errorsPlaceholder || data.rules"
      :background-color="backgroundColor"
      :errors="errors"
    />
  </ValidationProvider>
</template>

<script>

import isEmpty from 'lodash.isempty';
import debounce from 'lodash.debounce';
import { ValidationProvider } from 'vee-validate';
import Errors from '@/components/form/fields/field-errors';
import Icon from '@/components/ui/icon';

export default {
  name: 'Input',
  components: {
    ValidationProvider,
    Icon,
    Errors,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      labelFocus: false,
    };
  },
  computed: {
    labelEmpty() {
      return isEmpty(this.computedModel);
    },
    computedPlaceholder() {
      if (
        (this.data.placeholder && this.labelFocus)
          || this.data.placeholderIsVisible
      ) {
        return this.$labels[this.data.placeholder];
      }
      return null;
    },
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.focusElement();
    });
    if (this.data.inputFn) {
      this.debounceHandler = debounce(this.data.inputFn, 1000);
      this.$el
        .querySelector('input')
        .addEventListener('input', this.debounceHandler);
    }
  },
  beforeDestroy() {
    if (this.data.inputFn) {
      this.$el
        .querySelector('input')
        .removeEventListener('input', this.debounceHandler);
    }
  },
  methods: {
    labelPosition(e) {
      if (e.type === 'focus') {
        this.labelFocus = true;
      }
      if (e.type === 'blur') {
        this.labelFocus = false;
      }
    },
    blur(e) {
      this.labelPosition(e);

      if (this.data.id === 'search' && this.$route.name !== 'Search') {
        // this.$store.commit('TOGGLE_SEARCH_BAR');
      }
    },
    focusElement() {
      if (this.$refs.focusable) {
        this.$refs.focusable.focus();
      }
    },
  },
};
</script>

<style></style>
