<template>
  <div class="product-size-chart__visual">
    <figure class="product-size-chart__visual__figure">
      <img
        :class="`product-tabs-sizes__balloon--${balloonType.slug}`"
        :src="require(`@/assets/svg/sizes/${balloonType.slug}.svg`)"
        alt=""
      >
      <figcaption>{{ `${balloonType.acf.height} cm` }}</figcaption>
    </figure>
    <figure
      class="product-size-chart__visual__figure product-size-chart__visual__figure--human"
    >
      <img
        :src="require(`@/assets/svg/sizes/human.svg`)"
        alt=""
      >
      <figcaption>170 cm</figcaption>
    </figure>
  </div>
</template>

<script>
export default {
  props: {
    balloonType: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
  .product-size-chart {
    &__visual {
      align-items: end;
      background-color: var(--grey-1);
      border-radius: var(--border-radius);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      max-height: 300px;
      padding: var(--spacer-s);
      max-width: 300px;

      @include mq(m) {
        height: 300px;
        width: 300px;
      }

      &__figure {
        align-items: end;
        display: grid;
        grid-template-rows: 1fr min-content;
        height: 100%;
        justify-items: center;
        row-gap: var(--spacer-xxxs);

        &--human {
          img {
            height: 210px;
            width: auto;
          }
        }
      }
    }
  }
</style>
