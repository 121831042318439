export default {
  data() {
    return {
      settings: {
        sendLabel: 'search_button',
      },
      groups: {
        search: {
          visible: true,
          fields: {
            search: {
              id: 'search',
              type: 'text',
              model: this.$route.query.s,
              placeholder: 'search_placeholder',
              placeholderIsVisible: true,
              ref: 'focusable',
              inputFn: this.callback,
            },
          },
        },
      },
    };
  },
  methods: {
    callback() {
      const searchQuery = this.groups.search.fields.search.model;

      if (searchQuery !== '') {
        this.$router.push({
          name: 'Search',
          query: {
            s: searchQuery,
          },
        });

        // this.$store.commit('TOGGLE_SEARCH_BAR')
      }
    },
  },
};
