var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',_vm._l((_vm.post.acf.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:("section--" + parentIndex),attrs:{"layout":_vm.layout(section)}},_vm._l((section.blocks),function(block,index){return _c('Spacer',{key:index,attrs:{"template":block.block_spacer ? ("block--" + (block.block_spacer)) : ''}},[_c(_vm.components.get(block.acf_fc_layout),{tag:"component",class:[
          'block',
          ("block--" + (block.acf_fc_layout)),
          block.block_classes && block.block_classes !== ''
            ? block.block_classes.split(' ')
            : null,
          block.block_template && block.block_template !== ''
            ? ("block-template--" + (block.block_template))
            : null ],attrs:{"id":block.block_id || null,"data":Object.assign({}, block,
          {section_background_color: section.section_background_color})}})],1)}),1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }