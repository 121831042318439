<template>
  <div>
    <Grid
      v-if="data.block_template === 'newsletter--full'"
      column-gap="xs"
    >
      <Column
        v-if="!$mq.isMobile"
        size="5"
      >
        <Media :data="{ ...data.newsletter_image, object_fit: 'contain' }" />
      </Column>
      <Column :size="{ default: 12, l: 7 }">
        <Title :data="{ value: data.title, tag: 'h1', size: 'xl' }" />
        <Spacer
          v-if="$mq.isMobile"
          y="xxl"
        >
          <Media :data="{ ...data.newsletter_image, justify: 'center' }" />
        </Spacer>
        <Spacer
          :top="{ l: 'xs' }"
          bottom="cover"
        >
          <RichText :data="{ value: data.rich_text }" />
        </Spacer>
        <Form
          id="newsletter"
          theme="highlight--inline"
          :background-color="data.section_background_color"
        />
      </Column>
    </Grid>
    <Grid
      v-else
      col="12"
      row-gap="m"
    >
      <Content
        :data="{
          ...data,
          blockTemplate: 'newsletter--footer',
        }"
      />
      <Form
        id="newsletter"
        theme="inline"
      />
    </Grid>

    <!-- <Grid
      v-if="!success"
      grid="1"
      :row-gap="data.block_template === 'newsletter--full' ? 'xl' : 'm'"
      :class="['form', avoid ? 'form-avoid' : false]"
    >
    </Grid> -->
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import Content from '@/components/blocks/content';
import Form from '@/components/form';
import Media from '@/components/blocks/media';

export default {
  name: 'Newsletter',
  components: {
    Form,
    Media,
    Title,
    Content,
    RichText,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      success: false,
      avoid: false,
    };
  },
  methods: {
    submit() {
      if (this.avoid) return;

      this.avoid = true;
      const request = {
        fn: 'mailchimp',
        params: {
          list_id: this.data.list_id,
          email: this.email,
        },
      };
      this.$store
        .dispatch('sendRequest', request)
        .then((response) => {
          if (response.data.status && response.data.status === 'subscribed') {
            this.success = true;
          } else {
            this.success = false;
            this.avoid = false;
          }
        })
        .catch((error) => {
          this.success = false;
          this.avoid = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
  .form {
    &-avoid {
      cursor: progress;

      form {
        pointer-events: none;
      }
    }
  }
</style>
