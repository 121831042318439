<template>
  <nav>
    <Flex :column-gap="$mq.isMobileM ? 'xxxs' : 'xs'">
      <Icon
        :to="{ name: 'EditAccount' }"
        name="user"
      />
      <div class="cart__icon">
        <Icon
          :to="{ name: 'Cart' }"
          name="cart"
        />
        <div
          v-if="cart.items && cart.items.length !== 0"
          class="cart__icon__number typo--xs--700"
        >
          {{ cart.items.length }}
        </div>
      </div>
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/icon';

export default {
  name: 'WCMenu',
  components: {
    Icon,
  },
  computed: {
    ...mapGetters(['user', 'cart']),
  },
  methods: {
    toggleCart() {
      this.$store.commit('TOGGLE_CART');
    },
  },
};
</script>

<style lang="scss">
.cart {
  &__icon {
    position: relative;

    &__number {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(8px, -8px);
      display: grid;
      justify-items: center;
      align-items: center;
      background-color: var(--red);
      color: var(--white);
      height: 20px;
      width: 20px;
      border-radius: var(--border-radius--full);
    }
  }
}

</style>
