var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Grid',{attrs:{"col":"12","row-gap":"s"}},_vm._l((_vm.productCartSorted),function(cartItem,index){return _c('div',{key:index,class:{'cart__item-wrapper--item-data' : cartItem.custom_item_data},attrs:{"data-key":cartItem.key,"data-quantity":cartItem.quantity}},[_c('Flex',{staticClass:"cart__item",attrs:{"nowrap":true,"column-gap":"xs","row-gap":"xs","dir":_vm.$mq.isMobileS ? 'column' : 'row'}},[_c('div',{staticClass:"wc-product-image ratio--11",style:({
          backgroundImage: ("url(" + (cartItem.product_image) + ")"),
        })}),_c('Flex',{attrs:{"dir":"column","justify":"space-between","row-gap":"xs"}},[_c('div',[_c('router-link',{staticClass:"typo--s--700",attrs:{"to":{
              name: 'SingleProduct',
              params: {
                slug: cartItem.slug,
              },
            }}},[_vm._v(" "+_vm._s(cartItem.product_name)+" "),(cartItem.custom_item_data)?_c('Spacer',{attrs:{"top":"xxxs"}},_vm._l((cartItem.custom_item_data),function(itemData,j){return _c('div',{key:j,staticClass:"typo--xs"},[(j === 'items')?_c('div',_vm._l((itemData),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_c('div',[_c('span',{staticClass:"t-capitalize"},[_vm._v(_vm._s(j)+":")]),_vm._v(" "+_vm._s(itemData)+" ")])])}),0):_vm._e()],1),_c('Spacer',{attrs:{"top":"xxxs"}},[(cartItem.is_deposit)?_c('div',{domProps:{"innerHTML":_vm._s(cartItem.deposit_item_subtotal)}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getLineTotal(cartItem))}})])],1),(_vm.theme !== 'checkout')?_c('Flex',{attrs:{"align":"center","column-gap":"xxxs"}},[(!cartItem.custom_item_data)?_c('Number',{key:_vm.cartLoading,attrs:{"data":{
              id: "number",
              fn: _vm.numberChanged,
              itemKey: cartItem.key,
              readonly: _vm.cartLoading
            },"value":cartItem.quantity}}):_vm._e(),_c('Icon',{attrs:{"name":"close","size":"xs","theme":"alt","fn":function () { return _vm.removeCartItem(cartItem); }}})],1):_vm._e()],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }