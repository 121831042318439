<template>
  <transition name="sub-menu">
    <Wrapper
      v-show="subMenuVisibility"
      size="xl"
      class="sub-menu"
      boxed
    >
      <Spacer y="m">
        <Grid
          column-gap="xs"
          col="3"
        >
          <div
            v-for="section in subMenu"
            :key="section.id"
            class="sub-menu__section"
          >
            <h4>{{ section.content }}</h4>
            <Flex
              dir="column"
              row-gap="xxxs"
              tag="nav"
            >
              <Cta
                v-for="item in section.children"
                :key="item.id"
                :data="{
                  url: item.url,
                  title: item.content,
                }"
                @click.native="discardSubMenu"
              />
            </Flex>
          </div>
          <router-link
            v-for="section in subMenuHighlights"
            :key="section.id"
            :to="`/palloncini/categoria/${section.category.slug}`"
            @click.native="discardSubMenu"
          >
            <div class="sub-menu__section sub-menu__section--highlight">
              <h4>{{ section.title }}</h4>
              <Figure
                v-if="section.image"
                :data="{
                  ...section.image,
                  ratio: true,
                  object_fit: 'contain',
                }"
              />
            </div>
          </router-link>
        </Grid>
      </Spacer>
    </Wrapper>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Figure from '@/components/media/figure';

export default {
  name: 'SubMenu',
  components: {
    Cta,
    Figure,
  },
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hide-sub-menu'],
  computed: {
    ...mapGetters(['menu']),
    subMenu() {
      const menu = this.menu('Header').items.find(
        (e) => e.url === '/palloncini/',
      ).children;
      return menu;
    },
    subMenuHighlights() {
      const menu = this.menu('Header');
      const highlights = [menu.acf.section_1, menu.acf.section_2];

      return highlights;
    },
    subMenuVisibility() {
      return this.visibility;
    },
  },
  methods: {
    discardSubMenu() {
      this.$emit('hide-sub-menu');
    },
  },
};
</script>

<style lang="scss">
  .sub-menu {
    box-shadow: 0 0 10px var(--shadow);
    position: fixed;
    background-color: var(--white);

    &__section {
      display: grid;
      row-gap: var(--spacer-xs);
      align-content: start;

      &--highlight {
        align-content: space-between;
        height: 100%;
      }

      .picture {
        border-radius: var(--border-radius);
      }
    }
  }

  .sub-menu-enter-active,
  .sub-menu-leave-active {
    transition: all 0.2s ease;
  }
  .sub-menu-enter,
  .sub-menu-leave-to {
    transform: translateY(-100%);
  }
</style>
