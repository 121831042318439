<template>
  <AccordionWrapper
    :id="'order-'+order.id"
    class="form-theme--default"
  >
    <AccordionItem :data-order="order.id">
      <template slot="accordion-trigger">
        <Grid column-gap="xs">
          <Column
            :size="{
              default: 6,
              xs: 3,
            }"
          >
            <span v-if="!$mq.isMobileXS">{{ $labels.order }}</span>
            #{{ order.id }}
          </Column>
          <Column
            v-if="$mq.isMobileXS"
            :size="{
              default: 9,
              s: 3,
            }"
          >
            <div class="t-ellipsis">
              {{ orderStatus }}
            </div>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <div class="t-ellipsis">
              {{ parseDate(order.created_date) }}
            </div>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <Flex
              align="center"
              nowrap
            >
              <div class="t-ellipsis">
                {{ orderStatus }}
              </div>
              <Spacer
                v-if="orderActions[0]"
                left="xs"
              >
                <Icon
                  name="alert"
                  size="xs"
                />
              </Spacer>
            </Flex>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <div
              class="t-ellipsis"
              v-html="getOrderTotal(order)"
            />
          </Column>
        </Grid>
      </template>
      <template slot="accordion-content">
        <Grid
          col="12"
          row-gap="l"
        >
          <div
            v-if="!$mq.isMobileXS"
            class="typo--m--700"
          >
            <span v-if="theme === 'default'">
              {{ $labels.order_details }}
            </span>
            <span v-else>{{ $labels.details }}</span>
          </div>
          <div v-else>
            <OrderHeader
              :order="order"
              :theme="theme"
            />
            <div class="separator--row" />
          </div>
          <OrderDetails
            :order="order"
            :theme="theme"
          />
        </Grid>
      </template>
    </AccordionItem>
  </AccordionWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  parseDate,
  parseCurrency,
  availableOrderActions,
  getOrderTotal,
} from '@/assets/js/utils-wc';

import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import OrderHeader from '@/components/wc/order-header';
import OrderDetails from '@/components/wc/order-details';
import Icon from '@/components/ui/icon';

export default {
  name: 'Order',
  components: {
    AccordionWrapper,
    AccordionItem,
    OrderHeader,
    OrderDetails,
    Icon,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      parseCurrency,
    };
  },
  computed: {
    ...mapGetters(['wcSettings']),
    orderStatus() {
      return this.$store.state.wp.wcSettings.woocommerce_order_statuses[
        `wc-${this.order.status}`
      ];
    },
    orderActions() {
      return availableOrderActions[`wc-${this.order.status}`];
    },
  },
  mounted() {},
  methods: {
    parseDate(date) {
      if (date) {
        return parseDate(date, this.wcSettings);
      }
      return null;
    },
    getOrderTotal(order) {
      return getOrderTotal(order, this.wcSettings);
    },
    // closeOtherOrders() {
    //   document.querySelectorAll('.accordion__trigger--active').forEach((accordion) => {
    //     console.log(Number(accordion.parentElement.dataset.order), this.order.id);
    //     if (Number(accordion.parentElement.dataset.order) !== Number(this.order.id)) {
    //       accordion.click();
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss"></style>
