<template>
  <Section
    :layout="{
      section_spacer: 'l',
      section_wrapper: 'xl',
    }"
  >
    <div v-if="results.length > 0">
      <Spacer
        bottom="xs"
        tag="h5"
      >
        {{ $labels.search_results(results) }}
      </Spacer>
      <Grid
        :col="{ default: 12, m: 3 }"
        class="archive-grid"
        column-gap="xs"
      >
        <div
          v-for="item in results"
          :key="item.id"
          class="item"
        >
          <Thumb :data="item" />
        </div>
      </Grid>
    </div>
    <div v-else-if="results.length === 0 && !found">
      {{ emptyState }}
    </div>
    <div v-else>
      {{ placeholder }}
    </div>
  </Section>
</template>

<script>
// import groupBy from 'lodash.groupby';

import Thumb from '@/components/thumb/product';
import Section from '@/components/section';

export default {
  name: 'Search',
  components: {
    Thumb,
    Section,
  },
  data() {
    return {
      results: [],
      found: true,
      placeholder: 'Sto cercando...',
      emptyState: 'Nessun risultato',
    };
  },
  watch: {
    $route(to, old) {
      if (to.query.s && to.query.s !== old.query.s) {
        this.setRequest(to.query.s);
      }
    },
  },
  mounted() {
    if (this.$route.query.s) this.setRequest(this.$route.query.s);
  },
  methods: {
    setRequest(params) {
      const request = {
        type: 'product',
        params: {
          search: params,
          lang: 'default',
          per_page: 100,
          offset: 0,
        },
      };

      this.$store.dispatch('getItems', request).then((res) => {
        this.results = res;
        if (res.length !== 0) {
          this.results = res;
        } else {
          this.found = false;
          this.results = [];
        }
      });
    },
  },
};
</script>
