<template>
  <transition name="fade">
    <div
      v-show="show"
      class="modal"
      :tabindex="show ? 0 : -1"
      @click="close"
    >
      <Wrapper
        v-if="data"
        :class="['modal-wrapper', `modal-theme--${theme}`, 'overlay']"
        :size="data.wrapperSize ? data.wrapperSize : 'm'"
        :boxed="!$mq.isMobileS ? true : false"
      >
        <div class="modal-container">
          <Icon
            v-if="!data.mandatory"
            name="close"
            :fn="close"
            theme="minimal"
            size="m"
            class="icon--close"
          />

          <template v-if="data.type === 'video'">
            <Video
              ref="video"
              :data="{
                embed: true,
                url: data.url,
              }"
            />
          </template>

          <template v-if="data.type === 'content'">
            <Spacer all="m">
              <Content
                :data="{
                  title: data.content.title,
                  rich_text: data.content.richtext,
                  cta: data.content.cta,
                }"
              />
            </Spacer>
          </template>

          <template v-if="data.type === 'component'">
            <component
              :is="data.component"
              :data="data"
            />
          </template>

          <template v-if="data.type === 'dialog'">
            <Spacer all="wrapper">
              <Grid
                row-gap="xs"
                col="12"
              >
                {{ data.content.title }}
                <Title
                  v-if="data.content.title && data.content.title !== ''"
                  :data="{
                    value: data.content.title,
                  }"
                />
                <Richtext
                  v-if="data.content.richtext && data.content.richtext !== ''"
                  :data="{
                    value: data.content.richtext,
                  }"
                />
                <Flex
                  v-if="data.content.cta"
                  justify="center"
                >
                  <Cta
                    :data="{
                      fn: dialogFn,
                      title: data.content.cta.title,
                    }"
                    theme="button"
                  />
                </Flex>
              </Grid>
            </Spacer>
          </template>
        </div>
      </Wrapper>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/ui/icon';
import Video from '@/components/media/video';
import Content from '@/components/blocks/content';
import Cta from '@/components/typo/cta';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';

import ProductSizeChart from '@/components/pad/product-size-chart';

export default {
  name: 'Modal',
  components: {
    Video,
    Content,
    Icon,
    Cta,
    Title,
    Richtext,
    ProductSizeChart,
  },
  data() {
    return {
      show: false,
      data: null,
      force: true,
    };
  },
  computed: {
    theme() {
      let theme = 'default';
      if (this.data && this.data.theme) theme = this.data.theme;

      return theme;
    },
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add('overflow-hidden');
        document.body.addEventListener('keydown', this.close);
      } else {
        document.body.classList.remove('overflow-hidden');
        document.body.removeEventListener('keydown', this.close);
      }
    },
  },
  mounted() {
    this.$bus.$on('modal', this.init);
    this.$bus.$on('openModal', this.open);
    this.$bus.$on('populateModal', this.content);
    this.$bus.$on('removeContentModal', this.remove);
  },
  beforeDestroy() {
    this.$bus.$off('modal', this.init);
    this.$bus.$off('openModal', this.open);
    this.$bus.$off('populateModal', this.content);
    this.$bus.$off('removeContentModal', this.remove);
  },
  methods: {
    init(data) {
      this.data = data;
      this.show = true;
    },
    content(data) {
      this.data = data;
      this.force = false;
    },
    open(id) {
      if (id === this.data.id) {
        this.show = true;
      }
    },
    close(e) {
      if (e.keyCode === 27 || e.type === 'click') {
        if (this.data.type === 'video' && this.$refs.video) {
          this.$refs.video.plyr.destroy();
        }

        this.show = false;

        if (this.force) this.data = null;
      }
    },
    remove(id) {
      if (id) {
        if (this.data.id === id) {
          this.show = false;
          this.data = null;
        }
      }
    },
    dialogFn() {
      this.data.fn();
      this.remove(this.data.id);
    },
    addToCart() {
      this.$store.dispatch(
        'addToCart',
        Object.assign(this.data, {
          id: String(this.data.id),
          quantity: 1,
          quantityDelta: 1,
        }),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal {
    display: grid;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;

    .icon {
      z-index: 300;
      position: absolute;
      top: calc(var(--spacer-wrapper) / 2);
      right: calc(var(--spacer-wrapper) / 2);
    }

    &-theme {
      &--default {
        .modal-container {
          display: grid;
          justify-items: center;
          gap: var(--row-gap-xs);
          max-height: 100vh;
          padding: var(--spacer-wrapper);
          background: var(--white);
          box-shadow: 0 0 10px var(--shadow);
          border-radius: var(--border-radius);
          text-align: center;
        }
      }
      &--number-configurator {
        .modal-wrapper {
          height: 90vh;
          background: var(--white);
          border-radius: var(--border-radius);
          padding: var(--spacer-l);
        }
      }

      &--size-chart {
        .modal-container {
          display: grid;
          justify-items: center;
          max-height: 100vh;
          padding: var(--spacer-wrapper);
          overflow-y: scroll;
          background: var(--white);
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }

          @include mq(s) {
            max-height: 90vh;
            box-shadow: 0 0 10px var(--shadow);
            border-radius: var(--border-radius);
          }
        }
        .icon {
          top: var(--spacer-wrapper);
          right: var(--spacer-wrapper);
        }
      }
    }
  }

  .number-configurator {
    height: 100%;
    grid-template-rows: min-content min-content 1fr min-content;

    &__images {
      grid-column: span 2;
      grid-row: span 2;
    }
  }
</style>
