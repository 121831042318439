var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFooter),expression:"showFooter"}]},[_c('Separator'),_c('Spacer',{staticClass:"page-footer spacer--section--m"},[_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('Grid',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.hideFooter),expression:"!$route.meta.hideFooter"}],attrs:{"column-gap":_vm.$mq.isMobileM ? '' : 'xs',"row-gap":"l"}},[_vm._l((_vm.footerMenus),function(menu,menuIndex){return _c('Column',{key:menuIndex,attrs:{"size":{ default: 12, m: 4, l: 2 }}},[_c('Spacer',{staticClass:"typo--xs",attrs:{"tag":"h6","bottom":"xxxs","use-margin":true}},[_vm._v(" "+_vm._s(menu.items[0].content)+" ")]),_c('Flex',{attrs:{"dir":"column","row-gap":"xxxs"}},_vm._l((menu.items[0].children),function(item,index){return _c('Cta',{key:index,attrs:{"data":{
                url: item.url,
                title: item.content,
                target: item.target,
              }}})}),1)],1)}),(!_vm.$mq.isMobile)?_c('Column',{attrs:{"size":{ default: 12, m: 4 },"offset":{ l: 7 }}},[_c('img',{staticClass:"logo--footer",attrs:{"src":require('@/assets/logo.png')}})]):_vm._e(),_c('Column',{attrs:{"size":{ default: 12, m: 8, l: 7 }}},[_c('Spacer',{attrs:{"bottom":"xs","top":{ default: 'l', l: 0 }}},[_c('Title',{attrs:{"data":{
                value: _vm.footerInformation.business_name,
                tag: 'h6',
                size: 'xs',
              }}})],1),_c('RichText',{attrs:{"data":{ value: _vm.footerInformation.business_details, size: 'xs' }}})],1),_c('Column',{staticClass:"footer__social",attrs:{"size":{ default: 12, m: 4 },"offset":{ m: 8, l: 7 }}},[_c('Flex',{attrs:{"column-gap":"xs"}},_vm._l((_vm.socials),function(social){return _c('Icon',{key:social.name,attrs:{"name":social.name,"to":social.url,"external":""}})}),1)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }