var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('main',[_c('Section',{attrs:{"layout":{
        section_spacer: 'l',
        section_wrapper: 'xl',
      }}},[_c('Grid',{attrs:{"column-gap":"xs"}},[_c('Column',{attrs:{"size":{
            default: 12,
            l: 6,
          }}},[_c('Media',{ref:"product-gallery",staticClass:"product__gallery",attrs:{"data":{
              type: 'gallery',
              gallery: _vm.productGallery,
              ratio: true,
              lazyload: false,
              object_fit: true,
            }}})],1),_c('Column',{attrs:{"size":{
            default: 12,
            l: 6,
          }}},[_c('Spacer',{attrs:{"top":{ default: 'm', l: '0' },"bottom":"xxs"}},[_c('Title',{attrs:{"data":{ value: _vm.post.name }}})],1),(_vm.product)?_c('Price',{staticClass:"typo--m--700",attrs:{"product":_vm.product}}):_vm._e(),_c('Spacer',{attrs:{"use-margin":"","y":"m"}},[_c('Separator')],1),_c('ProductTabs',{attrs:{"balloon-types":_vm.post.gds_taxonomies.balloon_types,"description":_vm.post.short_description,"information":_vm.post.description}}),_c('Spacer',{attrs:{"use-margin":"","bottom":"m"}},[_c('Separator')],1),_c('div',{staticClass:"product__interactive"},[(_vm.hasVariations)?_c('div',{staticClass:"product__interactive__variations"},_vm._l((_vm.attributes),function(attribute,index){return _c('div',{key:index},[_c('Select',{staticClass:"product__variation-selector",attrs:{"theme":"variation","data":_vm.getAttribute(attribute),"model":attribute.model},on:{"update:model":function($event){return _vm.$set(attribute, "model", $event)}},model:{value:(attribute.model),callback:function ($$v) {_vm.$set(attribute, "model", $$v)},expression:"attribute.model"}},[_c('label',{attrs:{"slot":"label","for":"terms"},slot:"label"},[_vm._v(_vm._s(attribute.name))])])],1)}),0):_vm._e(),_c('Availability',{attrs:{"product":_vm.product}}),_c('Flex',{attrs:{"row-gap":"xs","column-gap":"xs"}},[_c('Number',{attrs:{"data":{
                  id: "number",
                  fn: _vm.numberChanged,
                  itemKey: _vm.product.id,
                  readonly: _vm.cartLoading || _vm.isOos,
                },"value":_vm.numberToBeAdded},on:{"update:value":function($event){_vm.numberToBeAdded=$event}}}),_c('Cta',{attrs:{"data":{
                  title: _vm.$labels.add_to_cart,
                  url: false,
                  fn: _vm.addToCart,
                },"disabled":!_vm.addableToCart,"theme":"button"}})],1)],1)],1)],1)],1)],1):_vm._e(),_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('Separator')],1),_c('Section',{attrs:{"layout":{
      section_spacer: 'l',
      section_wrapper: 'xl',
    }}},[_c('RelatedProducts',{attrs:{"relatable-product":_vm.product}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }