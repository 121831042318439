export default {
  data() {
    return {
      settings: {
        sendLabel: 'add_coupon',
        // btnSize: 1,
      },
      groups: {
        coupon: {
          visible: true,
          // size: 11,
          fields: {
            coupon: {
              type: 'text',
              model: null,
              label: 'insert_coupon',
            },
          },
        },
      },
    };
  },
  methods: {
    init() {},
    async callback() {
      this.$store.dispatch('addCoupon', {
        coupon: this.groups.coupon.fields.coupon.model,
      });
    },
  },
};
