<template>
  <Wrapper
    ref="search"
    :class="$route.name !== 'Search' ? 'overlay overlay--full' : null"
    size="xl"
    boxed
    @click.native="clickOutside"
  >
    <Form
      id="search"
      theme="search"
    />
    <Separator />
  </Wrapper>
</template>

<script>
// import Cta from '@/components/typo/cta';
import Form from '@/components/form';
import Separator from '@/components/blocks/blocks-separator';

export default {
  name: 'SearchInput',
  components: {
    Form,
    Separator,
    // Cta,
  },
  methods: {
    clickOutside(e) {
      if (e.target.className.includes('overlay')) {
        this.$store.commit('HIDE_SEARCH_BAR');
      }
    },
  },
};
</script>
