<template>
  <component
    :is="tag"
    :class="['wrapper-container', boxed ? 'wrapper--boxed' : false]"
  >
    <div
      :class="[
        'wrapper',
        ...wrapperSize,
        !margin ? 'wrapper--no-margin' : false,
      ]"
    >
      <slot />
    </div>
  </component>
</template>

<script>
export default {
  name: 'Wrapper',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    margin: {
      type: [Boolean],
      default: true,
    },
    boxed: {
      type: [Boolean],
      default: false,
    },
    size: {
      type: [Object, String, Boolean],
      default: false,
    },
  },
  computed: {
    wrapperSize() {
      const { size } = this;
      const sizes = [];
      if (typeof size === 'string' && size !== '') {
        sizes.push(`wrapper--${size}`);
      } else if (typeof size === 'object') {
        Object.keys(size).forEach((key) => {
          if (key !== 'default') {
            sizes.push(`wrapper-${key}--${size[key]}`);
          } else {
            sizes.push(`wrapper--${size[key]}`);
          }
        });
      }

      return sizes;
    },
  },
};
</script>

<style></style>
