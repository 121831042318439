import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// eslint-disable-next-line no-undef
const { wcSettings } = __VUE_WORDPRESS__.state;

export default {
  state: {
    cart: {},
    settings: wcSettings,
    showCart: false,
    coupons: [],
    cartTotals: {},
    cartLoading: false,
    snackbar: {
      content: null,
      active: false,
    },
    user: null,
    customer: {},
    customerSavedMethods: {},
    paymentGateway: null,
    paymentGatewaysActive: [],
    paymentMetas: {},
    customerShippingMethods: {},
    countries: null,
    capChecker: true,
    shippingDetails: {},
  },
  mutations,
  getters,
  actions,
};
