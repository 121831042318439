var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Grid',{staticClass:"number-configurator",attrs:{"col":"4","row-gap":"xs","column-gap":"xs"}},[_c('Column',{attrs:{"size":!_vm.$mq.isMobile ? '2' : '3'}},[_c('Flex',{staticClass:"number-configurator__digits block--default",attrs:{"justify":"center","align":"center","nowrap":""}},[(_vm.filteredDigits.length === 0)?_c('h5',{staticClass:"typo--xl t-center"},[_vm._v(" "+_vm._s(_vm.$labels.number_configurator_empty)+" ")]):(_vm.filteredDigits.length === _vm.selectedNumber.length)?_vm._l((_vm.filteredDigits),function(item,index){return _c('Digit',{key:((item.slug) + "-" + index),ref:"digit",refInFor:true,attrs:{"digit":item,"inflation":_vm.selectedInflation}})}):_vm._e()],2)],1),_c('Column',{staticClass:"number-configurator__side block--default",attrs:{"size":!_vm.$mq.isMobile ? '1' : '3'}},[_c('div',{staticClass:"number-configurator__side__content"},[_c('Title',{attrs:{"data":{
          value: _vm.$labels.number_configurator_title,
          size: 'm--700',
        }}}),_c('div',{staticClass:"number-configurator__side__content__block"},[_c('Richtext',{attrs:{"data":{
            value: _vm.$labels.number_configurator_number_title,
          }}}),_c('Form',{attrs:{"id":"number-input","theme":"inline"}})],1),_c('div',{staticClass:"number-configurator__side__content__block"},[_c('Richtext',{attrs:{"data":{
            value: _vm.$labels.number_configurator_type_title,
          }}}),_c('Flex',{attrs:{"column-gap":"xs","row-gap":"xxxs"}},[_c('Cta',{attrs:{"data":{
              fn: function () { return _vm.setSelectedInflation('aria'); },
              title: 'Aria compressa',
            },"theme":_vm.selectedInflation === 'aria'
                ? 'button--alt'
                : 'button--alt--disabled'}}),_c('Cta',{attrs:{"data":{
              fn: function () { return _vm.setSelectedInflation('elio'); },
              title: 'Elio',
            },"theme":_vm.selectedInflation === 'elio'
                ? 'button--alt'
                : 'button--alt--disabled'}})],1)],1)],1),_c('div',{staticClass:"number-configurator__side__content--centered"},[(_vm.selectedProduct)?_c('h4',{staticClass:"typo--m--700",domProps:{"innerHTML":_vm._s(_vm.selectedVariation.price_html)}}):_vm._e(),_c('Cta',{attrs:{"data":{
          fn: _vm.addToCart,
          title: _vm.$labels.add_to_cart,
        },"theme":"button"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }