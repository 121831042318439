<template>
  <Spacer
    v-if="data.slide.length > 0"
    class="hero"
  >
    <Grid
      v-if="data.slide.length === 1"
      col="12"
      class="hero__item"
    >
      <Content
        v-if="checkContent(data.slide[0])"
        :data="setContent(data.slide[0])"
        class="hero__item__content"
      />
      <Media
        v-if="checkMedia(data.slide[0])"
        :data="{ ...setMedia(data.slide[0])}"
        class="hero__item__media"
      />
    </Grid>

    <Carousel v-else>
      <div
        v-for="(item, index) in data.slide"
        :key="index"
        class="item"
      >
        <Content
          v-if="checkContent(item)"
          :data="setContent(item)"
          class="content"
        />
        <Media
          v-if="checkMedia(item)"
          :data="setMedia(item)"
          class="media"
        />
      </div>
    </Carousel>
  </Spacer>
</template>

<script>
import {
  setContent,
  setMedia,
  checkContent,
  checkMedia,
} from '@/assets/js/utils';
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';

export default {
  name: 'Hero',
  components: {
    Content,
    Media,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      setContent,
      setMedia,
      checkContent,
      checkMedia,
    };
  },
};
</script>

<style lang="scss">
.hero {

  &__item {
    min-height: inherit;

    &__content,
    &__media {
      grid-row: 1;
      grid-column: 1;
    }

    &__content {
      z-index: 2;
    }

    &__media {
      .flex {
        height: 100%;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }

}

// .block-template {
//   &--hero {
//     &--home {
//       min-height: 80vh;
//     }
//   }
// }
</style>
