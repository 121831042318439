<template>
  <Grid
    grid="1"
    row-gap="xxl"
    class="repeater"
  >
    <Title
      v-if="data.title"
      :data="{
        value: data.title,
        tag: 'h2',
        size: data.block_template === 'our-service' ? 'l' : 'xl',
      }"
    />
    <Grid
      :col="gridCol"
      :template="gridTemplate"
      column-gap="xs"
      row-gap="xxl"
    >
      <div
        v-for="(item, index) in data.items"
        :key="index"
        class="item"
      >
        <Media
          v-if="checkMedia(item)"
          :data="setMedia(item)"
        />
        <Content
          v-if="checkContent(item)"
          :data="{ ...setContent(item), blockTemplate: data.block_template }"
        />
      </div>
    </Grid>
  </Grid>
</template>

<script>
import {
  setContent,
  setMedia,
  checkContent,
  checkMedia,
} from '@/assets/js/utils';
import Title from '@/components/typo/title';
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';

export default {
  name: 'Repeater',
  components: {
    Content,
    Media,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      setContent,
      setMedia,
      checkContent,
      checkMedia,
    };
  },
  computed: {
    gridCol() {
      let cols = null;
      const elementsLength = this.data.items?.length
          || this.data.categories?.length
          || this.data.posts?.length;
      let defaultColsNumber = 3;
      if (elementsLength) {
        if (elementsLength < 5) {
          defaultColsNumber = 12 / elementsLength;
        } else {
          defaultColsNumber = 3;
        }
      }
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]:
                layout_size === 'auto'
                  ? defaultColsNumber.toString()
                  : layout_size,
          };
        });
      } else {
        cols = {
          xs: 12,
          s: 6,
          l: defaultColsNumber.toString(),
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'grid';
      }
      return template;
    },
  },
};
</script>

<style lang="scss">
  .block-template {
    &--our-service {
      .title,
      .wysiwyg {
        text-align: center;
        max-width: initial;
      }
      .item .content {
        margin-top: var(--spacer-m);
      }
      img {
        padding: 0 var(--spacer-m);

        @include mq(m) {
          padding: var(--spacer-s) var(--spacer-l);
        }
      }
    }
    &--repeater {
      &--how {
        .grid {
          grid-template-columns: initial;
        }
        .item {
          display: grid;
          row-gap: var(--spacer-l);
          column-gap: var(--spacer-xs);
          text-align: center;
          justify-items: center;

          figure {
            max-width: max-content;
            padding-left: var(--spacer-xs);
            padding-right: var(--spacer-xs);
          }

          @include mq(m) {
            grid-template-columns: repeat(3, 1fr);
            align-items: center;

            &:nth-child(even) {
              figure {
                grid-column-end: -1;
                grid-row: 1;
              }
              .content {
                grid-column-start: 2;
                grid-row: 1;
              }
            }

            figure {
              width: initial;
            }
          }
        }
      }
    }
  }
</style>
