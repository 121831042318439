<template>
  <div class="product-thumb">
    <router-link :to="data.link || data.permalink">
      <Media
        v-if="data.gds_featured_image"
        :data="{
          type: 'image',
          image: data.gds_featured_image,
          ratio: false,
          object_fit: 'cover',
        }"
        :class="[
          'product__image',
          'block--default',
          `ratio--${number ? '43' : '11'}`,
        ]"
      />
    </router-link>
    <div class="product-thumb__text">
      <router-link :to="data.link || data.permalink">
        <Title
          class="t-center"
          :data="{
            tag: 'h4',
            size: 's--700',
            value: data.taxonomy || data.name ? data.name : data.title.rendered,
          }"
        />
      </router-link>
      <Price
        class="t-center"
        :product="data"
      />
      <Availability
        v-if="isOos"
        :product="data"
      />
    </div>
    <Flex justify="center">
      <Cta
        v-if="!hasVariations"
        :section-background-color="data.backgroundColor"
        theme="button"
        :data="{
          title: $labels.add_to_cart,
          url: false,
          fn: addToCart,
        }"
        :disabled="!addableToCart"
      />

      <Cta
        v-else
        theme="button"
        :section-background-color="data.backgroundColor"
        :data="{
          title: $labels.select_option,
          url: data.link || data.permalink,
        }"
      />
    </Flex>
  </div>
</template>

<script>
import { addableToCart, isOos } from '@/assets/js/utils-wc';

import Media from '@/components/blocks/media';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Price from '@/components/wc/price.vue';
import Availability from '@/components/wc/availability.vue';

export default {
  name: 'Thumb',
  components: {
    Media,
    Title,
    Cta,
    Price,
    Availability,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'link',
    },
  },
  computed: {
    isOos() {
      return isOos(this.data);
    },
    hasVariations() {
      return this.data.type.startsWith('variable');
    },
    addableToCart() {
      if (!addableToCart(this.data) || isOos(this.data)) {
        return false;
      }
      return true;
    },
    number() {
      const { categories } = this.data;
      return categories.some((e) => e.slug === 'numeri');
    },
  },
  methods: {
    addToCart() {
      this.$store.dispatch(
        'addToCart',
        Object.assign(this.data, {
          id: String(this.data.id),
          quantity: 1,
          quantityDelta: 1,
        }),
      );
      this.$store.commit('TOGGLE_CART');
    },
  },
};
</script>

<style lang="scss">
  .product__image {
    @media (hover: hover) {
      &:hover {
        box-shadow: 0px 0px 10px var(--shadow--red);
      }
    }
  }

  .product-thumb {
    display: grid;
    row-gap: var(--spacer-s);
    grid-template-rows: min-content 1fr min-content;
    height: 100%;

    &__text {
      display: grid;
      row-gap: var(--spacer-xxxs);
      align-content: start;
    }
  }
</style>
