<template>
  <div
    :class="[
      'cta',
      invertedColors ? `cta-theme--${theme}--inverted` : `cta-theme--${theme}`,
      data.size ? `typo--${data.size}` : null,
      disabled ? 'cta-disabled' : null,
    ]"
  >
    <template v-if="data.fn">
      <button @click="!disabled ? data.fn() : null">
        <Flex
          align="center"
          column-gap="xxxs"
        >
          <slot v-if="data.beforeSlot" />
          <div v-html="data.title" />
          <slot v-if="data.afterSlot" />
        </Flex>
      </button>
    </template>
    <template v-else-if="modal">
      <button @click="openModal">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="hash">
      <button @click="goTo">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="external">
      <a
        :href="data.url"
        :target="data.target"
      >
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link :to="url">
        <Flex
          align="center"
          column-gap="xxxs"
          :justify="data.slotJustify"
        >
          <slot v-if="data.beforeSlot" />
          <div
            class="cta__title"
            v-html="data.title"
          />
          <slot v-if="data.afterSlot" />
        </Flex>
      </router-link>
    </template>
  </div>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Cta',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sectionBackgroundColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    url() {
      if (
        this.data.url
          && typeof this.data.url === 'string'
          && process.env.VUE_APP_BASE_URL
          && process.env.VUE_APP_BASE_URL !== '/'
      ) {
        return this.data.url.replace(process.env.VUE_APP_BASE_URL, '');
      }
      return this.data.url;
    },
    external() {
      return (
        this.url
          && typeof this.url === 'string'
          && this.url.startsWith('http')
      );
    },
    hash() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('#')
      );
    },
    modal() {
      return (
        this.url
          && typeof this.url === 'string'
          && this.url.startsWith('#modal-')
      );
    },
    invertedColors() {
      const invertingColors = ['red', 'green', 'blue'];
      return invertingColors.includes(this.sectionBackgroundColor);
    },
  },
  methods: {
    openModal() {
      if (!this.disabled) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      }
    },
    goTo() {
      if (!this.disabled) {
        const hash = this.data.url;
        gsap.killTweensOf(window);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(hash),
            offsetY: 0, // header height
            autoKill: true,
          },
          duration: 1,
          ease: 'expo.out',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  a {
    &.router-link-active {
      // style
    }
  }

  .cta-disabled {
    // cursor: progress;
    opacity: 0.5;

    * {
      // cursor: progress !important;
      pointer-events: none !important;
    }
  }

  button {
    font-size: inherit;
  }

  .cta {
    width: max-content;
    a,
    button {
      line-height: normal;
      width: max-content;
      text-decoration-color: transparent;
      transition: all 0.6s;
    }
    &-theme {
      &--default {
        a,
        button {
          text-decoration-color: transparent;
          transition: all 0.6s;

          @media (hover: hover) {
            &:hover {
              text-decoration-color: var(--black);
              text-decoration: underline;
              text-underline-offset: 6px;
              text-decoration-thickness: var(--line);
            }
          }
        }
      }
      &--sentence-case {
        .cta__title {
          text-transform: lowercase;
          &::first-letter {
            text-transform: capitalize;
          }
        }
      }

      &--full-width {
        a {
          width: 100%;
        }
      }
      &--button,
      &--button--inverted {
        a,
        button {
          display: flex;
          border: solid var(--red) var(--line);
          border-radius: var(--border-radius--full);
          background: var(--red);
          color: var(--white);
          padding: var(--spacer-xxxs) var(--spacer-xs) 10px var(--spacer-xs);
          text-align: center;
          min-height: 44px;
          transition: all 0.2s;

          @extend %typo--s--700;

          @media (hover: hover) {
            &:hover {
              box-shadow: 0px 0px 10px var(--red);
            }
          }
        }
        &--inverted {
          a,
          button {
            border: solid var(--white) var(--line);
            display: flex;
            background: var(--white);
            color: var(--red);
            min-height: 44px;
            @media (hover: hover) {
              &:hover {
                box-shadow: 0px 0px 10px var(--white);
              }
            }
          }
        }

        &--cat {
          a,
          button {
            display: flex;
            border: solid var(--yellow) var(--line);
            border-radius: var(--border-radius--full);
            background: var(--yellow);
            padding: var(--spacer-xxxs) var(--spacer-xs);
            text-align: center;
            min-height: 44px;
            transition: all 0.2s;
            @extend %typo--s--700;

            @media (hover: hover) {
              &:hover {
                box-shadow: 0px 0px 10px var(--yellow);
              }
            }
          }
        }

        &--alt,
        &--alt--disabled {
          a,
          button {
            display: flex;
            border: solid var(--line);
            padding: var(--spacer-xxxs) var(--spacer-xs);
            border-radius: var(--border-radius--full);
            min-height: 44px;
            @extend %typo--s--700;
          }
        }

        &--alt--disabled {
          a,
          button {
            border: solid var(--line) var(--grey-3);
            color: var(--grey-3);

            @media (hover: hover) {
              &:hover {
                border: solid var(--line);
                color: var(--black);
              }
            }
          }
        }

        &--menu {
          a,
          button {
            border: solid var(--red) var(--line);
            padding: var(--spacer-xxxs) var(--spacer-xs);
            border-radius: var(--border-radius--full);
            color: var(--red);
            min-height: 44px;
          }
        }
      }
      &--warning {
        color: var(--warning-color);
      }
      &--login {
        a,
        button {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row-reverse;
          width: 100%;
          column-gap: var(--spacer-m);
          border-radius: var(--border-radius);
          border: var(--line) solid var(--grey-d);
          padding: var(--spacer-s) var(--spacer-m);
          line-height: normal;

          @include mq($and: $hover) {
            &:hover {
              background: var(--white);
            }
          }
        }
      }
    }
  }
</style>
