export default {
  data() {
    return {
      settings: {
        sendLabel: 'newsletter_button',
      },
      groups: {
        newsletter: {
          visible: true,
          fields: {
            email: {
              type: 'email',
              model: null,
              placeholder: 'Email',
              label: 'email',
              rules: 'email',
            },
          },
        },
      },
    };
  },
  methods: {
    callback() {
      // this.$store.dispatch('checkCap', {
      //   cap: this.groups['cap-checker'].fields.cap.model,

      // });
      console.log(this.groups.newsletter.fields.email.model);
    },
  },
};
