<template>
  <Spacer
    y="xxl"
    :use-margin="true"
  >
    <Wrapper
      v-if="user"
      size="m"
      boxed
    >
      <Grid
        col="12"
        row-gap="l"
      >
        <h2 class="typo--l t-center">
          {{ $labels.my_account }}
        </h2>
        <MenuUser />
        <router-view :key="$route.fullPath" />
      </Grid>
    </Wrapper>
    <Wrapper
      v-else
      size="s"
      boxed
    >
      <Grid
        col="12"
        row-gap="l"
      >
        <div class="t-center typo--l">
          {{ $labels.login }}
        </div>
        <Login />
      </Grid>
    </Wrapper>
    <div />
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

import MenuUser from '@/components/wc/menu-user';
import Login from '@/components/wc/login';

export default {
  name: 'Account',
  components: {
    MenuUser,
    Login,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['user', 'authLoading']),
  },
};
</script>
