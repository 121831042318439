<template>
  <Spacer
    :id="layout.section_id ? layout.section_id : null"
    class="section"
    :class="[
      layout.section_classes && layout.section_classes !== ''
        ? layout.section_classes.split(' ')
        : null,
      `layout--${layout.section_layout}`,
      `background--${layout.section_background_color}`,
    ]"
    tag="section"
    :template="spacerTemplate"
  >
    <Wrapper
      :size="layout.section_wrapper ? layout.section_wrapper : undefined"
      boxed
    >
      <slot />
    </Wrapper>
  </Spacer>
</template>

<script>
export default {
  name: 'Section',
  props: {
    layout: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      spacerTemplate: `section--${this.layout.section_spacer}`,
    };
  },
};
</script>

<style lang="scss">
  .layout {
    &--who {
      .wrapper {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: var(--spacer-xs);
        row-gap: var(--spacer-l);

        .spacer:nth-child(1) {
          grid-column: 1 / -1;
          grid-row: 2;

          @include mq(m) {
            grid-column: span 7;
            grid-row: 1;
          }
        }
        .spacer:nth-child(2) {
          grid-column: 1 / -1;
          grid-row: 1;

          @include mq(m) {
            grid-column: span 5;
          }
        }
      }
    }
  }
</style>
