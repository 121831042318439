<template>
  <div
    v-if="data.cta"
    class="cta-block"
  >
    <Cta
      :data="{...data.cta}"
      :theme="theme"
    />
  </div>
</template>

<script>
import Cta from '@/components/typo/cta';

export default {
  name: 'CtaBlock',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    theme() {
      return this.data.color === 'yellow' ? 'button--cat' : 'button';
    },
  },
};
</script>

<style lang="scss">
.cta-block {
  display: grid;
  justify-items: center;
}
</style>
