var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"modal",attrs:{"tabindex":_vm.show ? 0 : -1},on:{"click":_vm.close}},[(_vm.data)?_c('Wrapper',{class:['modal-wrapper', ("modal-theme--" + _vm.theme), 'overlay'],attrs:{"size":_vm.data.wrapperSize ? _vm.data.wrapperSize : 'm',"boxed":!_vm.$mq.isMobileS ? true : false}},[_c('div',{staticClass:"modal-container"},[(!_vm.data.mandatory)?_c('Icon',{staticClass:"icon--close",attrs:{"name":"close","fn":_vm.close,"theme":"minimal","size":"m"}}):_vm._e(),(_vm.data.type === 'video')?[_c('Video',{ref:"video",attrs:{"data":{
              embed: true,
              url: _vm.data.url,
            }}})]:_vm._e(),(_vm.data.type === 'content')?[_c('Spacer',{attrs:{"all":"m"}},[_c('Content',{attrs:{"data":{
                title: _vm.data.content.title,
                rich_text: _vm.data.content.richtext,
                cta: _vm.data.content.cta,
              }}})],1)]:_vm._e(),(_vm.data.type === 'component')?[_c(_vm.data.component,{tag:"component",attrs:{"data":_vm.data}})]:_vm._e(),(_vm.data.type === 'dialog')?[_c('Spacer',{attrs:{"all":"wrapper"}},[_c('Grid',{attrs:{"row-gap":"xs","col":"12"}},[_vm._v(" "+_vm._s(_vm.data.content.title)+" "),(_vm.data.content.title && _vm.data.content.title !== '')?_c('Title',{attrs:{"data":{
                  value: _vm.data.content.title,
                }}}):_vm._e(),(_vm.data.content.richtext && _vm.data.content.richtext !== '')?_c('Richtext',{attrs:{"data":{
                  value: _vm.data.content.richtext,
                }}}):_vm._e(),(_vm.data.content.cta)?_c('Flex',{attrs:{"justify":"center"}},[_c('Cta',{attrs:{"data":{
                    fn: _vm.dialogFn,
                    title: _vm.data.content.cta.title,
                  },"theme":"button"}})],1):_vm._e()],1)],1)]:_vm._e()],2)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }