<template>
  <div class="product__tabs">
    <Flex column-gap="s">
      <h4
        v-for="tab in tabs"
        :key="tab.name"
        :class="[activeTab === tab ? 'product__tab--active' : 'product__tab']"
        @click="activeTab = tab"
      >
        {{ tab.title }}
      </h4>
    </Flex>
    <RichText
      v-if="activeTab.name !== 'sizes'"
      class="product__tab__content"
      :data="{
        value:
          activeTab.name === 'description'
            ? computedDescription
            : computedInformation,
      }"
    />
    <SizesTab
      v-if="activeTab.name === 'sizes' && balloonTypes.length > 0"
      class="product__tab__sizes"
      :balloon-type="balloonTypes[0]"
    />
    <div
      v-if="activeTab.name === 'sizes' && balloonTypes.length === 0"
      class="product__tab__content"
    >
      <p>
        {{ $labels.product_sizes_placeholder }}
      </p>
    </div>
  </div>
</template>

<script>
import labels from '@/assets/js/labels';

import RichText from '@/components/typo/richtext.vue';
import SizesTab from '@/components/pad/product-tabs-sizes.vue';

export default {
  components: {
    RichText,
    SizesTab,
  },

  props: {
    description: {
      type: String,
      default: '',
    },
    information: {
      type: String,
      default: '',
    },
    balloonTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'description',
          title: labels.product_description_title,
          content: this.description,
        },
        {
          name: 'sizes',
          title: labels.product_sizes_title,
        },
        {
          name: 'information',
          title: labels.product_information_title,
          content: this.information,
        },
      ],
      activeTab: {},
    };
  },
  computed: {
    computedDescription() {
      if (this.description && this.description !== '') {
        return this.description;
      }
      return `<p> ${this.$labels.product_description_placeholder}</p>`;
    },
    computedInformation() {
      if (this.information && this.information !== '') {
        return this.information;
      }
      return `<p> ${this.$labels.product_information_placeholder}</p>`;
    },
  },

  mounted() {
    [this.activeTab] = this.tabs;
  },
};
</script>

<style lang="scss">
  .product {
    &__tabs {
      display: grid;
      row-gap: var(--spacer-m);
    }

    &__tab {
      color: var(--grey-3);
      cursor: pointer;

      &__content {
        overflow-y: auto;
        max-height: 400px;

        :last-child {
          padding-bottom: var(--spacer-m);
        }
      }

      &__sizes {
        padding-bottom: var(--spacer-m);
      }

      &--active {
        color: var(--black);
        text-decoration: underline;
        text-underline-offset: 12px;
        cursor: pointer;
      }
    }
  }
</style>
