import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import wp from './modules/wp';
import wc from './modules/wc';
import pad from './modules/pad';
import auth from './modules/auth';

// eslint-disable-next-line no-undef
const { state } = __VUE_WORDPRESS__;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentPost: null,
    lang: state.i18n ? state.i18n.default : 'default',
    transition: true,
    snackbar: {
      content: null,
      active: false,
    },
  },
  mutations,
  getters,
  actions,
  modules: {
    wp,
    wc,
    pad,
    auth,
  },
});
