<template>
  <div class="account-menu">
    <Flex
      column-gap="xs"
      tag="nav"
      nowrap
      class="account-menu__items"
    >
      <Cta
        :theme="
          $route.name === 'EditAccount'
            ? 'button--alt'
            : 'button--alt--disabled'
        "
        :data="{
          title: $labels.account_details,
          url: { name: 'EditAccount' },
        }"
      />
      <Cta
        :theme="
          $route.name === 'Orders' ? 'button--alt' : 'button--alt--disabled'
        "
        :data="{
          title: $labels.orders,
          url: { name: 'Orders' },
        }"
      />
      <Cta
        v-if="areSubscriptionsActive"
        :theme="active ? 'button--alt' : 'button--alt--disabled'"
        :data="{
          title: $labels.subscriptions,
          url: { name: 'Subscriptions' },
        }"
      />
      <Cta
        theme="button"
        :data="{
          title: $labels.logout,
          url: { name: 'Account' },
          fn: logout,
        }"
      />
    </Flex>
  </div>
</template>

<script>
import Cta from '@/components/typo/cta';
// eslint-disable-next-line no-undef
const { woocommerce_subscriptions } = __VUE_WORDPRESS__.state.wcSettings;

export default {
  name: 'MenuUser',
  components: {
    Cta,
  },
  computed: {
    areSubscriptionsActive() {
      return woocommerce_subscriptions;
    },
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
  .account-menu {
    width: calc(100% + var(--spacer-wrapper) * 2);
    margin-left: 50%;
    transform: translateX(-50%);
    overflow-x: scroll;
    display: flex;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
    &:before,
    &:after {
      content: "";
      flex-grow: 1;
    }

    &__items {
      padding: var(--spacer-xxxs) var(--spacer-wrapper);
    }
  }
</style>
