import Vue from 'vue';
// import Cookies from 'js-cookie';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import VTooltip from 'v-tooltip';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';
import VCalendar from 'v-calendar';
import sessionHandler from '@/assets/js/sessionHandler';

// import { refreshNonce } from '@/api/auth';
import { checkFlexGap } from '@/assets/js/utils';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/js/eventBus';
import '@/assets/js/marketing';
import '@/assets/js/mq';
import '@/assets/js/layout';

sync(store, router);
Vue.config.productionTip = false;

Vue.use(InlineSvgPlugin);

Vue.use(VTooltip);
Vue.use(Vue2TouchEvents);

checkFlexGap();

// eslint-disable-next-line no-undef
const { options } = __VUE_WORDPRESS__.state;

if (
  options
  && options.extra
  && options.cookies
  && options.extra.ga_id
  && options.cookies.id
  && process.env.NODE_ENV !== 'development'
) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: options.extra.ga_id,
        params: {
          anonymize_ip: true,
        },
      },
      disableScriptLoad: true,
      bootstrap: true,
    },
    router,
  );
}

sessionHandler.init({
  onInitCompleted: () => {
    store.dispatch('initActions');

    // setInterval(async () => {
    //   // console.log('Refreshing nonce');
    //   await refreshNonce();
    // }, 1000 * 60 * 60 * 24);
  },
});

Vue.use(VCalendar);

Vue.directive('visible', (el, binding) => {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

// Vue.directive('click-outside', {
//   bind (el, binding) {
//     el.style.position = 'fixed'
//     var s = (binding.arg == 'left' ? 'left' : 'top')
//     el.style[s] = binding.value + 'px'
//   }
// });

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

router.onReady(() => app.$mount('#app'));
