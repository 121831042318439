<template>
  <Section
    class="not-found"
    :layout="{
      section_spacer: 'l',
      section_wrapper: 'xl',
    }"
  >
    <figure>
      <img :src="require('@/assets/svg/illustrations/404.svg')">
    </figure>
    <h1 class="typo--l">
      Pagina non trovata
    </h1>
    <Cta
      theme="button"
      :data="{
        title: $labels.go_back,
        fn: () => {
          $router.go(-2);
        },
      }"
    />
  </Section>
</template>

<script>
import Section from '@/components/section';
import Cta from '@/components/typo/cta';

export default {
  name: 'NotFound',
  components: {
    Section,
    Cta,
  },
};
</script>

<style lang="scss">
  .not-found {
    .wrapper {
      display: grid;
      justify-items: center;
      align-content: center;
      row-gap: var(--spacer-m);
    }

    img {
      max-width: 300px;
      width: 100%;
    }
  }
</style>
