var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data[_vm.data.type])?_c(_vm.components[_vm.data.type],{tag:"component",attrs:{"data":_vm.data.type === 'gallery'
      ? {
        items: _vm.data.gallery,
        lazyload: _vm.data.lazyload,
        ratio: _vm.data.ratio,
        object_fit: _vm.data.object_fit ? _vm.data.object_fit : 'contain',
      }
      : Object.assign({}, _vm.data[_vm.data.type],
        {lazyload: _vm.data.lazyload,
        ratio: _vm.data.ratio,
        object_fit: _vm.data.object_fit ? 'cover' : 'contain'})}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }