<template>
  <Grid
    col="12"
    row-gap="s"
  >
    <div
      v-for="(cartItem, index) in productCartSorted"
      :key="index"
      :data-key="cartItem.key"
      :data-quantity="cartItem.quantity"
      :class="{'cart__item-wrapper--item-data' : cartItem.custom_item_data}"
    >
      <Flex
        class="cart__item"
        :nowrap="true"
        column-gap="xs"
        row-gap="xs"
        :dir="$mq.isMobileS ? 'column' : 'row'"
      >
        <div
          class="wc-product-image ratio--11"
          :style="{
            backgroundImage: `url(${cartItem.product_image})`,
          }"
        />
        <Flex
          dir="column"
          justify="space-between"
          row-gap="xs"
        >
          <div>
            <router-link
              class="typo--s--700"
              :to="{
                name: 'SingleProduct',
                params: {
                  slug: cartItem.slug,
                },
              }"
            >
              {{ cartItem.product_name }}
              <Spacer
                v-if="cartItem.custom_item_data"
                top="xxxs"
              >
                <div
                  v-for="(itemData, j) in cartItem.custom_item_data"
                  :key="j"
                  class="typo--xs"
                >
                  <div v-if="j === 'items'">
                    <div
                      v-for="item in itemData"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                  <div v-else>
                    <span class="t-capitalize">{{ j }}:</span>
                    {{ itemData }}
                  </div>
                </div>
              </Spacer>
            </router-link>
            <Spacer top="xxxs">
              <div
                v-if="cartItem.is_deposit"
                v-html="cartItem.deposit_item_subtotal"
              />
              <div
                v-else
                v-html="getLineTotal(cartItem)"
              />
            </Spacer>
          </div>
          <Flex
            v-if="theme !== 'checkout'"
            align="center"
            column-gap="xxxs"
          >
            <Number
              v-if="!cartItem.custom_item_data"
              :key="cartLoading"
              :data="{
                id: `number`,
                fn: numberChanged,
                itemKey: cartItem.key,
                readonly: cartLoading
              }"
              :value="cartItem.quantity"
            />
            <Icon
              name="close"
              size="xs"
              theme="alt"
              :fn="() => removeCartItem(cartItem)"
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';

import orderBy from 'lodash.orderby';
import { parseCurrency, unparseCurrency } from '@/assets/js/utils-wc';

import Number from '@/components/form/fields/number';
import Icon from '@/components/ui/icon';
// import Figure from '@/components/media/figure';

export default {
  name: 'CartList',
  components: {
    Number,
    Icon,
    // Figure,
  },
  props: {
    theme: {
      type: String,
      default: 'complete',
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(['cart', 'cartLoading', 'showCart', 'wcSettings']),
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      return this.parseCurrency(
        this.unparseCurrency(this.cart.totals.subtotal)
          + this.unparseCurrency(this.cart.totals.subtotal_tax),
      );
    },
    generalSpacerX() {
      if (this.theme === 'checkout') {
        return false;
      }
      return this.theme === 'complete' ? 'l' : 'm';
    },
    generalSpacerY() {
      if (this.theme === 'checkout') {
        return 's';
      }
      return false;
    },
    productCartSorted() {
      const cartPojo = JSON.parse(JSON.stringify(this.cart));
      const itemsSorted = orderBy(cartPojo.items, ['custom_item_data'], ['desc']);
      return itemsSorted;
    },
  },
  watch: {
    // cart: {
    //   deep: true,
    //   handler(newVal) {
    //     const newValPojo = JSON.parse(JSON.stringify(newVal));
    //     const itemsSorted = orderBy(newValPojo.items, ['price']);
    //     console.log(itemsSorted);
    //     newVal.items = itemsSorted;
    //     return newVal;
    //   },
    // },
  },
  methods: {
    updateCartItem(key, quantity) {
      const item = this.cart.items.find((cartItem) => cartItem.key === key);
      const prevQuantity = this.$el.querySelector(`[data-key="${key}"]`) ? this.$el.querySelector(`[data-key="${key}"]`).dataset.quantity : 0;
      this.$store.dispatch('updateCartItem', Object.assign(item, { quantity, quantityDelta: quantity - prevQuantity }));
    },
    numberChanged(val) {
      if (val[0]) {
        this.updateCartItem(val[0], val[1]);
      } else {
        // $event
        const { target } = val;
        this.updateCartItem(target.dataset.key, target.value);
      }
    },
    removeCartItem(item) {
      const { key } = item;
      const prevQuantity = this.$el.querySelector(`[data-key="${key}"]`) ? this.$el.querySelector(`[data-key="${key}"]`).dataset.quantity : 0;
      this.$store.dispatch('removeCartItem', Object.assign(item, { quantityDelta: prevQuantity }));
    },
    getLineTotal(cartItem) {
      if (this.taxDisplayCart === 'excl') {
        return this.parseCurrency(cartItem.line_subtotal);
      }
      return this.parseCurrency(
        cartItem.line_subtotal + cartItem.line_subtotal_tax,
      );
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    // getProductProp(id, element) {
    //   const product = this.products.find((p) => p.id === id);
    //   if (product) {
    //     if (element === 'image' && product.gds_featured_image) {
    //       return product.gds_featured_image.sizes.woocommerce_thumbnail;
    //     }
    //     return product[element];
    //   }
    //   return null;
    // },
    getCartItemSpacerBottom(index) {
      if (this.theme === 'complete') {
        return 'l';
      }
      return index === this.cart.items.length - 1 ? false : 'm';
    },
    getCartItemSpacerTop(index) {
      if (this.theme === 'side' && index === 0) {
        return 'm';
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.wc-product-image {
  min-width: 150px;
  border: var(--line) solid var(--grey-2);
  border-radius: var(--border-radius);
  @include background-cover;
}
</style>
