<template>
  <Grid
    col="12"
    row-gap="m"
  >
    <Form
      id="login"
      theme="login--small"
    />
    <Social class="block-theme--default" />
    <Flex justify="space-between">
      <Cta
        :data="{
          title: $labels.lost_password,
          fn: toggleRecoverPassword,
        }"
      />
      <Cta
        :data="{
          title: $labels.register,
          url: {name: 'Register'},
        }"
      />
    </Flex>
    <Form
      v-if="showRecoverPassword"
      id="recovery-password"
    />
  </Grid>
</template>

<script>
import Form from '@/components/form';
import Cta from '@/components/typo/cta';
import Social from './login-social';

export default {
  name: 'Login',
  components: {
    Form,
    Social,
    Cta,
  },
  data() {
    return {
      showRecoverPassword: false,
    };
  },
  methods: {
    toggleRecoverPassword() {
      this.showRecoverPassword = !this.showRecoverPassword;
    },
  },
};
</script>

<style></style>
