var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header"},[(!_vm.firstLoading)?_c('Wrapper',{staticClass:"main-navigation",attrs:{"size":"xl","boxed":""}},[_c('div',{staticClass:"checkout__header"},[(!_vm.$mq.isMobileM)?_c('Cta',{attrs:{"data":{
            title: _vm.$labels.cart_button,
            beforeSlot: true,
            size: 's--700',
            url: { name: 'Cart' },
          }}},[_c('Icon',{attrs:{"size":"xs","name":"arrow-left"}})],1):_c('Icon',{attrs:{"size":"xs","name":"arrow-left","to":{ name: 'Cart' }}}),_c('h2',{staticClass:"typo--l"},[_vm._v(" "+_vm._s(_vm.$labels.checkout)+" ")])],1)]):_vm._e()],1),_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('Grid',{attrs:{"align":"start","column-gap":"xs"}},[_c('Column',{staticClass:"checkout__scrollable-column",attrs:{"size":{ default: '12', l: '7' }}},[_c('Grid',{staticClass:"checkout__left",attrs:{"col":"12","row-gap":"xs"}},[_c('AccordionWrapper',{staticClass:"form-theme--default"},[_c('AccordionItem',[_c('template',{slot:"accordion-trigger"},[_c('div',[(_vm.user)?_c('h4',[_vm._v(" "+_vm._s(_vm.$labels.welcome_back)+" "+_vm._s(_vm.userNiceName)+". ")]):_c('h4',[_vm._v(" "+_vm._s(_vm.$labels.do_you_have_account)+" ")])])]),_c('template',{slot:"accordion-content"},[(_vm.user)?_c('div',[_c('Spacer',[_vm._v(" "+_vm._s(_vm.$labels.logged_in_with_mail)+" "),_c('a',{attrs:{"href":'mailto:' + _vm.user.user_email}},[_vm._v(_vm._s(_vm.user.user_email))]),_vm._v(". ")])],1):_c('div',[_c('Form',{attrs:{"id":"login","theme":"plain"}}),_c('LoginSocial'),_c('Spacer',{attrs:{"use-margin":"","top":"l"}},[_c('Cta',{attrs:{"data":{
                        title: _vm.$labels.lost_password,
                        fn: _vm.toggleRecoverPassword,
                      }}})],1),(_vm.showRecoverPassword)?_c('Spacer',{attrs:{"use-margin":"","top":"l"}},[_c('Form',{attrs:{"id":"recovery-password","theme":"plain"}})],1):_vm._e()],1)])],2)],1),_c('Form',{ref:"form",attrs:{"id":"checkout","theme":"multi"}})],1)],1),_c('Column',{staticClass:"checkout__scrollable-column",attrs:{"size":{ default: '12', l: '5' }}},[_c('Grid',{attrs:{"col":"12","row-gap":"xs"}},[_c('CheckoutCalendar'),_c('CheckoutOrder')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }