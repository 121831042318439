<template>
  <Grid
    grid="1"
    row-gap="s"
  >
    <Title :data="{ value: $labels.related_products }" />
    <Grid
      column-gap="xs"
      row-gap="xl"
      :col="{ default: 12, m: 6, l: 3 }"
    >
      <ThumbProduct
        v-for="(item, index) in products"
        :key="index"
        :data="item"
      />
    </Grid>
  </Grid>
</template>

<script>
import ThumbProduct from '@/components/thumb/product';
import Title from '@/components/typo/title';

export default {
  components: {
    ThumbProduct,
    Title,
  },
  props: {
    relatableProduct: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      request: {
        type: 'product',
        params: {
          category: this.relatableProduct.categories[0].id,
          per_page: 4,
          lang: this.$store.state.lang,
        },
      },
      products: [],
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      this.products = await this.$store.dispatch('getItems', this.request);
    },
  },
};
</script>

<style></style>
