<template>
  <Grid
    col="12"
    row-gap="s"
  >
    <slot name="title" />
    <div class="fields">
      <slot name="fields" />
    </div>
  </Grid>
</template>

<script>
export default {
  name: 'DefaultGroup',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
