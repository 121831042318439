<template>
  <div
    v-visible="errors.length"
    :class="[
      'input-errors',
      backgroundColor !== '' ? `input-errors--${color}` : null,
    ]"
  >
    {{ errors[0] || $labels.error_placeholder }}
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },

  computed: {
    color() {
      const invertingColors = ['red', 'green', 'blue'];
      const inverted = invertingColors.includes(this.backgroundColor);
      if (inverted) {
        return 'white';
      }
      return 'black';
    },
  },
};
</script>

<style lang="scss">
  .input-errors {
    color: var(--red);
    &--white {
      color: var(--white);
    }
    &--black {
      color: var(--black);
    }
  }
</style>
