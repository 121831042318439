<template>
  <div class="cover">
    <div class="cover__content">
      <Title
        v-if="data.title && data.title !== ''"
        :data="{
          value: data.title,
          tag: 'h1',
          size: 'xl',
        }"
      />
      <Spacer
        class="cover__image"
        y="m"
      >
        <Figure
          v-if="data.figure.default"
          :data="{
            ...data.figure,
            lazyload: data.lazyload ? data.lazyload : false,
            ratio: data.ratio ? data.ratio : false,
            justify: 'center',
          }"
        />
      </Spacer>
      <Spacer
        tag="p"
        :top="{ m: 'xs' }"
      >
        {{ data.text }}
      </Spacer>
      <Spacer
        v-if="data.cta"
        y="cover"
      >
        <Cta
          :data="data.cta"
          :section-background-color="data.section_background_color"
          theme="button"
        />
      </Spacer>
    </div>
    <Figure
      v-if="data.figure.default"
      class="cover__image--background"
      :data="{
        ...data.figure,
        lazyload: data.lazyload,
        ratio: data.ratio,
        align: data.align,
        justify: 'end',
      }"
    />
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Figure from '@/components/media/figure';

export default {
  name: 'Cover',
  components: {
    Title,
    Cta,
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .cover {
    display: grid;

    @include mq(l) {
      grid-auto-rows: minmax(0, 500px);

    }

    &__content {
      grid-area: 1 / 1;
      z-index: 1;
    }

    &__image {
      @include mq(l) {
        display: none;
      }
      img {
        max-height: 25vh;
      }
      @include mq(m) {
        img {
          max-height: none;
        }
      }

      &--background {
        display: none;

        * {
          height: 100%;
        }

        @include mq(l) {
          display: initial;
          grid-area: 1 / 1;
        }
      }
    }
  }
  .main--category {
    .cover {
        @include mq(l) {
        grid-auto-rows: minmax(0, 360px);
        @media only screen and (min-height: 800px) {
          grid-auto-rows: minmax(0, 500px);
        }
      }
    }
  }
</style>
