<template>
  <Grid
    col="12"
    row-gap="m"
  >
    <div class="order-items">
      <Flex
        v-for="(orderItem, index) in order.items"
        :key="index"
        justify="space-between"
      >
        <Flex
          column-gap="xs"
          row-gap="xxs"
        >
          <div
            class="wc-product-image ratio--11"
            :style="{
              backgroundImage: `url(${
                orderItem.product.gds_featured_image
                  ? orderItem.product.gds_featured_image.sizes.medium
                  : ''
              })`,
            }"
          />
          <div>
            <del v-if="orderItem.qty_refunded_for_item < 0">{{
              orderItem.quantity - orderItem.qty_refunded_for_item
            }}</del>
            {{ orderItem.quantity }} x
            <router-link :to="orderItem.product.permalink">
              <h4 class="order-items__item__name">
                {{ orderItem.item_name }}
              </h4>
              <Spacer
                v-if="
                  orderItem.item_metas &&
                    !orderItem.item_metas.some((e) => e.key === 'items')
                "
                top="xxxs"
              >
                <div
                  v-for="(itemMeta, j) in orderItem.item_metas"
                  :key="j"
                  class="typo--xs"
                >
                  <div v-if="itemMeta.key.indexOf('pa_') !== 0">
                    <span class="t-capitalize">{{ itemMeta.key }}</span>:
                    {{ itemMeta.value }}
                  </div>
                </div>
              </Spacer>
              <Spacer
                v-else
                top="xxxs"
              >
                <h6 class="typo--xs">
                  {{
                    orderItem.item_metas.find((e) => e.key === "numbers").value
                  }}
                </h6>
                <h6
                  v-for="item in orderItem.item_metas.find(
                    (e) => e.key === 'items'
                  ).value"
                  :key="item.id"
                  class="typo--xs"
                >
                  {{ item.name }}
                </h6>
              </Spacer>
            </router-link>
          </div>
        </Flex>
        <div
          class="t-right"
          v-html="getTaxedValueLine(orderItem)"
        />
      </Flex>
    </div>
    <Separator />
    <Grid
      v-if="order.fees && order.fees[0]"
      col="12"
    >
      <Flex
        v-for="(fee, index) in order.fees"
        :key="index"
        justify="space-between"
      >
        <Flex column-gap="m">
          <div v-html="fee.name" />
        </Flex>
        <div
          class="t-right"
          v-html="getTaxedValueLine(fee)"
        />
      </Flex>
      <div class="separator--row" />
    </Grid>
    <Grid
      col="12"
      row-gap="xxs"
    >
      <Flex justify="space-between">
        <h4 class="typo--s--700">
          {{ $labels.subtotal }}
        </h4>
        <div
          class="t-right"
          v-html="subtotal"
        />
      </Flex>
      <Flex justify="space-between">
        <h4 class="typo--s--700">
          {{ $labels.shipping }}
        </h4>
        <div
          class="t-right"
          v-html="shipping"
        />
      </Flex>
      <Flex
        v-if="order.discount_total > 0"
        justify="space-between"
      >
        <div>{{ $labels.discount }}</div>
        <div>-<span v-html="discount" /></div>
      </Flex>
      <Flex
        v-if="taxDisplayCart === 'excl'"
        justify="space-between"
      >
        <h4 class="typo--s--700">
          {{ $labels.vat }}
        </h4>
        <div v-html="vat" />
      </Flex>
      <Flex
        v-if="order.total_refunded > 0"
        justify="space-between"
      >
        <div>{{ $labels.refunded }}</div>
        <div>-<span v-html="parseCurrency(order.total_refunded)" /></div>
      </Flex>
      <Flex
        v-if="paymentGateway"
        justify="space-between"
      >
        <div>
          <h4 class="typo--s--700">
            {{ $labels.payment_method }}
          </h4>
          <BanksDetails v-if="order.payment_method === 'bacs'" />
        </div>
        <div>
          {{ paymentGateway }}
        </div>
      </Flex>
    </Grid>
    <Separator />
    <Flex justify="space-between">
      <h4 class="t-uppercase">
        {{ $labels.total }}
      </h4>
      <h4 v-html="total" />
    </Flex>
    <Separator />

    <Flex
      v-if="order.extra && order.extra.selected_date"
      justify="space-between"
    >
      <div class="typo--s--500">
        {{ $labels.selected_date }}
      </div>
      <div>{{ order.extra.selected_date }}</div>
    </Flex>
    <Separator v-if="order.extra && order.extra.selected_date" />

    <Address :data="order" />

    <Separator v-if="order.customer_note" />
    <Spacer v-if="order.customer_note">
      <div class="typo--s--700">
        {{ $labels.additional_information }}
      </div>
      <Spacer y="xs">
        {{ order.customer_note }}
      </Spacer>
    </Spacer>
    <Spacer
      v-if="order.notes && order.notes[0]"
      :top="order.customer_note ? 'm' : null"
    >
      <Spacer
        y="xs"
        class="typo--s--700"
      >
        {{ $labels.order_updates }}
      </Spacer>
      <div
        v-for="note in order.notes"
        :key="note.id"
      >
        <Spacer y="xs">
          <div>{{ parseDateTime(note.date_created.date) }}</div>
          <div>{{ note.content }}</div>
        </Spacer>
      </div>
    </Spacer>
    <Spacer
      v-if="orderActions && orderActions[0] && theme === 'list'"
      top="l"
    >
      <Flex
        justify="center"
        align="center"
        column-gap="m"
      >
        <div
          v-for="(orderAction, i) in orderActions"
          :key="i"
        >
          <Cta
            v-if="orderAction === 'pay'"
            theme="button"
            :data="{
              title: $labels.go_to_payment,
              url: {
                name: 'PayOrder',
                params: {
                  orderId: order.id,
                },
                query: {
                  pay_for_order: true,
                  key: order.key,
                },
              },
            }"
          />
          <Cta
            v-if="orderAction === 'cancel'"
            theme="warning"
            :data="{
              title: $labels.cancel_order,
              fn: openModal,
            }"
          />
        </div>
      </Flex>
    </Spacer>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  parseCurrency,
  unparseCurrency,
  parseDateTime,
  availableOrderActions,
  getOrderTotal,
} from '@/assets/js/utils-wc';

import Cta from '@/components/typo/cta';
import BanksDetails from '@/components/wc/banks-details.vue';
import Address from '@/components/wc/address.vue';
import Separator from '@/components/blocks/blocks-separator.vue';

export default {
  name: 'OrderDetails',
  components: {
    Cta,
    BanksDetails,
    Address,
    Separator,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(['wcSettings', 'paymentGateways', 'geoData']),
    total() {
      return getOrderTotal(this.order, this.wcSettings);
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.order) {
        let linesTotals = 0;
        let linesTaxs = 0;
        this.order.items.forEach((element) => {
          linesTotals += Number(element.subtotal);
          linesTaxs += Number(element.subtotal_tax);
        });
        this.order.fees.forEach((element) => {
          linesTotals += Number(element.subtotal);
          linesTaxs += Number(element.subtotal_tax);
        });
        if (this.taxDisplayCart === 'excl') {
          return `${this.parseCurrency(Number(linesTotals))}`;
        }
        return this.parseCurrency(Number(linesTotals) + Number(linesTaxs));
      }
      return null;
    },
    discount() {
      if (this.order) {
        if (this.taxDisplayCart === 'excl') {
          return this.parseCurrency(Number(this.order.discount_total));
        }
        return this.parseCurrency(
          Number(this.order.discount_total) + Number(this.order.discount_tax),
        );
      }
      return null;
    },
    shipping() {
      if (this.order) {
        if (this.taxDisplayCart === 'excl') {
          return this.parseCurrency(Number(this.order.shipping_total));
        }
        return this.parseCurrency(
          Number(this.order.shipping_total) + Number(this.order.shipping_tax),
        );
      }
      return null;
    },
    paymentGateway() {
      if (this.order && this.order.payment_method) {
        if (this.paymentGateways[this.order.payment_method]) {
          return this.paymentGateways[this.order.payment_method].title;
        }
        return (
          this.order.payment_method.charAt(0).toUpperCase()
            + this.order.payment_method.slice(1)
        );
      }
      return null;
    },
    vat() {
      return this.parseCurrency(Number(this.order.total_tax));
    },
    orderStatus() {
      return this.$store.state.wp.wcSettings.woocommerce_order_statuses[
        `wc-${this.order.status}`
      ];
    },
    orderActions() {
      return availableOrderActions[`wc-${this.order.status}`];
    },
  },
  mounted() {},
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings, this.order.currency);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    getTaxedValueLine(row) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(Number(row.subtotal) + Number(row.subtotal_tax));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(Number(row.subtotal))}`;
      }
      return `${this.parseCurrency(Number(row.subtotal))} + ${this.parseCurrency(
        Number(row.subtotal_tax),
      )} VAT`;
    },
    parseDateTime(date) {
      return parseDateTime(date, this.wcSettings);
    },
    async cancelOrder() {
      await this.$store.dispatch('cancelOrder', {
        order_id: this.order.id,
        key: this.order.key,
      });
      await this.$store.dispatch('getOrders');
    },
    openModal() {
      this.$bus.$emit('modal', {
        type: 'dialog',
        id: 'cancelOrder',
        content: {
          richtext: this.$labels.cancel_order_dialog,
          cta: {
            title: this.$labels.cancel_order,
          },
        },
        fn: this.cancelOrder,
      });
    },
  },
};
</script>

<style lang="scss">
  .order-items {
    display: grid;
    row-gap: var(--spacer-s);

    &__item {
      &__name {
        display: inline;
      }
    }
  }
</style>
