<template>
  <transition name="mobile-menu">
    <Wrapper
      v-show="visibility"
      size="xl"
      boxed
      class="mobile-menu"
    >
      <Spacer y="m">
        <Flex
          dir="column"
          row-gap="s"
          nowrap
        >
          <div
            v-for="mainItem in navigationMenu"
            :key="mainItem.id"
            class="mobile-menu__main-item"
          >
            <Cta
              :class="[
                mainItem.url === '/palloncini/' ? 'typo--m--700' : 'typo--m',
              ]"
              :data="{
                url: mainItem.url,
                title: mainItem.content,
              }"
            />
            <div
              v-if="mainItem.url === '/palloncini/'"
              class="mobile-menu__highlights"
            >
              <router-link
                v-for="section in subMenuHighlights"
                :key="section.id"
                :to="`/palloncini/categoria/${section.category.slug}`"
              >
                <div class="sub-menu__section sub-menu__section--highlight">
                  <h4 class="cta__title">
                    {{ section.title }}
                  </h4>
                  <Figure
                    :data="{
                      ...section.image,
                      ratio: true,
                      object_fit: 'contain'
                    }"
                  />
                </div>
              </router-link>
            </div>
            <div
              v-for="section in mainItem.children"
              :key="section.id"
              class="sub-menu__section"
            >
              <h4 class="cta__title">
                {{ section.content }}
              </h4>
              <Flex
                dir="column"
                row-gap="xxxs"
                tag="nav"
              >
                <Cta
                  v-for="item in section.children"
                  :key="item.id"
                  :data="{
                    url: item.url,
                    title: item.content,
                  }"
                />
              </Flex>
            </div>
          </div>
        </Flex>
      </Spacer>
    </Wrapper>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
// import Icon from '@/components/ui/icon';
import Figure from '@/components/media/figure';

export default {
  name: 'MobileMenu',
  components: {
    Cta,
    // Icon,
    Figure,
  },
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clicked-outside'],
  computed: {
    ...mapGetters(['menu']),
    navigationMenu() {
      const menu = this.menu('Header').items;

      return menu;
    },
    subMenuHighlights() {
      const menu = this.menu('Header');
      const highlights = [menu.acf.section_1, menu.acf.section_2];

      return highlights;
    },
  },
  watch: {
    visibility(value) {
      if (value) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
  },
  // methods: {
  //   clickOutside(e) {
  //     if (e.target.className !== ('cta__title' || 'svg')) {
  //       this.$emit('clicked-outside');
  //     }
  //   },
  // },
};
</script>

<style lang="scss">
  .mobile-menu {
    box-shadow: 0 0 10px var(--shadow);
    position: fixed;
    background-color: var(--white);
    overflow-y: scroll;
    height: calc(var(--vh) * 100 - var(--main-navigation-height--mobile));

    &__main-item {
      display: grid;
      row-gap: var(--spacer-m);
    }

    a {
      display: block;
      width: max-content;
    }

    &__highlights {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--spacer-xs);

      a {
        display: block;
        width: 100%;
      }
    }
  }

  .mobile-menu-enter-active,
  .mobile-menu-leave-active {
    transition: all 0.2s ease;
  }
  .mobile-menu-enter,
  .mobile-menu-leave-to {
    opacity: 0;
  }
</style>
