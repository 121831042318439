var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('Grid',{attrs:{"grid":"1","row-gap":"xl"}},[(_vm.data.blockTemplate === 'our-service')?_c('Grid',{attrs:{"grid":"1","row-gap":"xxxs"}},[(_vm.data.title && _vm.data.title !== '')?_c('Title',{attrs:{"data":{
          value: _vm.data.title,
          tag: _vm.data.tag_title,
          size: 'm--700',
        }}}):_vm._e(),(_vm.data.rich_text && _vm.data.rich_text !== '')?_c('RichText',{attrs:{"data":{
          value: _vm.data.rich_text,
          size: _vm.data.size_rich_text,
        }}}):_vm._e()],1):(_vm.data.blockTemplate === 'newsletter--footer')?_c('Grid',{attrs:{"grid":"1","row-gap":"xxxs"}},[(_vm.data.label && _vm.data.label !== '')?_c('Label',{attrs:{"data":{
          value: _vm.data.label,
          tag: _vm.data.tag_label,
          size: _vm.data.size_label,
        }}}):_vm._e(),(_vm.data.rich_text && _vm.data.rich_text !== '')?_c('RichText',{attrs:{"data":{
          value: _vm.data.rich_text,
          size: _vm.data.size_rich_text,
        }}}):_vm._e()],1):_c('Grid',{attrs:{"grid":"1","row-gap":"xs"}},[(_vm.data.label && _vm.data.label !== '')?_c('Label',{attrs:{"data":{
          value: _vm.data.label,
          tag: _vm.data.tag_label,
          size: _vm.data.size_label,
        }}}):_vm._e(),(_vm.data.title && _vm.data.title !== '')?_c('Title',{attrs:{"data":{
          value: _vm.data.title,
          tag: _vm.data.tag_title,
          size: _vm.data.size_title,
        }}}):_vm._e(),(_vm.data.rich_text && _vm.data.rich_text !== '')?_c('RichText',{attrs:{"template":_vm.data.block_template,"data":{
          value: _vm.data.rich_text,
          size: _vm.data.size_rich_text,
        }}}):_vm._e()],1),_vm._l((_vm.data.ctas),function(item,index){return _c('Cta',{key:index,attrs:{"data":item.cta,"theme":_vm.data.cta_layout}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }