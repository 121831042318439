<template>
  <Grid
    v-if="subscriptions && subscriptions[0]"
    col="12"
  >
    <Subscription
      v-for="subscription in subscriptions"
      :key="subscription.subscription_id"
      :subscription="subscription"
      theme="list"
    />
  </Grid>

  <Grid
    v-else-if="!loading"
    col="12"
    class="block-theme--default"
  >
    {{ $labels.no_subscription }}<br>
    <Flex justify="center">
      <Cta
        :data="{
          title: `${$labels.go_to} ${$labels.shop}`,
          url: '/shop',
        }"
        theme="button"
      />
    </Flex>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import Subscription from '@/components/wc/subscription';
import Cta from '@/components/typo/cta';

export default {
  name: 'Subscriptions',
  components: {
    Subscription,
    Cta,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['user', 'subscriptions']),
  },
  async mounted() {
    await this.$store.dispatch('getSubscriptions');
    this.loading = false;
  },
};
</script>
