<template>
  <div>
    <transition name="fade">
      <div
        v-if="showCartCalc"
        class="overlay"
        @click="$store.commit('CLOSE_CART')"
      />
    </transition>
    <div class="cart">
      <transition name="slide">
        <div v-if="showCartCalc">
          <Spacer
            all="xs"
            class="cart__wrapper"
          >
            <div class="cart__header">
              <Flex
                justify="space-between"
                align="center"
              >
                <div class="typo--s--700">
                  {{ $labels.cart_products_title }}
                </div>

                <!-- <Icon
                  name="close"
                  size="m"
                  :fn="() => $store.commit('CLOSE_CART')"
                /> -->
              </Flex>
            </div>
            <div class="cart__content">
              <Spacer
                v-if="isCartEmpty"
                y="m"
                class="cart__empty-state"
              >
                <p>
                  {{ $labels.your_cart_empty }}
                </p>
              </Spacer>
              <CartList theme="side" />
            </div>
            <Flex
              v-if="!isCartEmpty"
              class="typo--m--700"
              dir="column"
              row-gap="m"
            >
              <Separator />
              <Flex justify="space-between">
                <h4 class="t-uppercase">
                  {{ $labels.subtotal }}
                </h4>
                <div class="t-right">
                  <h4 v-html="subtotal" />
                  <!-- <small v-if="taxDisplayCart === 'excl'">{{ $labels.excluded_vat_extended }}</small> -->
                  <small v-if="taxDisplayCart === 'excl'">{{
                    $labels.excluded_vat
                  }}</small>
                </div>
              </Flex>
              <Flex justify="center">
                <Cta
                  theme="button"
                  :data="{
                    title: $labels.cart_button,
                    url: { name: 'Cart' },
                    size: 's',
                  }"
                />
              </Flex>
            </Flex>
          </Spacer>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash.isempty';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { parseCurrency, unparseCurrency } from '@/assets/js/utils-wc';

import Cta from '@/components/typo/cta';
// import Icon from '@/components/ui/icon';
import CartList from '@/components/wc/cart-list';
import Separator from '@/components/blocks/blocks-separator';

export default {
  name: 'Cart',
  components: {
    Cta,
    // Icon,
    CartList,
    Separator,
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(['cart', 'cartLoading', 'showCart', 'wcSettings']),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.taxDisplayCart === 'excl') {
        return this.parseCurrency(
          this.unparseCurrency(this.cart.totals.subtotal),
        );
      }
      return this.parseCurrency(
        this.unparseCurrency(this.cart.totals.subtotal)
            + this.unparseCurrency(this.cart.totals.subtotal_tax),
      );
    },
    showCartCalc() {
      if (this.$route.name === 'Cart') {
        return false;
      }
      if (this.showCart) {
        disableBodyScroll(document.querySelector('html'));
        return true;
      }
      clearAllBodyScrollLocks();
      return false;
    },
  },
  watch: {
    $route() {
      // to
      // to.name === 'Checkout' &&
      if (this.showCart) {
        this.$store.commit('CLOSE_CART');
        clearAllBodyScrollLocks();
      }
    },
  },
  async mounted() {
    this.$store.dispatch('getCart');
  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s ease;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.25s ease;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translateX(100%);
  }

  .cart {
    position: fixed;
    z-index: 50;
    top: 0;
    right: 0;
    width: 80%;
    max-width: 500px;

    &__header {
      padding-top: 110px;
      margin-bottom: var(--spacer-s);
    }

    &__content {
      overflow-y: scroll;
      scrollbar-width: none;
      padding-bottom: var(--spacer-s);

      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__wrapper {
      background: var(--white);
      box-shadow: 0 1px 5px var(--shadow);
      height: calc(var(--vh) * 100);
      position: relative;
      display: grid;
      grid-template-rows: min-content 1fr min-content;
    }
  }

  .cart__item-right {
    height: 100%;
  }

  .cart__remove-item {
    cursor: pointer;
    transition: all 0.25s $ease-custom;
    display: block;
    line-height: $unit * 1.5;
  }

  .no-touchevents {
    .cart__remove-item {
      opacity: 0;
      pointer-events: none;
    }
  }

  .cart__item {
    &:hover {
      .cart__remove-item {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  // .cart__item-wrapper--item-data {
  //   border-top: 1px solid var(--grey-xl);
  //   padding-top: var(--spacer-s);
  // }

  // .header-placeholder {
  //   visibility: hidden;
  // }
</style>
