import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      settings: {
        sendLabel: 'cap_button',
      },
      groups: {
        'cap-checker': {
          visible: true,
          fields: {
            cap: {
              type: 'text',
              model: '',
              placeholder: 'cap_placeholder',
              label: 'cap',
              rules: 'digits:5',
              errors: false,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['cap']),
  },
  methods: {
    init() {
      setTimeout(() => {
        if (this.cap && this.groups['cap-checker']) {
          this.groups['cap-checker'].fields.cap.model = this.cap;
        }
      }, 200);
    },
    callback() {
      this.$store.dispatch(
        'checkCap', {
          cap: this.groups['cap-checker'].fields.cap.model,
        },
      );
    },
  },
};
