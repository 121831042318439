<template>
  <Spacer
    y="l"
    :use-margin="true"
  >
    <Wrapper
      size="xl"
      boxed
    >
      <Grid
        col="12"
        row-gap="l"
      >
        <div class="t-center typo--l">
          {{ $labels.change_payment_method }}
        </div>
        <div v-if="subscription">
          <Grid
            col="6"
            align="baseline"
          >
            <Grid
              col="12"
              class="block-theme--default"
            >
              <SubscriptionDetails
                :subscription="subscription"
                theme="simple"
              />
            </Grid>

            <Grid
              col="12"
              class="block-theme--default"
            >
              {{ $labels.payment_methods }}
              <Form
                id="changePaymentMethod"
                theme="plain"
                :data="{
                  subscription
                }"
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Wrapper>
  </Spacer>
</template>

<script>
import isEmpty from 'lodash.isempty';

import { mapGetters } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionDetails from '@/components/wc/subscription-details.vue';
import Form from '@/components/form';
import {
  stripeMountCard,
} from '@/assets/js/utils-wc';

export default {
  name: 'PayOrder',
  components: {
    SubscriptionDetails,
    Form,
  },
  data() {
    return {
      subscription: null,
    };
  },
  computed: {
    ...mapGetters(['paymentGateways', 'paymentMetas', 'user']),
  },
  created() {
    if (isEmpty(this.user)) {
      this.$router.push({ name: 'Account' });
    }
  },
  async mounted() {
    const subscription = await this.$store.dispatch('getSubscription', {
      key: this.$route.query.key,
      subscription_id: this.$route.params.orderId,
    });
    this.subscription = subscription;

    if (this.paymentGateways.stripe && this.paymentGateways.stripe.publishable_key) {
      const stripe = await loadStripe(this.paymentGateways.stripe.publishable_key, {
        locale: 'it', // TODO: get from WPML
      });
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      stripeMountCard(this.$store, this.paymentMetas);
    }
  },
};
</script>
