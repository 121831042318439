<template>
  <footer v-show="showFooter">
    <Separator />
    <Spacer class="page-footer spacer--section--m">
      <Wrapper
        size="xl"
        boxed
      >
        <Grid
          v-show="!$route.meta.hideFooter"
          :column-gap="$mq.isMobileM ? '' : 'xs'"
          row-gap="l"
        >
          <Column
            v-for="(menu, menuIndex) in footerMenus"
            :key="menuIndex"
            :size="{ default: 12, m: 4, l: 2 }"
          >
            <Spacer
              tag="h6"
              class="typo--xs"
              bottom="xxxs"
              :use-margin="true"
            >
              {{ menu.items[0].content }}
            </Spacer>
            <Flex
              dir="column"
              row-gap="xxxs"
            >
              <Cta
                v-for="(item, index) in menu.items[0].children"
                :key="index"
                :data="{
                  url: item.url,
                  title: item.content,
                  target: item.target,
                }"
              />
            </Flex>
          </Column>
          <Column
            v-if="!$mq.isMobile"
            :size="{ default: 12, m: 4 }"
            :offset="{ l: 7 }"
          >
            <img
              class="logo--footer"
              :src="require('@/assets/logo.png')"
            >
          </Column>
          <!-- <Column
            :size="{ default: 12, l: 4 }"
            :offset="{ l: 7}"
          >
            <Newsletter
              :data="{
                label: newsletter.label,
                tag_label: 'h6',
                size_label: 'xs',
                rich_text: newsletter.rich_text,
              }"
            />
          </Column> -->
          <Column :size="{ default: 12, m: 8, l: 7 }">
            <Spacer
              bottom="xs"
              :top="{ default: 'l', l: 0 }"
            >
              <Title
                :data="{
                  value: footerInformation.business_name,
                  tag: 'h6',
                  size: 'xs',
                }"
              />
            </Spacer>
            <RichText
              :data="{ value: footerInformation.business_details, size: 'xs' }"
            />
          </Column>
          <Column
            :size="{ default: 12, m: 4 }"
            :offset="{ m: 8, l: 7 }"
            class="footer__social"
          >
            <Flex column-gap="xs">
              <Icon
                v-for="social in socials"
                :key="social.name"
                :name="social.name"
                :to="social.url"
                external
              />
            </Flex>
          </Column>
        </Grid>
      </Wrapper>
    </Spacer>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';
// import Newsletter from '@/components/blocks/newsletter';
import Separator from '@/components/blocks/blocks-separator';
import Icon from '@/components/ui/icon';

export default {
  name: 'Footer',
  components: {
    Title,
    RichText,
    Cta,
    // Newsletter,
    Icon,
    Separator,
  },
  computed: {
    ...mapGetters(['options', 'menu', 'currentPost']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      // if (!this.$route.meta.title && !this.currentPost) {
      //   return false;
      // }
      return true;
    },
    socials() {
      return this.options.socials;
    },
    footerInformation() {
      return this.options.footer_information;
    },
    footerMenus() {
      const menus = [
        this.menu('Footer 1'),
        this.menu('Footer 2'),
        this.menu('Footer 3'),
      ];
      return menus || null;
    },
    newsletter() {
      const { newsletter } = this.options;
      return newsletter;
    },
  },
};
</script>

<style lang="scss">
  .footer__social {
    align-self: end;
    position: absolute;
    top: 0;
    right: 0;

    @include mq(m) {
      position: initial;
    }
  }

  .logo--footer {
    width: 280px;
    max-width: 100%;
    height: auto;
  }
</style>
