var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cap-checker"},[_c('Grid',{attrs:{"col":"12","row-gap":_vm.data.template === 'mini' ? 'l' : 'xl'}},[_c('Grid',{attrs:{"col":"12","row-gap":"xs"}},[_c('Title',{attrs:{"data":{
          value: _vm.data.title,
          tag: 'h2',
          size: _vm.data.template === 'mini' ? 'l' : 'xl',
        }}}),(_vm.data.text && _vm.data.text !== '')?_c('RichText',{attrs:{"data":{
          value: _vm.data.text,
        }}}):_vm._e()],1),_c('Form',{attrs:{"id":"cap-checker","theme":"highlight--inline","background-color":"data.section_background_color"}})],1),(!_vm.capChecker)?_c('Spacer',{attrs:{"top":"s"}},[_c('Flex',{attrs:{"dir":"column","row-gap":"l"}},[_c('div',[_c('h3',{staticClass:"typo--l"},[_vm._v(" "+_vm._s(_vm.$labels.cap_message_1)+" ")]),_c('h2',{staticClass:"typo--xl"},[_vm._v(" "+_vm._s(_vm.$labels.cap_message_2({ date: _vm.date }))+" ")])]),_c('Cta',{attrs:{"theme":"button","data":{
          title: _vm.$labels.cap_discover_cta,
          url: '/palloncini',
        }}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }