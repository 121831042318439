<template>
  <div>
    <div v-if="$mq.isMobileM">
      <Spacer
        top="l"
        bottom="xl"
        :use-margin="true"
        class="t-center checkout-order__mobile-static"
      >
        <Grid col="12">
          <Flex justify="center">
            <Cta
              :data="{
                title: $labels.place_order,
                fn: placeOrder,
              }"
              theme="button"
            />
          </Flex>
          <Spacer top="s">
            <Checkbox
              v-model="terms.model"
              :data="terms"
              :model.sync="terms.model"
            >
              <label
                slot="label"
                for="terms"
              >{{ $labels[terms.label] }} <sup
                v-html="'*'"
              /></label>
            </Checkbox>
          </Spacer>
        </Grid>
      </Spacer>
      <transition name="fade">
        <div
          v-if="showMobileModal"
          class="overlay"
          @click="showMobileModal = !showMobileModal"
        />
      </transition>
      <div
        v-touch:swipe="swipeHandlerMobileModal"
        class="checkout-order__mobile-modal block--default"
        :class="{ 'checkout-order__mobile-modal--visible': showMobileModal }"
        row-gap="m"
      >
        <Wrapper
          size="xl"
          boxed
        >
          <div class="checkout-order__mobile-modal__content">
            <Spacer
              y="xs"
              @click.native="showMobileModal = !showMobileModal"
            >
              <Flex
                justify="space-between"
                align="center"
              >
                <div>
                  {{ $labels.show_order_summary }}
                </div>
                <transition name="fade">
                  <div v-if="!showMobileModal">
                    <span
                      v-html="parseCurrency(unparseCurrency(cart.totals.total))"
                    />
                  </div>
                </transition>
              </Flex>
            </Spacer>
            <CartList theme="checkout" />
            <div class="separator--row" />
            <CartTotals />
          </div>
        </Wrapper>
      </div>
    </div>
    <Grid
      v-else
      col="12"
      class="form-theme--default"
      row-gap="s"
    >
      <h4>
        {{ $labels.order_details }}
      </h4>
      <CartList theme="checkout" />
      <Separator />
      <Coupon />
      <Separator />
      <CartTotals />
      <Flex justify="center">
        <Cta
          :data="{
            title: $labels.place_order,
            fn: placeOrder,
          }"
          theme="button"
        />
      </Flex>
      <Checkbox
        v-model="terms.model"
        :data="terms"
        :model.sync="terms.model"
      >
        <label
          slot="label"
          for="terms"
        >{{ $labels[terms.label] }} <sup
          v-html="'*'"
        /></label>
      </Checkbox>
    </Grid>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {
  parseCurrency,
  unparseCurrency,
  exclVatLabel,
} from '@/assets/js/utils-wc';

import Checkbox from '@/components/form/fields/checkbox';
import CartTotals from '@/components/wc/cart-totals';
import CartList from '@/components/wc/cart-list';
import Cta from '@/components/typo/cta';
import Separator from '@/components/blocks/blocks-separator';
import Coupon from '@/components/wc/coupon';

export default {
  name: 'CheckoutOrder',
  components: {
    CartTotals,
    CartList,
    Checkbox,
    Cta,
    Separator,
    Coupon,
  },
  data() {
    return {
      acceptTerms: false,
      invalid: true,
      invalidFromTerms: true,
      invalidFromForm: true,
      // products: [],
      showMobileModal: false,
      terms: {
        id: 'terms',
        type: 'checkbox',
        checked: false,
        model: false,
        label: 'terms',
        fn: this.setAcceptTerms,
      },
    };
  },
  computed: {
    ...mapGetters(['cart', 'wcSettings']),
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
  },
  watch: {
    invalidFromTerms(val) {
      if (!val && !this.invalidFromForm) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    invalidFromForm(val) {
      if (!val && !this.invalidFromTerms) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    showMobileModal(value) {
      if (value) {
        disableBodyScroll(document.querySelector('html'));
      } else {
        clearAllBodyScrollLocks();
      }
    },
  },
  async mounted() {
    this.$bus.$on('validForm', this.formInvalid);
    // const request = {
    //   type: 'product',
    //   params: {
    //     // include: [this.cart.items.map((x) => x.product_id)],
    //     per_page: 100,
    //     offset: 0,
    //     lang: this.$store.state.lang,
    //   },
    // };
    // this.products = await this.$store.dispatch('getItems', request);
  },
  beforeDestroy() {
    this.$bus.$off('validForm', this.formInvalid);
  },
  methods: {
    getLineTotal(cartItem) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(
          this.unparseCurrency(cartItem.line_subtotal)
              + this.unparseCurrency(cartItem.line_subtotal_tax),
        );
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(
          this.unparseCurrency(cartItem.line_subtotal),
        )}  ${exclVatLabel(this.$labels.excluded_vat)}`;
      }
      return this.parseCurrency(
        this.unparseCurrency(cartItem.line_subtotal)
            + this.unparseCurrency(cartItem.line_subtotal_tax),
      );
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    placeOrder() {
      this.$bus.$emit('submitForm', 'checkout');
    },
    setAcceptTerms() {
      this.acceptTerms = !this.acceptTerms;
      this.invalidFromTerms = !this.acceptTerms;
    },
    formInvalid(data) {
      if (data && data.id === 'checkout') {
        this.invalidFromForm = data.invalid;
      }
    },
    swipeHandlerMobileModal(dir) {
      if (dir === 'top') {
        this.showMobileModal = true;
      }
      if (dir === 'bottom') {
        this.showMobileModal = false;
      }
    },
  },
};
</script>

<style lang="scss">
  .checkout-order__mobile-modal {
    position: fixed;
    bottom: 60px;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    transition: all 0.6s $ease-custom;
    z-index: 20;
    max-height: 80vh;
    overflow: auto;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding-bottom: var(--spacer-l);

    &--visible {
      transform: translateY(0);
      bottom: 0;
    }

    &__content {
      display: grid;
      row-gap: var(--spacer-s);
    }
  }
</style>
