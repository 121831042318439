var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Section',{staticClass:"not-found",attrs:{"layout":{
    section_spacer: 'l',
    section_wrapper: 'xl',
  }}},[_c('figure',[_c('img',{attrs:{"src":require('@/assets/svg/illustrations/404.svg')}})]),_c('h1',{staticClass:"typo--l"},[_vm._v(" Pagina non trovata ")]),_c('Cta',{attrs:{"theme":"button","data":{
      title: _vm.$labels.go_back,
      fn: function () {
        _vm.$router.go(-2);
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }