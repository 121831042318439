var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Spacer',{attrs:{"y":"xl","use-margin":true}},[(!_vm.firstLoading)?_c('Wrapper',{attrs:{"size":"xl","boxed":""}},[_c('div',{staticClass:"typo--l"},[_vm._v(" "+_vm._s(_vm.$labels.your_cart)+" ")]),(_vm.isCartEmpty && !_vm.cartLoading)?_c('Wrapper',{attrs:{"size":"s"}},[_c('div',{staticClass:"block-theme--default"},[_c('div',{staticClass:"t-center"},[_c('Spacer',{attrs:{"y":"m"}},[_vm._v(" "+_vm._s(_vm.$labels.your_cart_empty)+" ")])],1),_c('Flex',{attrs:{"justify":"center"}},[_c('Cta',{attrs:{"theme":"button","data":{
              title: _vm.$labels.go_to_shop,
              url: '/shop',
            }}})],1)],1)]):_c('Spacer',{attrs:{"top":"l"}},[_c('Grid',{attrs:{"col":{
          default: 12,
          l: 6,
        },"column-gap":"xs","row-gap":"xs"}},[_c('Column',{attrs:{"size":"1"}},[_c('Form',{staticClass:"cart-column--left",attrs:{"id":"order-card","theme":"order-card"}})],1),_c('Column',{attrs:{"size":"1"}},[_c('Grid',{staticClass:"form-theme--default",attrs:{"col":"12","row-gap":"m"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$labels.cart_products_title)+" ")]),_c('CartList',{attrs:{"theme":"complete"}}),_c('Separator'),_c('Coupon'),_c('Separator'),_c('CartTotals',{attrs:{"theme":"cart"}}),_c('Flex',{attrs:{"justify":"center"}},[_c('Cta',{attrs:{"theme":"button","data":{
                  title: _vm.$labels.go_to_checkout,
                  url: '/checkout',
                }}})],1),_c('h5',[_vm._v(" "+_vm._s(_vm.$labels.cart_disclaimer)+" ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }