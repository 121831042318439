<template>
  <svg
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
    class="mobile-color-selector"
  >
    <path
      :class="['bottom', selectedColor === 'rosa' ? 'active' : null]"
      d="m50 50-43.3 25a50 50 0 0 0 43.3 25 50 50 0 0 0 43.3-25z"
    />
    <path
      :class="['left', selectedColor === 'blu' ? 'active' : null]"
      d="m50 0a50 50 0 0 0 -50 50 50 50 0 0 0 6.7 25l43.3-25z"
    />
    <path
      :class="['right', selectedColor === 'argento' ? 'active' : null]"
      d="m50 0v50l43.3 25a50 50 0 0 0 6.7-25 50 50 0 0 0 -50-50z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    selectedColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
  .mobile-color-selector {
    width: 20px;
    height: 20px;
    overflow: visible;

    .bottom {
      fill: var(--pink);
    }
    .left {
      fill: var(--blue);
    }
    .right {
      fill: var(--grey-2);
    }

    .active {
      transform: scale(1.2);
      transform-origin: 50px 50px;
    }
  }
</style>
