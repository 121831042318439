<template>
  <transition
    name="fade"
    appear
  >
    <div
      v-if="selectedVariation && selectedVariation.gds_featured_image && imageIsLoaded"
      class="number-configurator__digit"
      @click="$mq.isMobileM ? cycleColors() : null"
    >
      <Figure
        :key="selectedVariation.id"
        :data="
          Object.assign(selectedVariation.gds_featured_image, {
            lazyload: false,
            ratio: true,
            object_fit: 'cover',
          })
        "
      />
      <Flex
        v-if="!$mq.isMobileM"
        column-gap="xxxs"
        class="number-configurator__digit__color-selectors"
      >
        <input
          v-for="(color, index) in colors"
          :key="color"
          v-model="selectedColor"
          :class="
            `number-configurator__digit__color-selectors__color--${index}`
          "
          type="radio"
          :name="`number-configurator-color-${$vnode.key}`"
          :value="color"
        >
      </Flex>
      <MobileColorSelector
        v-else
        :selected-color="selectedColor"
      />
    </div>
  </transition>
</template>

<script>
import { fetchVariations } from '@/api';

import Figure from '@/components/media/figure';
import MobileColorSelector from '@/components/pad/number-configurator-digit-mobile-color-selector';

export default {
  components: {
    Figure,
    MobileColorSelector,
  },
  props: {
    digit: {
      type: Object,
      default: () => {},
    },
    inflation: {
      type: String,
      default: 'aria',
    },
  },
  data() {
    return {
      selectedColor: 'blu',
      variations: [],
      selectedVariation: null,
      imageIsLoaded: false,
    };
  },
  computed: {
    colors() {
      return this.digit.attributes_slugs.pa_colore;
    },
  },

  watch: {
    selectedColor() {
      this.setSelectedVariation();
    },
    inflation() {
      this.setSelectedVariation();
    },
    'selectedVariation.gds_featured_image': function (newVal) {
      if (newVal) {
        this.imageIsLoaded = true;
      }
    },
  },

  async created() {
    if (this.digit.type.startsWith('variable')) {
      const { data: variations } = await fetchVariations({
        params: { slug: this.digit.slug },
      });

      this.variations = variations;

      this.setSelectedVariation();
    }
  },
  methods: {
    setSelectedVariation() {
      this.selectedVariation = this.variations.length > 0
        ? this.variations.filter(
          (variation) => variation.attributes_slugs.pa_gonfiaggio === this.inflation
                  && variation.attributes_slugs.pa_colore === this.selectedColor,
        )[0]
        : null;
    },
    cycleColors() {
      const currentColorIndex = this.colors.indexOf(this.selectedColor);
      if (currentColorIndex < this.colors.length - 1) {
        this.selectedColor = this.colors[currentColorIndex + 1];
      } else [this.selectedColor] = this.colors;
    },
  },
};
</script>

<style lang="scss">
  .number-configurator {
    &__digit {
      display: grid;
      row-gap: var(--spacer-s);
      justify-items: center;
      align-content: center;
      width: 300px;

      @include mq(l) {
        row-gap: var(--spacer-xl);
        width: 400px;
      }

      &__color-selectors {
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          position: relative;
          cursor: pointer;
        }

        input:checked {
          transform: scale(1.2);
        }

        &__color {
          &--0 {
            background-color: var(--grey-2);
          }
          &--1 {
            background-color: var(--blue);
          }
          &--2 {
            background-color: var(--pink);
          }
        }
      }
    }
  }
</style>
