<template>
  <div>
    <div class="header">
      <Wrapper
        v-if="!firstLoading"
        size="xl"
        boxed
        class="main-navigation"
      >
        <div class="checkout__header">
          <Cta
            v-if="!$mq.isMobileM"
            :data="{
              title: $labels.cart_button,
              beforeSlot: true,
              size: 's--700',
              url: { name: 'Cart' },
            }"
          >
            <Icon
              size="xs"
              name="arrow-left"
            />
          </Cta>
          <Icon
            v-else
            size="xs"
            name="arrow-left"
            :to="{ name: 'Cart' }"
          />
          <h2 class="typo--l">
            {{ $labels.checkout }}
          </h2>
        </div>
      </Wrapper>
    </div>

    <!--  -->
    <Wrapper
      size="xl"
      boxed
    >
      <Grid
        align="start"
        column-gap="xs"
      >
        <Column
          class="checkout__scrollable-column"
          :size="{ default: '12', l: '7' }"
        >
          <Grid
            col="12"
            row-gap="xs"
            class="checkout__left"
          >
            <AccordionWrapper class="form-theme--default">
              <!-- Account -->
              <AccordionItem>
                <template slot="accordion-trigger">
                  <div>
                    <h4
                      v-if="user"
                    >
                      {{ $labels.welcome_back }} {{ userNiceName }}.
                    </h4>
                    <h4
                      v-else
                    >
                      {{ $labels.do_you_have_account }}
                    </h4>
                  </div>
                </template>
                <template slot="accordion-content">
                  <div v-if="user">
                    <Spacer>
                      <!-- bottom="l" -->
                      {{ $labels.logged_in_with_mail }}
                      <a :href="'mailto:' + user.user_email">{{
                        user.user_email
                      }}</a>.
                    </Spacer>
                    <!-- <Cta
                    :data="{
                      title: $labels.logout,
                      url: {name: 'Logout'},
                      fn: logout
                    }"
                  /> -->
                  </div>
                  <div v-else>
                    <Form
                      id="login"
                      theme="plain"
                    />
                    <LoginSocial />
                    <Spacer
                      use-margin
                      top="l"
                    >
                      <Cta
                        :data="{
                          title: $labels.lost_password,
                          fn: toggleRecoverPassword,
                        }"
                      />
                    </Spacer>
                    <Spacer
                      v-if="showRecoverPassword"
                      use-margin
                      top="l"
                    >
                      <Form
                        id="recovery-password"
                        theme="plain"
                      />
                    </Spacer>
                  </div>
                </template>
              </AccordionItem>
            </AccordionWrapper>
            <Form
              id="checkout"
              ref="form"
              theme="multi"
            />
          </Grid>
        </Column>
        <Column
          class="checkout__scrollable-column"
          :size="{ default: '12', l: '5' }"
        >
          <Grid
            col="12"
            row-gap="xs"
          >
            <CheckoutCalendar />
            <CheckoutOrder />
          </Grid>
        </Column>
      </Grid>
    </Wrapper>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import isEmpty from 'lodash.isempty';
import { mapGetters } from 'vuex';

import { getCartPaymentGateways } from '@/api';
import { stripeMountCard, unparseCurrency } from '@/assets/js/utils-wc';

import Form from '@/components/form';
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import CheckoutOrder from '@/components/wc/checkout-order';
import LoginSocial from '@/components/wc/login-social';
import CheckoutCalendar from '@/components/pad/checkout-calendar';

export default {
  name: 'Checkout',
  components: {
    Form,
    Cta,
    Icon,
    AccordionWrapper,
    AccordionItem,
    CheckoutOrder,
    LoginSocial,
    CheckoutCalendar,
  },
  data() {
    return {
      firstLoading: true,
      paymentMethod: null,
      stripe: null,
      stripeCard: null,
      stripeClientSecret: null,
      cartPaymentGateways: null,
      showRecoverPassword: false,
      mainNavigationVisibility: true,
      prevCartTotal: null,
      oldScrollY: 0,
      // hasPaymentIntent: false,
      // cartLoaded: this.cartLoading,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'cart',
      'customer',
      'paymentGateways',
      'paymentGatewaysActive',
      'paymentMetas',
      'cartLoading',
      'wcSettings',
    ]),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
    userNiceName() {
      if (this.customer && this.customer.first_name) {
        return this.customer.first_name;
      }
      return this.user.display_name;
    },
  },
  watch: {
    cartLoading() {
      // if (!val) this.cartLoaded = true;
      if (this.cartLoading === false) {
        this.firstLoading = false;
        if (this.isCartEmpty) {
          this.$router.push('/shop');
        }
      }
    },
    cart() {
      const total = this.unparseCurrency(this.cart.totals.total) + this.unparseCurrency(this.cart.totals.total_tax);
      // console.log(this.prevCartTotal, total);
      if (this.prevCartTotal !== total) {
        this.updatePaymentsMethods();
      }
      this.prevCartTotal = total;
    },
  },
  async mounted() {
    document.addEventListener('scroll', this.handleScroll);

    await this.$store.dispatch('getCart');

    // Marketing
    this.$bus.$emit('beginCheckout', this.cart);
    this.updatePaymentsMethods();

    // this.hasPaymentIntent = true;
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    async updatePaymentsMethods() {
      const { data } = await getCartPaymentGateways();
      this.$store.commit('SET_PAYMENT_GATEWAYS_ACTIVE', Object.keys(data));
      this.cartPaymentGateways = Object.keys(data);
      if (
        this.paymentGateways
          && this.paymentGateways.stripe
          && this.paymentGateways.stripe.publishable_key
      ) {
        const stripe = await loadStripe(
          this.paymentGateways.stripe.publishable_key,
          {
            locale: 'it', // TODO: get from WPML
          },
        );
        this.$store.commit('SET_PAYMENT_METAS', {
          id: 'stripe',
          key: 'stripe',
          meta: stripe,
        });
        // await createAndStorePaymentIntent(this.$store);
        stripeMountCard(this.$store, this.paymentMetas);
      }
    },
    logout() {
      this.$store.dispatch('logout');
    },
    async setPaymentGateway() {
      const body = {
        gateway_id: this.paymentMethod,
      };
      this.$store.dispatch('setPaymentGateway', body);
    },
    toggleRecoverPassword() {
      this.showRecoverPassword = !this.showRecoverPassword;
    },
    handleScroll() {
      const newScrollY = window.scrollY;

      if (newScrollY > 0 && newScrollY > this.oldScrollY) {
        this.mainNavigationVisibility = false;
        this.oldScrollY = newScrollY;
      } else {
        this.mainNavigationVisibility = true;
        this.oldScrollY = newScrollY;
      }
    },
  },
};
</script>

<style lang="scss">
  .checkout {
    &__header {
      display: grid;
      grid-template-columns: 1fr min-content 1fr;
      align-items: center;
      transition: transform 0.2s;
      min-height: inherit;
      a {
        display: block;
      }
    }

    &__scrollable-column {
      padding-top: var(--spacer-l);
      padding-bottom: var(--spacer-xxl);
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @include mq(l) {
        overflow-y: scroll;
        padding: 10px;
        padding-top: var(--spacer-l);
        padding-bottom: var(--spacer-xxl);
        height: calc(100vh - 90px);
      }
    }
  }
</style>
