var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cover"},[_c('div',{staticClass:"cover__content"},[(_vm.data.title && _vm.data.title !== '')?_c('Title',{attrs:{"data":{
        value: _vm.data.title,
        tag: 'h1',
        size: 'xl',
      }}}):_vm._e(),_c('Spacer',{staticClass:"cover__image",attrs:{"y":"m"}},[(_vm.data.figure.default)?_c('Figure',{attrs:{"data":Object.assign({}, _vm.data.figure,
          {lazyload: _vm.data.lazyload ? _vm.data.lazyload : false,
          ratio: _vm.data.ratio ? _vm.data.ratio : false,
          justify: 'center'})}}):_vm._e()],1),_c('Spacer',{attrs:{"tag":"p","top":{ m: 'xs' }}},[_vm._v(" "+_vm._s(_vm.data.text)+" ")]),(_vm.data.cta)?_c('Spacer',{attrs:{"y":"cover"}},[_c('Cta',{attrs:{"data":_vm.data.cta,"section-background-color":_vm.data.section_background_color,"theme":"button"}})],1):_vm._e()],1),(_vm.data.figure.default)?_c('Figure',{staticClass:"cover__image--background",attrs:{"data":Object.assign({}, _vm.data.figure,
      {lazyload: _vm.data.lazyload,
      ratio: _vm.data.ratio,
      align: _vm.data.align,
      justify: 'end'})}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }