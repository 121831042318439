<template>
  <div class="cap-checker">
    <Grid
      col="12"
      :row-gap="data.template === 'mini' ? 'l' : 'xl'"
    >
      <Grid
        col="12"
        row-gap="xs"
      >
        <Title
          :data="{
            value: data.title,
            tag: 'h2',
            size: data.template === 'mini' ? 'l' : 'xl',
          }"
        />
        <RichText
          v-if="data.text && data.text !== ''"
          :data="{
            value: data.text,
          }"
        />
      </Grid>
      <Form
        id="cap-checker"
        theme="highlight--inline"
        background-color="data.section_background_color"
      />
    </Grid>
    <Spacer
      v-if="!capChecker"
      top="s"
    >
      <Flex
        dir="column"
        row-gap="l"
      >
        <div>
          <h3 class="typo--l">
            {{ $labels.cap_message_1 }}
          </h3>
          <h2 class="typo--xl">
            {{ $labels.cap_message_2({ date }) }}
          </h2>
        </div>
        <Cta
          theme="button"
          :data="{
            title: $labels.cap_discover_cta,
            url: '/palloncini',
          }"
        />
      </Flex>
    </Spacer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/components/form';
import Cta from '@/components/typo/cta';
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';

// import Cookies from 'js-cookie';

export default {
  name: 'CapChecker',
  components: {
    Form,
    Cta,
    Title,
    RichText,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      capChecker: 'capChecker',
      cap: 'cap',
      date: 'formattedShippingDate',
    }),
  },
  // mounted() {
  //   const cookie = Cookies.get('gds_shipping_details');
  //   if (typeof cookie !== 'undefined') {
  //     this.SET_CAP_CHECKER()
  //       cookie: JSON.parse(cookie),
  //       capCheckerState: false,
  //     });

  // },
  // methods: {
  //   ...mapMutations(['SET_CAP_CHECKER']),
  //   editCap() {
  //     this.SET_CAP_CHECKER({ capCheckerState: true });
  //   },
  // },
};
</script>
