export default {
  user: (state) => state.user,
  customer: (state) => state.customer,
  customerSavedMethods: (state) => state.customerSavedMethods,
  orders: (state) => (state.customer ? state.customer.orders : []),
  subscriptions: (state) => (state.customer ? state.customer.subscriptions : []),
  addresses: (state) => (state.customer ? {
    billing: state.customer.billing,
    shipping: state.customer.shipping,
  } : null),
  cart: (state) => state.cart,
  showCart: (state) => state.showCart,
  wcSettings: (state) => state.settings,
  paymentGateways: (state) => state.settings.woocommerce_payments,
  paymentGatewaysActive: (state) => state.paymentGatewaysActive,
  paymentGateway: (state) => state.paymentGateway, // user selected gateway
  paymentMetas: (state) => state.paymentMetas,
  geoData: (state) => state.countries,
  cartLoading: (state) => state.cartLoading,
  customerShippingMethods: (state) => state.customerShippingMethods,
  capChecker: (state) => state.capChecker,
  cap: (state) => state.shippingDetails.cap,
  shippingDetails: (state) => state.shippingDetails,
  shippingDate: (state) => state.shippingDetails.date,
  shippingVendor: (state) => state.shippingDetails.vendor,
  shippingClosingDates: (state) => (state.shippingDetails.vendor ? state.shippingDetails.vendor.holidays : null),
  formattedShippingDate: (state) => {
    const date = new Date(state.shippingDetails.date);
    return new Intl.DateTimeFormat('it', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(date);
  },
};
