<template>
  <Section
    v-if="order"
    :layout="{
      section_spacer: 'l',
      section_wrapper: 'm',
    }"
    class="after-checkout"
  >
    <div
      v-if="order.status !== 'failed'"
      class="after-checkout__block"
    >
      <figure>
        <img :src="require('@/assets/svg/illustrations/after-checkout.svg')">
      </figure>
      <div class="after-checkout__block__text">
        <h1 class="typo--xl">
          {{ $labels.order_success_title }}
        </h1>
        <h2 class="typo--l">
          {{ $labels.order_success_text }}
        </h2>
      </div>
      <Separator />
      <OrderHeader :order="order" />
      <Separator />
      <div class="after-checkout__block__summary">
        <h3 class="typo--m--700">
          {{ $labels.order_details }}
        </h3>
        <OrderDetails :order="order" />
      </div>
    </div>
    <div
      v-else
      class="after-checkout__text--fail"
    >
      <h1>{{ $labels.order_fail_title }}</h1>
      <h2>{{ $labels.order_fail_text }}</h2>
    </div>
  </Section>
</template>

<script>
import { mapGetters } from 'vuex';

import OrderHeader from '@/components/wc/order-header';
import OrderDetails from '@/components/wc/order-details';
import Section from '@/components/section';
import Separator from '@/components/blocks/blocks-separator.vue';

export default {
  name: 'ThankYou',
  components: {
    OrderDetails,
    OrderHeader,
    Section,
    Separator,
  },
  data() {
    return {
      order: null,
    };
  },
  computed: {
    ...mapGetters(['wcSettings', 'paymentGateways']),
    total() {
      return this.parseCurrency(Number(this.order.total));
    },
    paymentGateway() {
      if (this.order && this.order.payment_method) {
        if (this.paymentGateways[this.order.payment_method]) {
          return this.paymentGateways[this.order.payment_method].title;
        }
        return `${this.order.payment_method
          .charAt(0)
          .toUpperCase()}${this.order.payment_method.slice(1)}`;
      }
      return null;
    },
  },
  async mounted() {
    this.$store.dispatch('clearCart');
    const { data } = await this.$store.dispatch('getOrder', {
      key: this.$route.query.key,
      order_id: this.$route.params.orderId,
    });
    this.order = data;
    this.$store.dispatch('loadCustomerData');
  },
  methods: {},
};
</script>

<style lang="scss">
  .after-checkout {
    figure {
      width: fit-content;
    }
    img {
      max-width: 80%;
    }
    &__block {
      display: grid;
      row-gap: var(--spacer-m);

      &__text {
        display: grid;
        row-gap: var(--spacer-xs);
        margin-bottom: var(--spacer-l);
      }

      &__summary {
        display: grid;
      row-gap: var(--spacer-m);
      }
    }
  }
</style>
