<template>
  <Spacer
    class="block--default"
    all="xs"
  >
    <AccordionWrapper>
      <AccordionItem>
        <template slot="accordion-trigger">
          <div class="form-wrapper__header">
            <div class="form-wrapper__title">
              {{ $labels.saved_cards }}
            </div>
          </div>
        </template>
        <template slot="accordion-content">
          <Spacer
            v-if="!customerSavedMethodsSorted[0]"
            bottom="l"
          >
            {{ $labels.no_saved_card }}.
          </Spacer>
          <Spacer
            v-else
            bottom="l"
            class="saved-cards"
          >
            <div
              v-for="(savedMethod, index) in customerSavedMethodsSorted"
              :key="index"
              class="saved-card"
            >
              <p class="saved-card__message">
                <span>{{ savedMethod.method.brand }}</span>
                {{ $labels.ending_in }}
                <span>{{ savedMethod.method.last4 }}</span>
                ({{ $labels.expires }} <span>{{ savedMethod.expires }}</span>)
              </p>

              <div class="saved-card__actions">
                <Cta
                  v-for="(action, i) in getAvailableActions(savedMethod)"
                  :key="i"
                  :data="{
                    title:
                      action === 'delete'
                        ? $labels.delete
                        : $labels.make_default,
                    fn:
                      action === 'delete'
                        ? () => openModal(savedMethod)
                        : () => makeDefaultCard(savedMethod),
                  }"
                  :theme="action === 'delete' ? 'button' : 'button--alt'"
                />
              </div>
            </div>
          </Spacer>
          <Spacer
            bottom="m"
            class="typo--s"
          >
            {{ $labels.add_new_card }}
          </Spacer>
          <div class="add-card">
            <div
              id="card-element"
              class="add-card__input"
            >
              <!-- Elements will create input elements here -->
            </div>
            <Cta
              theme="button--alt"
              :data="{ title: $labels.add, fn: addCard }"
            />
            <p
              id="card-errors"
              class="add-card__error"
              role="alert"
            >
              <!-- Error messages will be shown in this element -->
            </p>
          </div>
        </template>
      </AccordionItem>
    </AccordionWrapper>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import orderBy from 'lodash.orderby';

import { loadStripe } from '@stripe/stripe-js';
import {
  stripeMountCard,
  addCardToCustomerSavedMethods,
} from '@/assets/js/utils-wc';

import { setDefaultPaymentSource, deletePaymentSource } from '@/api';

import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import Cta from '@/components/typo/cta';

export default {
  name: 'CartList',
  components: {
    AccordionWrapper,
    AccordionItem,
    Cta,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'paymentGateways',
      'paymentMetas',
      'customerSavedMethods',
    ]),
    customerSavedMethodsSorted() {
      return orderBy(this.customerSavedMethods, ['is_default'], ['desc']);
    },
  },
  watch: {},
  async mounted() {
    if (
      this.paymentGateways.stripe
        && this.paymentGateways.stripe.publishable_key
    ) {
      const stripe = await loadStripe(
        this.paymentGateways.stripe.publishable_key,
        {
          locale: 'it', // TODO: get from WPML
        },
      );
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      stripeMountCard(this.$store, this.paymentMetas, {
        order_id: this.$route.params.orderId,
        key: this.$route.query.key,
      });
    }
  },
  methods: {
    async addCard() {
      this.$store.commit('SET_CART_LOADING', true);
      await addCardToCustomerSavedMethods(this.$store, {
        stripeInstance: this.paymentMetas.stripe,
      });
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    getAvailableActions(method) {
      const actions = ['delete'];
      if (!method.is_default) {
        actions.unshift('make-default');
      }
      return actions;
    },
    async deleteCard(card) {
      this.$store.commit('SET_CART_LOADING', true);
      const { status, data } = await deletePaymentSource({
        payment_source_id: card.token_key,
      });
      if (status !== 200 && status !== 202) {
        this.$store.commit('SET_SNACKBAR', { active: true, content: data });
      }
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    async makeDefaultCard(card) {
      this.$store.commit('SET_CART_LOADING', true);
      await setDefaultPaymentSource({ payment_source_id: card.token_key });
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    openModal(card) {
      this.$bus.$emit('modal', {
        type: 'dialog',
        id: 'cancelCars',
        wrapperSize: 's',
        content: {
          richtext: this.$labels.cancel_card_dialog,
          cta: {
            title: this.$labels.cancel_card,
          },
        },
        fn: () => this.deleteCard(card),
      });
    },
  },
};
</script>

<style lang="scss">
  .saved-cards {
    display: grid;
    row-gap: var(--row-gap-s);
  }
  .saved-card {
    display: grid;
    justify-content: space-between;
    align-items: center;
    gap: var(--column-gap-xs);

    @include mq(m) {
      grid-auto-flow: column;
    }

    &__message {
      span {
        @extend %typo--s--700;
        // color: var(--blue);
      }
    }

    &__actions {
      display: grid;
      column-gap: var(--column-gap-xs);
      row-gap: var(--column-gap-xxxs);
      justify-content: start;
      @include mq(s) {
        grid-auto-flow: column;
      }
    }
  }

  .add-card {
    display: grid;
    align-items: center;
    gap: var(--column-gap-xs);

    @include mq(m) {
      justify-content: space-between;
      grid-template-columns: 1fr max-content;
    }

    &__input {
      max-width: 400px;
      font-family: Arial, Helvetica, sans-serif !important;
    }

    &__error {
      height: 24px;
      color: var(--red);
    }
  }
</style>
