<template>
  <div id="app">
    <div
      ref="loader"
      class="loader"
    />
    <AppHeader />
    <Search v-if="searchBarIsVisible" />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="$route.path" />
    </transition>
    <AppFooter />

    <Modal />
    <Snackbar />
    <Loader />
    <Cart />
    <Cookie v-if="options && options.cookies" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import gsap from 'gsap';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
// import SiteAnnouncement from '@/components/ui/site-announcement';

import Modal from '@/components/ui/modal';
import Snackbar from '@/components/ui/snackbar';
import Loader from '@/components/ui/loader';
import Cookie from '@/components/ui/cookie';

import Cart from '@/components/wc/cart';
import Search from './components/blocks/search.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    // SiteAnnouncement,
    Modal,
    Cookie,
    Loader,
    Snackbar,
    Cart,
    Search,
  },
  mixins: [lazyload],
  data() {
    return {
      colors: ['red', 'orange', 'yellow', 'green', 'blue', 'pink'],
      randomColor: 'yellow',
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options', 'searchBarIsVisible']),
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );
  },
  methods: {
    enter(el, done) {
      const tl = gsap.timeline({
        defaults: { duration: 0.4, ease: 'power2.in' },
      });

      tl.set(
        el,
        {
          autoAlpha: 0,
          y: 20,
        },
        0,
      )
        .to(
          this.$refs.loader,
          {
            scaleY: 0,
            transformOrigin: '50% 0%',
          },
          0.1,
        )
        .to(
          el,
          {
            autoAlpha: 1,
            y: 0,
            clearProps: 'all',
          },
          0.4,
        )
        .then(() => {
          this.randomColor = this.colors[
            Math.floor(Math.random() * this.colors.length)
          ];
          this.$refs.loader.style.backgroundColor = `var(--${this.randomColor}`;
          this.$store.commit('SET_TRANSITION', false);
          done();
        });
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      if (this.$route.name === 'Search') {
        this.$store.commit('SET_TRANSITION', false);
        done();
      } else {
        const tl = gsap.timeline({
          defaults: { duration: 0.4, ease: 'power2.out' },
        });

        tl.to(
          el,
          {
            autoAlpha: 0,
            y: -20,
          },
          0,
        )
          .to(
            this.$refs.loader,
            {
              scaleY: 1,
              transformOrigin: '50% 100%',
            },
            0.1,
          )
          .then(() => {
            window.scroll(0, 0);
            done();
          });
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/style.scss";

  .app-loaded {
    #loader {
      display: none;
    }
  }

  .loader {
    position: fixed;
    z-index: 500;
    top: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: var(--yellow);
  }
</style>
