<template>
  <div>
    <main v-if="!loading">
      <Section
        :layout="{
          section_spacer: 'l',
          section_wrapper: 'xl',
        }"
      >
        <Grid column-gap="xs">
          <Column
            :size="{
              default: 12,
              l: 6,
            }"
          >
            <Media
              ref="product-gallery"
              class="product__gallery"
              :data="{
                type: 'gallery',
                gallery: productGallery,
                ratio: true,
                lazyload: false,
                object_fit: true,
              }"
            />
          </Column>
          <Column
            :size="{
              default: 12,
              l: 6,
            }"
          >
            <Spacer
              :top="{ default: 'm', l: '0' }"
              bottom="xxs"
            >
              <Title :data="{ value: post.name }" />
            </Spacer>
            <Price
              v-if="product"
              class="typo--m--700"
              :product="product"
            />
            <Spacer
              use-margin
              y="m"
            >
              <Separator />
            </Spacer>
            <ProductTabs
              :balloon-types="post.gds_taxonomies.balloon_types"
              :description="post.short_description"
              :information="post.description"
            />
            <Spacer
              use-margin
              bottom="m"
            >
              <Separator />
            </Spacer>
            <div class="product__interactive">
              <div
                v-if="hasVariations"
                class="product__interactive__variations"
              >
                <div
                  v-for="(attribute, index) in attributes"
                  :key="index"
                >
                  <Select
                    v-model="attribute.model"
                    class="product__variation-selector"
                    theme="variation"
                    :data="getAttribute(attribute)"
                    :model.sync="attribute.model"
                  >
                    <label
                      slot="label"
                      for="terms"
                    >{{ attribute.name }}</label>
                  </Select>
                  <!-- <Icon
                        v-if="attribute.model"
                        name="close"
                        theme="small"
                        :fn="() => resetAttribute(attribute)"
                      /> -->
                </div>
              </div>

              <Availability :product="product" />

              <Flex
                row-gap="xs"
                column-gap="xs"
              >
                <Number
                  :data="{
                    id: `number`,
                    fn: numberChanged,
                    itemKey: product.id,
                    readonly: cartLoading || isOos,
                  }"
                  :value.sync="numberToBeAdded"
                />
                <Cta
                  :data="{
                    title: $labels.add_to_cart,
                    url: false,
                    fn: addToCart,
                  }"
                  :disabled="!addableToCart"
                  theme="button"
                />
              </Flex>
            </div>
          </Column>
        </Grid>
      </Section>
    </main>
    <Wrapper
      size="xl"
      boxed
    >
      <Separator />
    </Wrapper>
    <Section
      :layout="{
        section_spacer: 'l',
        section_wrapper: 'xl',
      }"
    >
      <RelatedProducts :relatable-product="product" />
    </Section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import orderBy from 'lodash.orderby';
import data from '@/mixins/data';
import { addableToCart, isOos } from '@/assets/js/utils-wc';

import Media from '@/components/blocks/media';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
// import Icon from '@/components/ui/icon.vue';
import Price from '@/components/wc/price.vue';
import Availability from '@/components/wc/availability.vue';
import RelatedProducts from '@/components/wc/related-products.vue';

import Number from '@/components/form/fields/number';
import Select from '@/components/form/fields/select';
import Separator from '@/components/blocks/blocks-separator';
import ProductTabs from '@/components/pad/product-tabs';
import Section from '@/components/section';

import { fetchVariations } from '@/api';

// N.B. testato fino a 2 attributi

export default {
  name: 'SingleProduct',
  components: {
    Media,
    Title,
    Cta,
    // Icon,
    Price,
    Availability,
    Number,
    Select,
    RelatedProducts,
    Separator,
    ProductTabs,
    Section,
  },
  mixins: [data],
  data() {
    return {
      loading: true,
      product: null,
      variations: [],
      attributes: [],
      selectedVariation: null,
      selectedImage: null,
      numberToBeAdded: 1,
      activeTab: 'description',
    };
  },
  computed: {
    ...mapGetters(['cartLoading']),
    hasVariations() {
      return this.post.type.startsWith('variable');
    },
    isOos() {
      return isOos(this.product);
    },
    addableToCart() {
      if (this.hasVariations && !this.selectedVariation) {
        return false;
      }
      if (!addableToCart(this.product) || isOos(this.product)) {
        return false;
      }
      return true;
    },
    productGallery() {
      this.variations.forEach((variation) => {
        this.post.gallery.push(variation.gds_featured_image);
      });
      return [this.post.gds_featured_image].concat(
        this.post.gallery ? this.post.gallery : [],
      );
    },
  },
  watch: {
    $route() {
      this.setSelectedVariation();
    },
    selectedImage() {
      setTimeout(() => {
        if (
          this.$refs['product-gallery']
            && this.$refs['product-gallery'].$children[0]
        ) {
          this.$refs['product-gallery'].$children[0].gallery.selectCell(
            `[data-item-id="${this.selectedImage}"]`,
          );
        }
      }, 100); // Await flickity loading
    },
  },
  async created() {
    this.product = this.post;
    if (this.hasVariations) {
      this.$store.commit('SET_CART_LOADING', true);
      const { data: variations } = await fetchVariations({
        params: { slug: this.post.slug },
      });
      this.variations = variations;
      const attributesKeys = Object.keys(this.post.attributes_slugs);
      attributesKeys.forEach((attribute_slug, index) => {
        const newAttribute = {
          type: 'select',
          model: null,
          id: attribute_slug,
          name: this.post.attributes[index].name,
          hidden: false,
          placeholder: null,
          options: {},
          originalOptions: {},
          fn: this.updateSelectedVariation,
        };
        this.post.attributes[index].options.forEach((option, i) => {
          // Check if exists any variation with this attribute
          if (
            this.variations.findIndex(
              (variation) => variation.attributes_slugs[attribute_slug]
                  === this.post.attributes_slugs[attribute_slug][i],
            ) > -1
          ) {
            newAttribute.options[
              this.post.attributes_slugs[attribute_slug][i]
            ] = option;
            newAttribute.originalOptions[
              this.post.attributes_slugs[attribute_slug][i]
            ] = option;
          }
        });
        if (Object.keys(newAttribute.options).length !== 0) {
          this.attributes.push(newAttribute);
        }
      });
      this.setSelectedVariation();
      this.$store.commit('SET_CART_LOADING', false);
      this.loading = false;

      const attributesSlugs = Object.keys(
        this.variations[0].attributes_slugs,
      );

      if (attributesSlugs.length > 0 && isEmpty(this.$route.query)) {
        const variationsSorted = orderBy(
          this.variations,
          (variation) => Object.values(variation.attributes_slugs),
          'asc',
        );
        const query = {};
        attributesSlugs.forEach((attribute) => {
          query[`attribute_${attribute}`] = variationsSorted[0].attributes_slugs[attribute];
        });

        this.$router.replace({ query });
      }
    } else {
      this.loading = false;
    }
  },
  async mounted() {
    // Marketing
    this.$bus.$emit('viewItem', this.product);
  },
  methods: {
    addToCart() {
      this.$store.dispatch(
        'addToCart',
        Object.assign(this.post, {
          id: String(this.post.id),
          variation_id: this.selectedVariation,
          variation: this.variations.find(
            (variation) => this.selectedVariation === variation.id,
          ),
          quantity: this.numberToBeAdded,
          quantityDelta: this.numberToBeAdded,
        }),
      );
      this.$store.commit('TOGGLE_CART');
    },
    numberChanged(val) {
      if (val[0]) {
        /* eslint-disable */
          this.numberToBeAdded = val[1];
        } else {
          this.numberToBeAdded = val.target.value;
        }
        if (this.numberToBeAdded < 1) {
          this.numberToBeAdded = 1;
        }
        if (
          this.product.stock_quantity &&
          this.numberToBeAdded > this.product.stock_quantity
        ) {
          this.numberToBeAdded = this.product.stock_quantity;
        }
      },
      getAttribute(attribute) {
        const select = document.querySelector(`select#${attribute.id}`);
        const currentFixedOption = document.querySelector(
          ".field.focus select"
        );
        if (
          currentFixedOption &&
          select &&
          select.closest(".field") &&
          !select.closest(".field").classList.contains("focus")
        ) {
          // Re set original value
          const originalOptionsKeys = Object.keys(attribute.originalOptions);
          originalOptionsKeys.forEach((originalOptionsKey) => {
            if (!attribute.options[originalOptionsKey]) {
              attribute.options[originalOptionsKey] =
                attribute.originalOptions[originalOptionsKey];
            }
          });

          const currentFixedKey = currentFixedOption.getAttribute("id");
          const currentFixedValue = currentFixedOption.value;
          const optionsKeys = Object.keys(attribute.options);
          optionsKeys.forEach((optionsKey) => {
            const existingVariation = this.variations.filter((variation) => {
              return (
                variation.attributes_slugs[currentFixedKey] ===
                  currentFixedValue &&
                variation.attributes_slugs[attribute.id] === optionsKey
              );
            });
            if (!existingVariation[0]) {
              delete attribute.options[optionsKey];
            }
          });
        }

        return attribute;
      },
      resetAttribute(attribute) {
        attribute.model = null;
        this.resetSelectedVariation();
      },
      resetSelectedVariation() {
        this.selectedVariation = null;
        this.selectedImage = null;
      },
      setSelectedVariation() {
        const queryVariations = Object.keys(this.$route.query);
        if (queryVariations.length === 0) {
          this.attributes.forEach((attribute) => {
            attribute.model = null;
          });
          this.resetSelectedVariation();
        } else {
          // Re-attribute model if reloading page
          queryVariations.forEach((queryVariation) => {
            this.attributes.forEach((attribute) => {
              if (attribute.id === queryVariation.replace("attribute_", "")) {
                attribute.model = this.$route.query[queryVariation];
              }
            });
          });

          const selectedVariation = this.variations.find((variation) => {
            let isRightVariation = false;
            let correctAttributes = 0;
            this.attributes.forEach((attribute) => {
              if (
                attribute.model === variation.attributes_slugs[attribute.id]
              ) {
                correctAttributes += 1;
              }
            });
            if (correctAttributes === variation.attributes.length) {
              isRightVariation = true;
            }
            return isRightVariation;
          });
          if (selectedVariation) {
            this.selectedVariation = selectedVariation.id;
            this.selectedImage = selectedVariation.gds_featured_image
              ? selectedVariation.gds_featured_image.id
              : null;
            this.product = selectedVariation;
          } else {
            this.resetSelectedVariation();
          }
        }
        // Marketing
        this.$bus.$emit("selectItem", this.product);
      },
      updateSelectedVariation() {
        let attributesSelected = 0;
        const query = {};
        this.attributes.forEach((attribute) => {
          if (attribute.model) {
            query[`attribute_${attribute.id}`] = attribute.model;
            attributesSelected += 1;
          }
        });
        if (
          attributesSelected === this.attributes.length &&
          !isEqual(query, this.$route.query)
        ) {
          this.$router.push({ query });
        }
      },
    },
  };
</script>

<style lang="scss">
  .product {
    &__gallery {
      box-shadow: 0 0 10px var(--shadow);
      border-radius: var(--border-radius);
      figure {
        @include aspect-ratio(1, 1);
      }
    }

    &__interactive {
      width: fit-content;
      display: grid;
      row-gap: var(--spacer-xxxs);

      &__variations {
        display: grid;
        row-gap: var(--spacer-xs);

        .input-errors {
          display: none;
        }

        select {
          width: 100%;
        }
      }
    }
  }
</style>
