import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Repeater from '@/components/blocks/repeater';
import List from '@/components/blocks/list';
import Archive from '@/components/blocks/archive';
import CapChecker from '@/components/blocks/custom/cap-checker';
import Newsletter from '@/components/blocks/newsletter';
// import PageMenu from '@/components/ui/page-menu';
import Hero from '@/components/blocks/hero';
import Accordion from '@/components/blocks/accordion';
import Marquee from '@/components/blocks/marquee';
import Search from '@/components/blocks/search';
import Separator from '@/components/blocks/blocks-separator';
import Cover from '@/components/blocks/cover';
import Figure from '@/components/media/figure';
import Cta from '@/components/blocks/cta-block';

const components = new Map();

components.set('content', Content);
components.set('media', Media);
components.set('repeater', Repeater);
components.set('list', List);
components.set('archive', Archive);
components.set('hero', Hero);
components.set('marquee', Marquee);
components.set('newsletter', Newsletter);
components.set('accordion', Accordion);
components.set('search', Search);
components.set('cap_checker', CapChecker);
// components.set('page_menu', PageMenu);
components.set('separator', Separator);
components.set('cover', Cover);
components.set('figure', Figure);
components.set('cta', Cta);

export default components;
