<template>
  <div class="content">
    <Grid
      grid="1"
      row-gap="xl"
    >
      <Grid
        v-if="data.blockTemplate === 'our-service'"
        grid="1"
        row-gap="xxxs"
      >
        <Title
          v-if="data.title && data.title !== ''"
          :data="{
            value: data.title,
            tag: data.tag_title,
            size: 'm--700',
          }"
        />
        <RichText
          v-if="data.rich_text && data.rich_text !== ''"
          :data="{
            value: data.rich_text,
            size: data.size_rich_text,
          }"
        />
      </Grid>
      <Grid
        v-else-if="data.blockTemplate === 'newsletter--footer'"
        grid="1"
        row-gap="xxxs"
      >
        <Label
          v-if="data.label && data.label !== ''"
          :data="{
            value: data.label,
            tag: data.tag_label,
            size: data.size_label,
          }"
        />
        <RichText
          v-if="data.rich_text && data.rich_text !== ''"
          :data="{
            value: data.rich_text,
            size: data.size_rich_text,
          }"
        />
      </Grid>
      <Grid
        v-else
        grid="1"
        row-gap="xs"
      >
        <Label
          v-if="data.label && data.label !== ''"
          :data="{
            value: data.label,
            tag: data.tag_label,
            size: data.size_label,
          }"
        />
        <Title
          v-if="data.title && data.title !== ''"
          :data="{
            value: data.title,
            tag: data.tag_title,
            size: data.size_title,
          }"
        />
        <RichText
          v-if="data.rich_text && data.rich_text !== ''"
          :template="data.block_template"
          :data="{
            value: data.rich_text,
            size: data.size_rich_text,
          }"
        />
      </Grid>
      <Cta
        v-for="(item, index) in data.ctas"
        :key="index"
        :data="item.cta"
        :theme="data.cta_layout"
      />
    </Grid>
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .content {
    h3 {
      @extend %typo--l;
    }
  }
</style>
