<template>
  <Spacer
    y="xl"
    :use-margin="true"
  >
    <Wrapper
      v-if="!firstLoading"
      size="xl"
      boxed
    >
      <div class="typo--l">
        {{ $labels.your_cart }}
      </div>
      <!-- Cart -->

      <Wrapper
        v-if="isCartEmpty && !cartLoading"
        size="s"
      >
        <div class="block-theme--default">
          <div class="t-center">
            <Spacer y="m">
              {{ $labels.your_cart_empty }}
            </Spacer>
          </div>
          <Flex justify="center">
            <Cta
              theme="button"
              :data="{
                title: $labels.go_to_shop,
                url: '/shop',
              }"
            />
          </Flex>
        </div>
      </Wrapper>
      <Spacer
        v-else
        top="l"
      >
        <Grid
          :col="{
            default: 12,
            l: 6,
          }"
          column-gap="xs"
          row-gap="xs"
        >
          <Column size="1">
            <Form
              id="order-card"
              class="cart-column--left"
              theme="order-card"
            />
          </Column>
          <Column size="1">
            <Grid
              col="12"
              row-gap="m"
              class="form-theme--default"
            >
              <h4>
                {{ $labels.cart_products_title }}
              </h4>
              <CartList theme="complete" />
              <Separator />
              <Coupon />
              <Separator />
              <CartTotals theme="cart" />
              <Flex justify="center">
                <Cta
                  theme="button"
                  :data="{
                    title: $labels.go_to_checkout,
                    url: '/checkout',
                  }"
                />
              </Flex>
              <h5>
                {{ $labels.cart_disclaimer }}
              </h5>
            </Grid>
          </Column>
        </Grid>
      </Spacer>
      <!-- <div class="block-theme--default">
          <div>
            {{ $labels.may_be_interested_in }}
          </div>
          <div>
            <Grid>
              <Column
                v-for="(item, index) in products"
                :key="index"
                :size="{
                  default: 12,
                  s: 6,
                  m: 3,
                }"
              >
                <ThumbProduct :data="item" />
              </Column>
            </Grid>
          </div>
        </div> -->
    </Wrapper>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash.isempty';

// import {
//   cancelOrder,
// } from '@/api';

// import ThumbProduct from '@/components/thumb/product';
import Cta from '@/components/typo/cta';
import CartList from '@/components/wc/cart-list';
import CartTotals from '@/components/wc/cart-totals';
import Form from '@/components/form';
import Separator from '@/components/blocks/blocks-separator';
import Coupon from '@/components/wc/coupon';

export default {
  name: 'Cart',
  components: {
    // ThumbProduct,
    Cta,
    CartList,
    CartTotals,
    Form,
    Separator,
    Coupon,
  },
  data() {
    return {
      firstLoading: true,
      products: [],
    };
  },
  computed: {
    ...mapGetters(['cart', 'cartLoading', 'showCart', 'wcSettings']),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
  },
  watch: {
    cartLoading() {
      if (this.cartLoading === false) {
        this.firstLoading = false;
      }
    },
  },
  async mounted() {
    // Ordine da cancellare
    if (
      this.$route.query.cancel_order === 'true'
      && this.$route.query.order
      && this.$route.query.order_id
    ) {
      await this.$store.dispatch('cancelOrder', {
        order_id: this.$route.query.order_id,
        key: this.$route.query.order,
      });
    }
    // Altrimenti mostra carrello
    await this.$store.dispatch('getCart');
    const request = {
      type: 'product',
      params: {
        // include: [this.cart.items.map((x) => x.product_id)],
        per_page: 100,
        offset: 0,
        lang: this.$store.state.lang,
      },
    };
    this.products = await this.$store.dispatch('getItems', request);
    // Marketing
    this.$bus.$emit('viewCart', this.cart);
  },
};
</script>

<style lang="scss">
.cart-column--left {
  position: sticky;
  top: var(--spacer-l);
}
</style>
