<template>
  <transition name="announcement">
    <Spacer
      v-if="announcement.active"
      v-show="announcementIsVisible && !cookie"
      y="xxxs"
      class="announcement"
    >
      <Wrapper
        size="xl"
        boxed
      >
        <Flex
          justify="space-between"
          align="center"
          nowrap
        >
          <h4>{{ announcement.text }}</h4>
          <Icon
            name="close"
            size="xs"
            :fn="dismissAnnouncement"
          />
        </Flex>
      </Wrapper>
    </Spacer>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import Cookies from 'js-cookie';

import Icon from '@/components/ui/icon';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      announcementIsVisible: true,
      cookie: !!Cookies.get('gds_announcement_dismissed'),
    };
  },
  computed: {
    ...mapGetters(['options']),
    announcement() {
      const { announcement } = this.options;
      return announcement;
    },
  },
  methods: {
    dismissAnnouncement() {
      Cookies.set('gds_announcement_dismissed', 'true', { expires: 7, sameSite: 'strict' });
      this.announcementIsVisible = false;
    },
  },
};
</script>

<style lang="scss">
  .announcement {
    position: relative;
    z-index: 100;
    background: var(--yellow);
  }

  .announcement-enter-active,
  .announcement-leave-active {
    transition: all 0.2s ease;
  }
  .announcement-leave,
  .announcement-enter-to {
    transform: translateY(-100%);
  }
</style>
