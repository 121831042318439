export default {
  SET_NUMBER(state, number) {
    state.selectedNumber = number;
  },
  TOGGLE_MOBILE_MENU(state) {
    state.mobileMenuVisibility = !state.mobileMenuVisibility;
  },
  HIDE_MOBILE_MENU(state) {
    state.mobileMenuVisibility = false;
  },
  TOGGLE_SEARCH_BAR(state) {
    state.searchBarIsVisible = !state.searchBarIsVisible;
  },
  HIDE_SEARCH_BAR(state) {
    state.searchBarIsVisible = false;
  },
};
