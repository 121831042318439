<template>
  <main class="main--category">
    <Spacer
      v-if="$mq.isMobile && cover && cover.title && cover.title !== ''"
      top="m"
      left="s"
    >
      <Title
        :data="{
          value: cover.title,
          tag: 'h1',
          size: 'xl',
        }"
      />
    </Spacer>
    <Section
      v-if="!$mq.isMobile"
      :layout="{
        section_background_color: cover.backgroundColor,
        section_spacer: 'm',
        section_wrapper: 'xl',
      }"
    >
      <Cover
        :data="{
          title: cover.title,
          text: cover.text,
          figure: cover.image,
        }"
      />
    </Section>
    <Section
      v-if="request.slug === 'numeri'"
      :layout="{
        section_spacer: 'l',
        section_wrapper: 'xl',
      }"
    >
      <NumberConfigurator />
    </Section>
    <Wrapper
      v-if="!$mq.isMobile"
      size="xl"
    >
      <Separator />
    </Wrapper>
    <Section
      :layout="{
        section_spacer: 'l',
        section_wrapper: 'xl',
      }"
    >
      <div class="category-archive">
        <h2
          v-if="request.slug === 'numeri'"
          class="typo--l"
        >
          {{ $labels.numbers_composition }}
        </h2>
        <Archive
          :data="{
            archive_type: 'archive',
            archive: request.postType,
            posts_per_page: 16,
            infinite_scroll: true,
            archive_category: {
              taxonomy: request.category,
              term_id: request.categoryId,
            },
            layout: [
              {
                media_query: 'default',
                layout_size: '12',
                layout_type: 'grid',
              },
              {
                media_query: 'm',
                layout_size: `${request.slug === 'numeri' ? '12' : '6'}`,
                layout_type: 'grid',
              },
              {
                media_query: 'l',
                layout_size: `${request.slug === 'numeri' ? '6' : '3'}`,
                layout_type: 'grid',
              },
            ],
          }"
        />
      </div>
    </Section>
    <Section
      v-if="request.slug === 'numeri'"
      :layout="{
        section_spacer: 'l',
        section_wrapper: 'xl',
      }"
    >
      <div class="category-archive">
        <Archive
          :data="{
            archive_type: 'archive',
            archive: request.postType,
            posts_per_page: 16,
            infinite_scroll: true,
            archive_category: {
              taxonomy: request.category,
              term_id: 667,
            },
            layout: [
              {
                media_query: 'default',
                layout_size: '12',
                layout_type: 'grid',
              },
              {
                media_query: 'm',
                layout_size: '6',
                layout_type: 'grid',
              },
              {
                media_query: 'l',
                layout_size: '3',
                layout_type: 'grid',
              },
            ],
          }"
        />
      </div>
    </Section>
    <Section
      v-if="$mq.isMobile"
      :layout="{
        section_background_color: cover.backgroundColor,
        section_spacer: 'm',
        section_wrapper: 'xl',
      }"
    >
      <Cover
        :data="{
          title: cover.title,
          text: cover.text,
          figure: cover.image,
        }"
      />
    </Section>
    <Wrapper
      v-if="!$mq.isMobile && options.sections && options.sections[0]"
      size="xxl"
    >
      <Separator />
    </Wrapper>
    <Section
      v-for="(section, parentIndex) in options.sections"
      :key="parentIndex"
      :layout="layout(section)"
      :class="`section--${parentIndex}`"
    >
      <Spacer
        v-for="(block, index) in section.blocks"
        :key="index"
        :template="block.block_spacer ? `block--${block.block_spacer}` : ''"
      >
        <component
          :is="components.get(block.acf_fc_layout)"
          :id="block.block_id || null"
          :class="[
            'block',
            `block--${block.acf_fc_layout}`,
            block.block_classes && block.block_classes !== ''
              ? block.block_classes.split(' ')
              : null,
            block.block_template && block.block_template !== ''
              ? `block-template--${block.block_template}`
              : null,
          ]"
          :data="{
            ...block,
            section_background_color: section.section_background_color,
          }"
        />
      </Spacer>
    </Section>
  </main>
</template>

<script>
import components from '@/assets/js/components';
import { mapGetters } from 'vuex';
import Title from '@/components/typo/title';
import Archive from '@/components/blocks/archive';
import NumberConfigurator from '@/components/pad/number-configurator';
import Cover from '@/components/blocks/cover';
import Section from '@/components/section';
import Separator from '@/components/blocks/blocks-separator';

export default {
  name: 'Category',
  components: {
    Title,
    Archive,
    NumberConfigurator,
    Cover,
    Section,
    Separator,
  },
  props: {
    category: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      archive: null,
      request: {},
      cover: {},
      components,
    };
  },
  computed: {
    ...mapGetters(['options', 'options']),
    capChecker() {
      const {
        category_cap_checker_title,
        category_cap_checker_is_visible,
      } = this.options;
      return {
        capCheckerIsVisible: category_cap_checker_is_visible,
        title: category_cap_checker_title,
        template: 'mini',
      };
    },
    capCheckerIsVisible() {
      return this.options.category_cap_checker_is_visible;
    },
  },
  created() {
    const { category, postType } = this.$route.meta;
    const { slug } = this.$route.params;
    const cat = this.$store.state.wp.categories.shop.default[slug];
    const { id, acf } = cat;
    const {
      title, text, section_background_color, image,
    } = acf;

    this.request = {
      category: this.category ? this.category.category : category,
      postType: this.category ? this.category.postType : postType,
      parent: 'shop',
      slug: this.category ? this.category.slug : slug,
      categoryId: id,
    };

    this.cover.title = title;
    this.cover.text = text;
    this.cover.backgroundColor = section_background_color;
    this.cover.image = image;
  },
  methods: {
    layout(section) {
      const {
        section_background_color,
        section_classes,
        section_layout,
        section_text_color,
        section_id,
        section_wrapper,
        section_spacer,
      } = section;
      return {
        section_background_color,
        section_classes,
        section_layout,
        section_text_color,
        section_id,
        section_wrapper,
        section_spacer,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .hero__item__content /deep/ .wysiwyg {
    max-width: 660px;
  }

  .category-archive {
    display: grid;
    row-gap: var(--spacer-xl);
  }
</style>
