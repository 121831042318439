<template>
  <component
    :is="tag"
    :class="[
      'icon',
      `icon-theme--${theme}`,
      `icon--size-${size}`,
      `icon-${name}`,
    ]"
    @click.stop="fn"
  >
    <router-link
      v-if="to && !external"
      :to="to"
    >
      <inline-svg :src="require(`@/assets/svg/icons/${name}.svg`)" />
    </router-link>

    <a
      v-else-if="to && external"
      :href="to"
      :target="target"
    >
      <inline-svg :src="require(`@/assets/svg/icons/${name}.svg`)" />
    </a>

    <inline-svg
      v-else
      :src="require(`@/assets/svg/icons/${name}.svg`)"
    />
  </component>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 's',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: [String, Object],
      default: () => {},
    },
    external: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
};
</script>

<style lang="scss">
  .icon {
    cursor: pointer;

    &--size-xs {
      --size: 20px;
      width: var(--size);
      height: var(--size);
      svg {
        width: var(--size);
        height: var(--size);
      }
    }

    &--size-s {
      --size: 24px;
      width: var(--size);
      height: var(--size);
      svg {
        width: var(--size);
        height: var(--size);
      }

      @include mq(m) {
        --size: 30px;
      }
    }

    &--size-m {
      --size: 28px;
      width: var(--size);
      height: var(--size);
      svg {
        width: var(--size);
        height: var(--size);
      }

      @include mq(m) {
        --size: 36px;
      }
    }

    &--size-l {
      width: $unit * 4;
      height: $unit * 4;
    }

    &-sparkle {
      path {
        fill: var(--red);
      }
    }

    &-theme {
      &--default {
        // background: var(--primary-color);
        color: var(--black);
      }

      &--alt {
        border: solid var(--line);
        border-radius: var(--border-radius--full);
        height: 40px;
        width: 40px;
        display: grid;
        align-items: center;
        justify-items: center;
      }

      &--button {
        background-color: var(--red);
        border-radius: var(--border-radius--full);
        path {
          fill: var(--white);
        }
        @media (hover: hover) {
          &:hover {
            box-shadow: 0px 0px 10px var(--red);
          }
        }
      }

      &--warning {
        path {
          fill: var(--warning-color);
        }
      }
      &--invert {
        path {
          fill: var(--white);
        }
      }

      &--circle {
        background: var(--black);
        border-radius: 50%;
        path {
          fill: var(--white);
        }
        // &--close {
        //   position: absolute;
        //   top: $unit * 0.5;
        //   right: $unit * 0.5;
        //   z-index: 10;
        // }
      }
    }
  }
</style>
