// import '@/assets/js/mq';

export default {
  data() {
    return {
      settings: {
        sendLabel: 'login',
      },
      groups: {
        login: {
          visible: true,
          fields: {
            username: {
              type: 'text',
              model: null,
              label: 'username_email',
              rules: 'required',
              size: !this.$mq.isMobileM ? 6 : 12,
            },
            password: {
              type: 'password',
              model: null,
              label: 'password',
              size: !this.$mq.isMobileM ? 6 : 12,
              // rules: 'required',
            },
          },
        },
      },
    };
  },
  methods: {
    init() {

    },
    async callback() {
      const loggedIn = await this.$store.dispatch('login', {
        username: this.groups.login.fields.username.model,
        password: this.groups.login.fields.password.model,
      });
      if (loggedIn) {
        // Attendiamo che CoCart aggiorni il Cookie del carrelo, associando l'ID all'hash casuale
        setTimeout(async () => {
          await this.$store.dispatch('loadCustomerData');
        }, 2000);
      }
    },
  },
};
