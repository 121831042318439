<template>
  <Grid
    col="4"
    class="number-configurator"
    row-gap="xs"
    column-gap="xs"
  >
    <Column :size="!$mq.isMobile ? '2' : '3'">
      <Flex
        justify="center"
        align="center"
        class="number-configurator__digits block--default"
        nowrap
      >
        <h5
          v-if="filteredDigits.length === 0"
          class="typo--xl t-center"
        >
          {{ $labels.number_configurator_empty }}
        </h5>

        <Digit
          v-for="(item, index) in filteredDigits"
          v-else-if="filteredDigits.length === selectedNumber.length"
          :key="`${item.slug}-${index}`"
          ref="digit"
          :digit="item"
          :inflation="selectedInflation"
        />
      </Flex>
    </Column>
    <Column
      class="number-configurator__side block--default"
      :size="!$mq.isMobile ? '1' : '3'"
    >
      <div class="number-configurator__side__content">
        <Title
          :data="{
            value: $labels.number_configurator_title,
            size: 'm--700',
          }"
        />
        <div class="number-configurator__side__content__block">
          <Richtext
            :data="{
              value: $labels.number_configurator_number_title,
            }"
          />
          <Form
            id="number-input"
            theme="inline"
          />
        </div>
        <div class="number-configurator__side__content__block">
          <Richtext
            :data="{
              value: $labels.number_configurator_type_title,
            }"
          />
          <Flex
            column-gap="xs"
            row-gap="xxxs"
          >
            <Cta
              :data="{
                fn: () => setSelectedInflation('aria'),
                title: 'Aria compressa',
              }"
              :theme="
                selectedInflation === 'aria'
                  ? 'button--alt'
                  : 'button--alt--disabled'
              "
            />
            <Cta
              :data="{
                fn: () => setSelectedInflation('elio'),
                title: 'Elio',
              }"
              :theme="
                selectedInflation === 'elio'
                  ? 'button--alt'
                  : 'button--alt--disabled'
              "
            />
          </Flex>
        </div>
      </div>
      <div class="number-configurator__side__content--centered">
        <h4
          v-if="selectedProduct"
          class="typo--m--700"
          v-html="selectedVariation.price_html"
        />
        <Cta
          :data="{
            fn: addToCart,
            title: $labels.add_to_cart,
          }"
          theme="button"
        />
      </div>
    </Column>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/components/form';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';
import Digit from '@/components/pad/number-configurator-digit';

import { fetchVariations } from '@/api';

export default {
  components: {
    Form,
    Title,
    Digit,
    Richtext,
    Cta,
  },
  data() {
    return {
      request: {},
      numbers: [],
      digits: [],
      slugDigits: [],
      selectedInflation: 'aria',
      selectedProduct: null,
      selectedVariation: {},
      variationsLoading: false,
    };
  },
  computed: {
    ...mapGetters(['selectedNumber']),
    filteredDigits() {
      const array = [];

      if (this.slugDigits.length > 0 && this.digits.length > 0) {
        this.slugDigits.forEach((digit) => {
          array.push(this.digits.find((item) => item.slug === digit));
        });
      }

      return array;
    },
  },
  watch: {
    selectedNumber: {
      immediate: true,
      handler(val) {
        const array = val.split('');
        this.slugDigits = array.map((el) => `numero-${el}`);
        const { length } = array;
        if (this.numbers.length > 0) {
          this.selectedProduct = this.numbers.find(
            (item) => item.slug === `composizione-numero-${length}`,
          );
        }
      },
    },
    selectedProduct: {
      immediate: true,
      handler(val) {
        if (val && this.variationsLoading) {
          this.loadVariations();
        }
      },
    },
  },
  created() {
    this.loadDigits();
    this.loadNumbers();
  },
  methods: {
    async loadDigits() {
      const cat = this.$store.state.wp.categories.shop.default[
        'configurator-digits'
      ];
      const request = {
        type: 'product',
        params: {
          category: cat.id,
          lang: 'default',
        },
      };
      this.digits = await this.$store.dispatch('getItems', request);
    },

    async loadNumbers() {
      const cat = this.$store.state.wp.categories.shop.default[
        'configurator-numbers'
      ];
      const request = {
        type: 'product',
        params: {
          category: cat.id,
          lang: 'default',
        },
      };
      this.numbers = await this.$store.dispatch('getItems', request);
      const { length } = this.selectedNumber.split('');
      this.selectedProduct = this.numbers.find(
        (item) => item.slug === `composizione-numero-${length}`,
      );
      this.loadVariations();
    },

    async loadVariations() {
      const { data: variations } = await fetchVariations({
        params: { slug: this.selectedProduct.slug },
      });
      this.selectedProduct.variations = variations;
      this.variationsLoading = true;
      this.setSelectedVariation();
    },

    setSelectedInflation(inflation) {
      this.selectedInflation = inflation;
      this.setSelectedVariation();
    },

    setSelectedVariation() {
      const variation = this.selectedProduct.variations.find(
        (e) => e.attributes_slugs.pa_gonfiaggio === this.selectedInflation,
      );
      this.selectedVariation = variation;
    },

    addToCart() {
      const items = [];

      this.$refs.digit.forEach((digit) => {
        items.push(digit.selectedVariation);
      });

      const cart_item_data = {
        custom_item_data: {
          numbers: this.selectedNumber,
          items,
        },
      };

      this.$store
        .dispatch('addToCart', {
          id: String(this.selectedProduct.id),
          quantity: 1,
          quantityDelta: 1,
          variation_id: String(this.selectedVariation.id),
          cart_item_data,
        });
      this.$store.commit('TOGGLE_CART');
    },
  },
};
</script>

<style lang="scss">
  .number-configurator {
    &__digits {
      padding: var(--spacer-s);
      min-height: 400px;

      @include mq(m) {
        min-height: 600px;
      }
    }
    &__side {
      display: grid;
      grid-template-rows: 1fr min-content;
      row-gap: var(--spacer-m);
      padding: var(--spacer-s);

      &__content,
      &__content--centered {
        display: grid;
        row-gap: var(--spacer-s);
        align-content: start;

        &__block {
          display: grid;
          row-gap: var(--spacer-xs);
        }
      }

      &__content--centered {
        justify-items: center;
      }
    }
  }
</style>
