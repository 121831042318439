import { mapGetters } from 'vuex';

import {
  addCardToCustomerSavedMethods,
} from '@/assets/js/utils-wc';

import {
  updateSubscriptionPaymentMethod,
} from '@/api';

export default {
  data() {
    return {
      settings: {
        sendLabel: 'change_payment_method',
      },
      groups: {
        payments: {
          visible: true,
          fields: {
            payment_method: {
              type: 'radio-payment',
              model: null,
              id: 'radio-payment',
              rules: 'required',
              placeholder: null,
              options: this.paymentGateways,
              fn: this.setPaymentGateway,
            },
          },
        },
      },

      paymentGatewaySelected: null,
    };
  },
  computed: {
    ...mapGetters(['paymentGateways', 'paymentMetas']),
  },
  watch: {
    paymentGateways: {
      immediate: true,
      handler(val) {
        if (val) {
          const options = Object.keys(val).map((key) => val[key]);
          const optionsFiltered = options.filter((option) => this.$store.state.wp.wcSettings.woocommerce_payments[option.id].supports.subscription_payment_method_change_customer);
          Object.assign(this.groups.payments.fields.payment_method, { ...this.groups.payments.fields.payment_method, options: optionsFiltered });
        }
      },
    },
  },
  methods: {
    init() {
      if (this.data.subscription) {
        this.groups.payments.fields.payment_method.model = this.data.subscription.payment_method;
        this.paymentGatewaySelected = this.groups.payments.fields.payment_method.model;
        if (this.data.subscription.payment_method === 'stripe' && this.$refs['radio-payment'][0] && this.$refs['radio-payment'][0].saved_cards) {
          this.$refs['radio-payment'][0].saved_cards.model = this.data.subscription.stripe_source;
        }
        if (this.$refs['radio-payment'][0] && this.$refs['radio-payment'][0].save_card) {
          this.$refs['radio-payment'][0].save_card.model = true;
          this.$refs['radio-payment'][0].save_card.disabled = true;
        }
      }
    },
    async callback() {
      const data = {
        subscriptionsId: this.$route.query.change_payment_method,
        orderKey: this.$route.query.key,
      };

      let addCardResult = null;
      if (this.paymentGatewaySelected === 'stripe') {
        // 1 - Save card if request
        if (this.$refs['radio-payment'] && this.$refs['radio-payment'][0] && this.$refs['radio-payment'][0].save_card.model && (this.$refs['radio-payment'][0].saved_cards.model === 'new' || this.$refs['radio-payment'][0].saved_cards.model === null)) {
          addCardResult = await addCardToCustomerSavedMethods(this.$store, {
            stripeInstance: this.paymentMetas.stripe,
          });
        }

        // 2 - Change card
        const stripeSource = addCardResult ? addCardResult.token
          : this.$refs['radio-payment'][0].saved_cards.model !== 'new' ? this.$refs['radio-payment'][0].saved_cards.model : null;

        if (stripeSource) {
          const body = {
            payment_method_id: this.paymentGatewaySelected,
            source: {
              id: stripeSource,
            },
          };
          const { status } = await updateSubscriptionPaymentMethod(body, data);
          if (status === 200) {
            this.$store.commit('SET_SNACKBAR', { active: true, content: this.$labels.changed_payment_method, theme: 'success' });
            this.$router.push({ name: 'Subscriptions' });
          } else {
            this.$store.commit('SET_SNACKBAR', { active: true, content: this.$labels.error_generic, theme: 'success' });
          }
        }
      }

      if (this.paymentGatewaySelected === 'paypal') {
        const body = {
          payment_method_id: this.paymentGatewaySelected,
        };
        const response = await updateSubscriptionPaymentMethod(body, data);
        if (response.data.metas.result === 'success') {
          window.location = response.data.metas.redirect;
        } else {
          this.$store.commit('SET_SNACKBAR', { active: true, content: 'Error in saving order' });
        }
      }
      return true;
    },
    async setPaymentGateway(method) {
      this.paymentGatewaySelected = method;
    },
  },
};
