<template>
  <figure
    :class="[
      'picture',
      !data.ratio ? 'no-ratio' : false,
      data.relative ? 'picture-relative' : false,
      data.object_fit ? `picture-${data.object_fit}` : 'picture-cover',
      data.align ? `align--${data.align}` : false,
    ]"
    :data-lazy="data.lazyload ? 'false' : null"
  >
    <picture
      v-if="imageType !== 'svg'"
      :style="{ paddingBottom: data.ratio ? `${ratio}%` : false }"
    >
      <img
        ref="image"
        class="image"
        :data-src="data.lazyload || skipLoad ? src : null"
        :src="!data.lazyload && !skipLoad ? src : ''"
        :alt="defaultImage.alt"
      >
      <div
        v-if="data.lazyload"
        class="placeholder"
      />
    </picture>
    <div
      v-else-if="imageType === 'inline-svg'"
      class="svg"
      v-html="data.svg"
    />
    <Flex
      v-else-if="imageType === 'svg'"
      :justify="data.justify"
    >
      <img
        ref="image"
        class="svg"
        :src="defaultImage.url"
        :alt="defaultImage.alt"
      >
    </Flex>
    <Spacer
      v-if="data.caption !== ''"
      tag="figcaption"
      :all="{ default: 's', m: 'm' }"
      v-html="data.caption"
    />
  </figure>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    data: {
      type: Object,
      required: true,
    },
    skipLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
      ratio: 0,
    };
  },
  computed: {
    defaultImage() {
      if (this.data.default) {
        return this.data.default;
      }
      return this.data;
    },
    imageType() {
      if (this.data.type === 'svg') {
        return 'inline-svg';
      }
      if (this.defaultImage?.subtype === 'svg+xml') {
        return 'svg';
      }
      return 'image';
    },
  },
  mounted() {
    if (this.imageType === 'image') {
      this.currentSrc();
      this.setRatio();
      this.$bus.$on('windowResized', this.currentSrc);
    }

    if (!this.data.lazyload && !this.skipLoad) {
      this.load();
    }
  },
  beforeDestroy() {
    if (this.imageType === 'image') {
      this.$bus.$off('windowResized', this.currentSrc);
    }
  },
  methods: {
    currentSrc() {
      let src = this.defaultImage.sizes.l;
      const { sizes } = this.defaultImage;
      const ratio = window.devicePixelRatio >= 2 ? 2 : 1;
      const wrapper = this.$parent.$el.offsetWidth;
      const dimension = 'width';
      const max = wrapper >= sizes[`xxl-${dimension}`] ? wrapper : wrapper * ratio;
      const ranges = {
        xxs: sizes[`xxs-${dimension}`],
        xs: sizes[`xs-${dimension}`],
        s: sizes[`s-${dimension}`],
        m: sizes[`m-${dimension}`],
        l: sizes[`l-${dimension}`],
        xl: sizes[`xl-${dimension}`],
        xxl: sizes[`xxl-${dimension}`],
      };

      const sizesRange = Object.keys(ranges).filter(
        (key) => ranges[key] >= max,
      );
      const size = sizesRange.length > 0 ? sizesRange[0] : 'l';
      if (this.$mq.isMobile && this.data.mobile) {
        src = this.data.mobile.sizes[size];
        this.currentImg = 'mobile';
        this.setRatio();
      } else {
        src = this.defaultImage.sizes[size];
        this.currentImg = 'default';
        this.setRatio();
      }

      if (this.$el.dataset.lazy === 'true') {
        this.$refs.image.src = src;
      }

      if (this.defaultImage?.subtype === 'gif') {
        src = this.defaultImage.url;
      }

      this.src = src;
    },
    setRatio() {
      if (!this.data && !this.defaultImage) this.ratio = 0;
      this.ratio = this.currentImg === 'default'
        ? (this.defaultImage.height / this.defaultImage.width) * 100
        : (this.data.mobile.height / this.data.mobile.width) * 100;
    },
    async load() {
      const img = this.$refs.image;

      if (this.skipLoad && this.src) {
        img.src = this.src;
      }

      if (img && img.decode && img.src) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .picture {
    position: relative;
    overflow: hidden;
    width: 100%;

    .svg {
      max-width: 100%;
    }

    picture {
      position: relative;
      overflow: hidden;
      display: block;
    }

    &.no-ratio {
      picture {
        height: 100%;
      }

      .image {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: none;
      }

      &.picture-relative {
        .image {
          position: relative;
          max-width: 100%;
        }
      }
    }

    .image {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.align--top {
      .image {
        object-position: 50% 0;
      }
    }

    &.align--center {
      .image {
        object-position: 50% 50%;
      }
    }

    &.align--bottom {
      .image {
        object-position: 50% 100%;
      }
    }

    &.picture-contain {
      .image {
        object-fit: contain;
      }
      .svg {
        width: 100%;
      }
    }

    .placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      opacity: 1;
      pointer-events: none;
      transition: opacity 0.5s $ease-custom;
      will-change: opacity;

      background: var(--grey-d);
    }

    figcaption {
      text-align: left;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      color: var(--white);
    }

    .image {
      visibility: hidden;

      &[src] {
        visibility: visible;
      }
    }
    &[data-lazy="true"] {
      .placeholder {
        opacity: 0;
      }
    }
  }
</style>
